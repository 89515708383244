import { useEffect, useState } from 'react';
import { App, Button, Card, Col, Drawer, Form, Input, List, Row, Select, Space, Tooltip, Typography } from 'antd';
import { gql } from '@/__generated__';
import { useMutation } from '@apollo/client';
import { errorMessage } from '@/components/Common/errorMessage';
import { useSafePath } from '@/hooks/useSafePath';
import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ControlLibraryDsQuery, ObligationLibraryInsertInput } from '@/__generated__/graphql';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { ControlLibraryTable } from '@/components/Control/ControlLibraryTable';
import { grey } from '@ant-design/colors';
import { BreachExamplesProvider } from '@/components/Obligation/BreachExamples/BreachExamplesContext';
import { BreachExamples } from '@/components/Obligation/BreachExamples';
import { ArrayInput } from '@/components/Common/ArrayInput';
import { CountrySelect } from '@/components/Obligation/CountrySelect';
import { useNavigate } from '@/hooks/useNavigate';
import { RichTextInput } from '@/components/Common/RichTextInput';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/obligations/library/add')({
    staticData: {
        breadcrumb: {
            title: 'New obligation',
        },
    },
    component: ObligationsLibraryItemAdd,
});

type ObligationLibraryInput = ObligationLibraryInsertInput;

type ControlLibraryItem = Pick<ControlLibraryDsQuery['ControlLibrary'][number], 'id' | 'objective' | 'owner'>;

function ObligationsLibraryItemAdd() {
    const { message } = App.useApp();
    const { data: customLabelsConfig, loading: customLabelsLoading } = useConfigServiceLoader((service) =>
        service.fetchObligationLibraryLabels(),
    );
    const { data: legislations, loading: legislationsLoading } = useConfigServiceLoader((service) =>
        service.fetchObligation_LegislationItems(),
    );
    const [insertControlOne, { loading }] = useMutation(InsertObligationLibraryOne);
    const navigate = useNavigate();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [obligationControls, setObligationControls] = useState<ControlLibraryItem[]>([]);
    const [breachExamples, setBreachExamples] = useState<string[]>([]);

    const [form] = Form.useForm<ObligationLibraryInput>();
    const safePath = useSafePath<ObligationLibraryInput>();

    const onFinish = async (values: ObligationLibraryInput) => {
        try {
            message.info('Saving...');
            await insertControlOne({
                variables: {
                    input: {
                        ...values,
                        breachExamples,
                        Controls: {
                            data: obligationControls.map(({ id }) => ({
                                controlLibraryItemId: id,
                            })),
                        },
                    },
                },
            });

            message.destroy();
            message.success('Saved.');
            navigate({ to: '/admin-console/obligations/library' });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };
    useEffect(() => {
        form.resetFields();
    }, [open]);

    const handleSelectControls = (controls: ControlLibraryItem[]) => {
        const selectedControlIds = controls.map((control) => control?.id);
        const updatedObligationControls = [
            ...obligationControls.filter((control) => selectedControlIds.includes(control?.id)),
            ...controls.filter((control) => !obligationControls.some((c) => c.id === control?.id)),
        ];
        setObligationControls(updatedObligationControls);
        setDrawerVisible(false);
    };

    return (
        <>
            <Drawer
                title="Select Controls"
                width={900}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                destroyOnClose
            >
                <ControlLibraryTable
                    onSelect={handleSelectControls}
                    onCancel={() => setDrawerVisible(false)}
                    selectedControls={obligationControls}
                />
            </Drawer>

            <Form form={form} id="obligationEdit" layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title="New Obligation">
                                    <Row gutter={[16, 16]}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="Legislation"
                                                name={safePath('legislation')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Select
                                                    loading={legislationsLoading}
                                                    showSearch
                                                    options={legislations?.map(({ label, internalName }) => ({
                                                        label,
                                                        value: internalName,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="Country"
                                                name={safePath('country')}
                                                rules={[{ required: true }]}
                                            >
                                                <CountrySelect />
                                            </Form.Item>
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                label="Core obligation"
                                                name={safePath('coreObligation')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Owner"
                                                name={safePath('owner')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="What it means"
                                                name={safePath('whatItMeans')}
                                                required
                                                rules={[{ required: true }]}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Card title="Source of obligation">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Sub obligation / section / paragraph"
                                                name={safePath('section')}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Exact wording from the legislation"
                                                name={safePath('legislationDescription')}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Austlii link">
                                                <ArrayInput
                                                    name={safePath('austLIIURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Federal Register of Legislation link">
                                                <ArrayInput
                                                    name={safePath('federalRegisterOfLegislationURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Card title="Regulatory guidance">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Regulatory guidance"
                                                name={safePath('regulatoryGuidance')}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Regulator" name={safePath('regulator')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Exact wording from the Regulatory guide"
                                                name={safePath('regulatoryGuidanceDescription')}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Links">
                                                <ArrayInput
                                                    name={safePath('regulatoryGuidanceURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [{ type: 'url', required: true }],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <BreachExamplesProvider
                                    saveField={(value) => {
                                        setBreachExamples(value);
                                    }}
                                >
                                    <BreachExamples breachExamples={breachExamples || []} />
                                </BreachExamplesProvider>
                            </Col>

                            <Col span={24}>
                                <Card title="Labels" loading={customLabelsLoading}>
                                    <Row gutter={[16, 16]}>
                                        {customLabelsConfig?.map((labelConfig) => {
                                            const isMultiSelect = labelConfig.type === 'MULTI_SELECT';
                                            return (
                                                <Col span={6} key={labelConfig.id}>
                                                    <Form.Item
                                                        label={labelConfig.label}
                                                        name={safePath(`customLabels.${labelConfig.id}`)}
                                                    >
                                                        <Select
                                                            mode={isMultiSelect ? 'multiple' : undefined}
                                                            options={labelConfig.options.map((option) => ({
                                                                label: option.label,
                                                                value: option.internalName,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <Space className="flex justify-between">
                                    <Button
                                        icon={<ArrowLeftOutlined />}
                                        onClick={() => {
                                            navigate({
                                                to: '/admin-console/obligations/library',
                                            });
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit" form="obligationEdit" loading={loading}>
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card
                                    title="Key controls"
                                    extra={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                                setDrawerVisible(true);
                                            }}
                                        >
                                            Add control
                                        </Button>
                                    }
                                >
                                    <List
                                        dataSource={obligationControls}
                                        size="small"
                                        loading={loading}
                                        className="overflow-auto"
                                        renderItem={(item) => (
                                            <List.Item
                                                className="flex w-full items-center justify-between p-2"
                                                actions={[
                                                    <Button
                                                        key={item.id}
                                                        type="text"
                                                        icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                                        onClick={() => {
                                                            setObligationControls(
                                                                obligationControls.filter(
                                                                    (control) => control.id !== item.id,
                                                                ),
                                                            );
                                                        }}
                                                    />,
                                                ]}
                                            >
                                                <div className="flex-1 overflow-hidden">
                                                    <Tooltip title={item.objective}>
                                                        <Typography.Paragraph
                                                            ellipsis
                                                            className="m-0 overflow-hidden text-ellipsis whitespace-nowrap"
                                                        >
                                                            {item.objective}
                                                        </Typography.Paragraph>
                                                    </Tooltip>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const InsertObligationLibraryOne = gql(/* GraphQL */ `
    mutation InsertObligationLibraryOne($input: ObligationLibraryInsertInput = {}) {
        insertObligationLibraryOne(object: $input) {
            id
        }
    }
`);
