import { useOrgId } from '@/hooks/Org/useOrgId';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLinkProps } from '@tanstack/react-router';
import { useEffect } from 'react';
import './stripe.sass';
import { errorMessage } from '../Common/errorMessage';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function PaymentForm() {
    const stripe = useStripe();
    const elements = useElements();
    const orgId = useOrgId();

    const { href: paymentSuccessUrl } = useLinkProps({
        to: '/console/$orgId/configuration/billing/balance',
        params: { orgId },
        search: {
            paymentSuccess: 'true',
        },
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}${paymentSuccessUrl}`,
            },
        });

        if (error) {
            errorMessage.show(error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button type="submit" disabled={!stripe}>
                Pay
            </button>
        </form>
    );
}

type StripePaymentProps = {
    clientSecret: string;
};

export function StripePayment({ clientSecret }: StripePaymentProps) {
    useEffect(() => {
        void stripePromise;
    }, []);

    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe' as const,
        },
    };

    return (
        <div className="stripe">
            <Elements stripe={stripePromise} options={options}>
                <PaymentForm />
            </Elements>
        </div>
    );
}
