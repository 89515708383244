import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { errorMessage } from '@/components/Common/errorMessage';
import { useNavigate } from '@/hooks/useNavigate';
import { useUser } from '@/hooks/User/useUser';
import { useAuthStore } from '@/stores/AuthStore';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, ErrorComponentProps, useMatch } from '@tanstack/react-router';
import { Button, message, Spin } from 'antd';
import { useEffect } from 'react';
import { SYSTEM_ORG_ID } from 'shared/constants/org';

export const Route = createFileRoute('/(authentication)/change-org/$orgId')({
    params: {
        parse({ orgId }) {
            return {
                orgId: BigInt(orgId),
            };
        },
    },
    errorComponent: ErrorComponent,
    component: ChangeOrg,
});

function ErrorComponent({ error }: ErrorComponentProps) {
    const navigate = useNavigate();
    return (
        <ComponentLoading isFullPage>
            <div className="mx-auto w-96 max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl">
                <h2 className="mb-4 text-3xl font-bold text-red-600">Error</h2>
                <p className="mb-8 text-lg text-gray-700">{error?.toString()}</p>
                <Button type="link" onClick={() => navigate({ moveInHistory: 'back' })} className="mx-auto">
                    Go Back
                </Button>
            </div>
        </ComponentLoading>
    );
}

function ChangeOrg() {
    const match = useMatch({ strict: false });
    const { orgId } = Route.useParams();
    const user = useUser();
    const authStore = useAuthStore();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    if (match && match.paramsError) {
        return null;
    }
    const org = user.userOrgs?.find((org) => org.id === orgId);

    useEffect(() => {
        const handleChangeOrg = async () => {
            try {
                queryClient.clear();
                const accessToken = await authStore.getAccessToken(orgId, false);
                await authStore.changeOrg(accessToken);
                if (orgId === SYSTEM_ORG_ID.SYSTEM_CONSOLE) {
                    navigate({
                        to: '/admin-console',
                    });
                } else {
                    navigate({
                        to: '/console/$orgId',
                        params: {
                            orgId,
                        },
                    });
                }
            } catch (e) {
                await errorMessage.showAsync(e);
                message.destroy();
                message.info('Changing to the original organization...');
                const accessToken = await authStore.getAccessToken(user.orgId, true);
                try {
                    await authStore.changeOrg(accessToken);
                } catch (e) {
                    await errorMessage.showAsync(e);
                }
            }
        };
        handleChangeOrg();
    }, [orgId]);

    if (!orgId || !org) {
        return (
            <ComponentLoading isFullPage>
                <div className="mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl">
                    <h2 className="mb-4 text-3xl font-bold text-red-600">Organization not found</h2>
                    <p className="mb-8 text-lg text-gray-700">
                        The organization you are trying to access does not exist or you do not have permission to access
                        it.
                    </p>
                </div>
            </ComponentLoading>
        );
    }

    return (
        <ComponentLoading isFullPage>
            <div className="hover:shadow-3xl mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl transition-all duration-500 ease-in-out">
                <div className="mb-6">
                    <img src="/static/curium-logo.svg" alt="Company Logo" className="mx-auto h-16 w-auto" />
                </div>
                <h2 className="text-primary-600 mb-4 text-3xl font-bold">
                    {orgId === SYSTEM_ORG_ID.SYSTEM_CONSOLE
                        ? 'Logging into the system console...'
                        : `Logging into ${org?.name}...`}
                </h2>
                <p className="mb-8 text-lg text-gray-700">
                    {orgId === SYSTEM_ORG_ID.SYSTEM_CONSOLE
                        ? 'Please hold on while we prepare your personalized experience.'
                        : `You are now logging into ${org?.name}. Please hold on while we prepare your personalized experience.`}
                </p>
                <div className="flex flex-col items-center justify-center">
                    <Spin size="large" />
                    <p className="text-md mt-6 text-gray-600">Preparing your personalized experience...</p>
                </div>
            </div>
        </ComponentLoading>
    );
}
