import { errorMessage } from '@/components/Common/errorMessage';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { useZodState } from '@/hooks/useZodState';
import { RouterOutputs } from '@/trpc';
import type { TableColumnType } from '@/types/common';
import { PlusOutlined } from '@ant-design/icons';
import { Decimal } from 'decimal.js';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Drawer, Form, Input, Modal, Select, Spin, Table, Tag, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { listWalletTransactionsSchema } from 'server/schemas/walletTransaction';

const { Text } = Typography;

export const Route = createFileRoute('/admin-console/organizations/$orgId/wallet')({
    component: Wallet,
});

type Transaction = RouterOutputs['admin']['billing']['listWalletTransactions']['rows'][number];

function Wallet() {
    const { orgId } = Route.useParams();
    const [open, setOpen] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { trpc, trpcUtils } = useTrpc();
    const { data: org } = trpc.admin.org.getOrg.useQuery({ orgId });
    const [queryParams, setQueryParams] = useZodState(listWalletTransactionsSchema, {
        where: { orgId },
    });

    // Fetch wallet balance
    const { data: walletBalance, isLoading: walletLoading } = trpc.admin.billing.getWalletBalance.useQuery({ orgId });

    // Setup transactions query
    const { data: transactionsData, isLoading: transactionsLoading } =
        trpc.admin.billing.listWalletTransactions.useQuery(queryParams);

    const addTransactionMutation = trpc.admin.billing.addWalletTransaction.useMutation({
        onSuccess: () => {
            setIsModalOpen(false);
            form.resetFields();
            void trpcUtils.admin.billing.listWalletTransactions.invalidate();
            void trpcUtils.admin.billing.getWalletBalance.invalidate();
        },
        onError: (error) => {
            errorMessage.show(error);
        },
    });

    const handleAddTransaction = async (values: { type: 'credit' | 'debit'; amount: string; note: string }) => {
        try {
            await addTransactionMutation.mutateAsync({
                orgId,
                type: values.type,
                amount: Number(values.amount),
                note: values.note,
            });
        } catch (error) {
            errorMessage.show(error);
        }
    };

    const columns: TableColumnType<Transaction>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: bigint) => <Text>{id.toString()}</Text>,
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: true,
            render: (type: string) => <Tag color="blue">{type}</Tag>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            render: (amount: Decimal) => (
                <Text strong type={amount.isNegative() ? 'danger' : 'success'}>
                    {amount.isNegative() ? '-' : '+'}${Math.abs(Number(amount)).toFixed(2)}
                </Text>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (date: Date) => <Text type="secondary">{DateTime.fromJSDate(date).toISO()}</Text>,
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            render: (note: string | null) => <Text>{note || 'N/A'}</Text>,
        },
    ];

    const { tableProps } = useAntdTable({
        columns,
        rowKey: 'id',
        data: {
            total: transactionsData?.total ?? 0,
            rows: transactionsData?.rows ?? [],
            loading: transactionsLoading,
        },
        paginationConfig: {
            defaultPageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: true,
        },
        onQueryVariableChange: (options) => {
            setQueryParams({
                ...queryParams,
                ...options,
            });
        },
    });

    return (
        <Drawer
            title={`${org?.name} Wallet`}
            open={open}
            onClose={() => setOpen(false)}
            width={1200}
            afterOpenChange={async (open) => {
                if (!open) {
                    navigate({ to: '/admin-console/organizations/$orgId', params: { orgId } });
                }
            }}
        >
            <div className="space-y-6">
                <Card className="shadow-sm">
                    <div className="space-y-2">
                        <Text className="text-gray-500">Current Balance</Text>
                        <div>
                            {walletLoading ? (
                                <Spin />
                            ) : (
                                <Text className="text-3xl font-semibold">
                                    ${walletBalance ? walletBalance.balance.toFixed(2) : '0.00'}
                                </Text>
                            )}
                        </div>
                    </div>
                </Card>

                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <Text strong className="text-lg">
                            Transaction History
                        </Text>
                        <div className="flex items-center gap-4">
                            <Text type="secondary">{transactionsData?.total ?? 0} transactions</Text>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setIsModalOpen(true)}
                                loading={addTransactionMutation.isPending}
                            >
                                Add Transaction
                            </Button>
                        </div>
                    </div>

                    <Table {...tableProps} className="shadow-sm" scroll={{ x: 500 }} size="small" />
                </div>
            </div>

            <Modal
                title="Add Transaction"
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                    form.resetFields();
                }}
                footer={null}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={handleAddTransaction} className="mt-4">
                    <Form.Item
                        name="type"
                        label="Transaction Type"
                        rules={[{ required: true, message: 'Please select a transaction type' }]}
                    >
                        <Select>
                            <Select.Option value="credit">Credit</Select.Option>
                            <Select.Option value="debit">Debit</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Amount"
                        dependencies={['type']}
                        rules={[
                            { required: true, message: 'Please enter an amount' },
                            {
                                pattern: /^-?\d*\.?\d{0,2}$/,
                                message: 'Please enter a valid amount with up to 2 decimal places',
                            },
                            ({ getFieldValue }) => ({
                                async validator(_, value) {
                                    const type = getFieldValue('type');
                                    const amount = Number(value);

                                    if (!type || !value) {
                                        return;
                                    }

                                    if (type === 'debit' && amount >= 0) {
                                        throw new Error('Debit amount must be negative');
                                    }

                                    if (type === 'credit' && amount <= 0) {
                                        throw new Error('Credit amount must be positive');
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input prefix="$" placeholder="0.00" type="number" step="0.01" />
                    </Form.Item>

                    <Form.Item name="note" label="Note" rules={[{ required: true, message: 'Please enter a note' }]}>
                        <Input.TextArea placeholder="Enter a note for this transaction" />
                    </Form.Item>

                    <div className="flex justify-end gap-2">
                        <Button
                            onClick={() => {
                                setIsModalOpen(false);
                                form.resetFields();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={addTransactionMutation.isPending}>
                            Add Transaction
                        </Button>
                    </div>
                </Form>
            </Modal>
        </Drawer>
    );
}
