import { ActionBar } from '@/components/Common/ActionBar';
import { DatePicker } from '@/components/Common/DatePicker';
import { errorMessage } from '@/components/Common/errorMessage';
import { ScrollToTopOnMount } from '@/components/Common/ScrollToTopOnMount';
import { ContactList } from '@/components/Contact/ContactList';
import { trpc } from '@/trpc';
import { SaveOutlined } from '@ant-design/icons';
import { skipToken } from '@tanstack/react-query';
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ClaimFormState } from '@/hooks/Claim/useClaimFormState';

type Props = {
    goNext: () => any;
    state: ClaimFormState;
    onSave: () => any;
};
export const PolicyData = observer(({ state, goNext, onSave }: Props) => {
    const claim = state.claim;
    const readOnly = state.ui.formAction === 'view';

    const { data, isLoading } = trpc.insurer.getInsurersByProductTypeId.useQuery(
        claim.productTypeId ? { productTypeId: claim.productTypeId } : skipToken,
        {
            throwOnError: (error) => {
                errorMessage.show(error);
                return false;
            },
        },
    );

    const { data: productTypeData, isLoading: productTypeLoading } = trpc.productType.getProductTypesWithPds.useQuery(
        undefined,
        {
            throwOnError: (error) => {
                errorMessage.show(error);
                return false;
            },
        },
    );

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(claim);
    }, []);

    useEffect(() => {
        (async () => {
            if (!claim.insurerId || !claim.productTypeId) {
                claim.pdsVersionId = undefined;
                state.questionnaireDef = [];
                return;
            }
            // const { data } = await pdsVersionByInsurerIdAndProductTypeId({
            //     variables: {
            //         insurerId: claim.insurerId,
            //         productTypeId: claim.productTypeId,
            //     },
            //     fetchPolicy: 'network-only',
            // });

            const { data } = await trpc.pdsVersion.getPdsVersionByInsurerIdAndProductTypeId.useQuery({
                insurerId: claim.insurerId,
                productTypeId: claim.productTypeId,
            });
            if (!data) {
                return;
            }
            claim.pdsVersionId = data.id;
            if (claim.pdsVersionId) {
                state.questionnaireDef = data.Questionnaire?.definition || [];
            }
        })();
    }, [claim.insurerId, claim.productTypeId]);

    return (
        <>
            <div className="portal" />
            <ScrollToTopOnMount />
            <Form layout="vertical" form={form} scrollToFirstError onFinish={goNext} size="large">
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card title="Policy" className="card--full-height">
                            <Form.Item
                                label="Product type"
                                required
                                rules={[{ required: true, message: 'Product type is required.' }]}
                                name="productTypeId"
                            >
                                <Select<bigint, { value: bigint; label: string }>
                                    loading={productTypeLoading}
                                    options={
                                        productTypeData?.map((el) => ({
                                            value: el.id,
                                            label: el.name,
                                        })) || []
                                    }
                                    onChange={(value) => {
                                        claim.productTypeId = value;
                                    }}
                                    value={claim.productTypeId}
                                    disabled={readOnly}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Insurer"
                                required
                                rules={[{ required: true, message: 'Insurer is required.' }]}
                                name="insurerId"
                            >
                                <Select<bigint, { value: bigint; label: string }>
                                    loading={isLoading}
                                    options={
                                        data?.map((el) => ({
                                            value: el.id,
                                            label: el.name,
                                        })) || []
                                    }
                                    onChange={(value) => {
                                        claim.insurerId = value;
                                    }}
                                    value={claim.insurerId}
                                    disabled={readOnly}
                                />
                            </Form.Item>
                            <Form.Item label="Policy Name">
                                <Input
                                    value={claim.policyName || ''}
                                    onChange={(e) => (claim.policyName = e.target.value)}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                            <Form.Item>
                                <ContactList
                                    value={claim.contacts || {}}
                                    onChange={(contacts) => {
                                        claim.contacts = contacts;
                                    }}
                                    contactType="insurer"
                                    title="Insurer contacts"
                                />
                            </Form.Item>
                            <Form.Item label="Policy#">
                                <Input
                                    value={claim.policyNo || ''}
                                    onChange={(e) => (claim.policyNo = e.target.value)}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Term - From">
                                        <DatePicker
                                            onChange={(date) => {
                                                claim.policyTermFrom = date;
                                            }}
                                            value={claim.policyTermFrom}
                                            className="w-full"
                                            format="DD/MM/YYYY HH:mm"
                                            disabled={readOnly}
                                            showTime
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Term - To">
                                        <DatePicker
                                            onChange={(date) => {
                                                claim.policyTermTo = date;
                                            }}
                                            value={claim.policyTermTo}
                                            className="w-full"
                                            format="DD/MM/YYYY HH:mm"
                                            disabled={readOnly}
                                            showTime
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Policy Issued on">
                                        <DatePicker
                                            onChange={(date) => {
                                                claim.policyIssuedOn = date;
                                            }}
                                            value={claim.policyIssuedOn}
                                            className="w-full"
                                            format="DD/MM/YYYY"
                                            disabled={readOnly}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Other info">
                                <Input.TextArea
                                    value={claim.policyOtherInfo || ''}
                                    onChange={(e) => (claim.policyOtherInfo = e.target.value)}
                                    rows={4}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Broker" className="card--full-height">
                            <Form.Item label="Company Name">
                                <Input
                                    value={claim.brokerCompany || ''}
                                    onChange={(e) => (claim.brokerCompany = e.target.value)}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                            <Form.Item>
                                <ContactList
                                    value={claim.contacts || {}}
                                    onChange={(contacts) => {
                                        claim.contacts = contacts;
                                    }}
                                    contactType="broker"
                                    title="Broker contacts"
                                />
                            </Form.Item>
                            <Form.Item label="Contact insured?">
                                <Switch disabled={readOnly} />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Policy Holder" className="card--full-height">
                            <Form.Item label="Insured Company Name">
                                <Input
                                    value={claim.phCompany || ''}
                                    onChange={(e) => {
                                        claim.phCompany = e.target.value;
                                    }}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                            <Form.Item label="Business type">
                                <Input
                                    value={claim.phBusinessType || ''}
                                    onChange={(e) => {
                                        claim.phBusinessType = e.target.value;
                                    }}
                                    readOnly={readOnly}
                                />
                            </Form.Item>
                            <Form.Item label="Registered for GST?">
                                <Switch
                                    checked={claim.phIsRegisteredForGst || false}
                                    onChange={(checked) => (claim.phIsRegisteredForGst = checked)}
                                    disabled={readOnly}
                                />
                            </Form.Item>
                            <Form.Item>
                                <ContactList
                                    value={claim.contacts || {}}
                                    onChange={(contacts) => {
                                        claim.contacts = contacts;
                                    }}
                                    contactType="policy_holder"
                                    title="Policy holder contacts"
                                />
                            </Form.Item>
                            <Card title="Payment info" size="small">
                                <Form.Item label="ITC %">
                                    <Input
                                        readOnly={readOnly}
                                        value={claim.phITC || ''}
                                        onChange={(e) => {
                                            claim.phITC = e.target.value;
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="ABN">
                                    <Input
                                        value={claim.phABN || ''}
                                        onChange={(e) => {
                                            claim.phABN = e.target.value;
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Form.Item>
                                <Form.Item label="BSB">
                                    <Input
                                        value={claim.phAccountBsb || ''}
                                        onChange={(e) => {
                                            claim.phAccountBsb = e.target.value;
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Form.Item>
                                <Form.Item label="ACC#">
                                    <Input
                                        value={claim.phAccountNo || ''}
                                        onChange={(e) => {
                                            claim.phAccountNo = e.target.value;
                                        }}
                                        readOnly={readOnly}
                                    />
                                </Form.Item>
                            </Card>
                        </Card>
                    </Col>
                </Row>

                <ActionBar
                    renderRight={() => (
                        <>
                            {!readOnly && (
                                <Button icon={<SaveOutlined />} onClick={onSave} size="large">
                                    Save
                                </Button>
                            )}
                            <Button type="primary" htmlType="submit" size="large">
                                {state.ui.formAction === 'new' ? 'Confirm' : 'Next'}
                            </Button>
                        </>
                    )}
                />
            </Form>
        </>
    );
});
