import { useState } from 'react';
import { Button, Card, Popconfirm, Space, Table } from 'antd';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { ProductTypeEdit, ProductTypeAction } from './ProductTypeEdit';
import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { CostTypes } from './CostTypes';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';
import { useZodState } from '@/hooks/useZodState';
import { listProductTypeInputSchema } from 'server/schemas/productType';
import { RouterOutputs, trpc } from '@/trpc';

export const Route = createFileRoute('/console/$orgId/configuration/product-types/')({
    staticData: {
        ui: {
            title: 'Product types & PDS configuration',
        },
    },
    component: ProductTypeList,
});

type ProductType = RouterOutputs['productType']['listProductTypes']['rows'][number];

type CostTypeAction = ['none'] | ['edit', bigint];

function ProductTypeList() {
    const orgId = useOrgId();
    const [productTypeAction, setProductTypeAction] = useState<ProductTypeAction>(['none']);
    const [costTypeAction, setCostTypeAction] = useState<CostTypeAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listProductTypeInputSchema);
    const { data, isLoading, refetch } = trpc.productType.listProductTypes.useQuery(queryParams);
    const { mutateAsync: deleteProductType } = trpc.productType.deleteProductType.useMutation();

    const { tableProps } = useAntdTable<ProductType>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            onPopupClick={(e) => e.stopPropagation()}
                            onConfirm={async () => {
                                try {
                                    await deleteProductType({
                                        id: value,
                                    });

                                    void refetch();
                                } catch (e) {
                                    errorMessage.show(e);
                                }
                            }}
                        >
                            <Button
                                type="text"
                                size="small"
                                icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Popconfirm>

                        <Button
                            type="link"
                            onClick={(e) => {
                                e.stopPropagation();
                                setCostTypeAction(['edit', value]);
                            }}
                        >
                            Manage Cost Types
                        </Button>
                        <Link
                            to="/console/$orgId/configuration/product-types/$productTypeId"
                            params={{ orgId, productTypeId: value }}
                            onClick={(e) => e.stopPropagation()}
                            type="link"
                        >
                            Manage PDS
                        </Link>
                    </Space>
                ),
            },
        ],
    });

    return (
        <>
            <ProductTypeEdit
                action={productTypeAction}
                onClose={() => {
                    setProductTypeAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setProductTypeAction(['add']);
                    }}
                >
                    Add new
                </Button>
            </div>

            {costTypeAction[0] === 'edit' && (
                <CostTypes productTypeId={costTypeAction[1]} onClose={() => setCostTypeAction(['none'])} />
            )}

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setProductTypeAction(['edit', id]),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
