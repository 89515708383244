import { errorMessage } from '@/components/Common/errorMessage';
import { Link } from '@/components/Common/Link';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useDeleteConfirmation } from '@/hooks/UI/useDeleteConfirmation';
import { useUser } from '@/hooks/User/useUser';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { RouterInputs, trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { App, Button, Card, Input, Table, Tag } from 'antd';
import { useState } from 'react';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/admin-console/organizations/')({
    staticData: {
        ui: {
            title: 'Organizations',
            subtitle: 'Organizations can be configured here.',
        },
    },
    component: AdminPageOrganizations,
});

type QueryVariables = RouterInputs['admin']['org']['listOrgs'];

function AdminPageOrganizations() {
    const { message } = App.useApp();
    const trpcClient = useTrpcClient();
    const currentUser = useUser();

    const [orgIdToDelete, setOrgIdToDelete] = useState<bigint | undefined>();
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
        orderBy: {
            id: 'desc',
        },
    });

    const { data: orgs, isLoading, refetch } = trpc.admin.org.listOrgs.useQuery(queryVariables);

    const {
        showConfirmationModal,
        hideConfirmationModal,
        deleteConfirmationModal: DeleteConfirmationModal,
    } = useDeleteConfirmation({
        entityDisplayName: 'organization',
        onConfirm: async (confirmationText) => {
            try {
                invariant(orgIdToDelete, 'orgIdToDelete is required');
                message.loading('Deleting organization...');
                await trpcClient.admin.org.deleteOrg.mutate({
                    orgId: BigInt(orgIdToDelete),
                    orgName: confirmationText,
                });
                message.destroy();
                message.success('Organization deleted successfully');
                refetch();
            } catch (error) {
                errorMessage.show(error);
            } finally {
                hideConfirmationModal();
            }
        },
    });

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: orgs?.rows || [],
            total: orgs?.total || 0,
            loading: isLoading,
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: (value) => BigInt(value).toString(),
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'isEnabled',
                title: 'Is enabled?',
                sorter: true,
                render: (value) => (value ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>),
            },
            {
                dataIndex: 'emailId',
                title: 'Email ID',
                render: (value) => (value ? <Tag>{value}</Tag> : ''),
            },
            {
                title: 'Modules',
                render: (_, row) => (
                    <>
                        {row.enabledModules.map((module) => (
                            <Tag key={module} color="blue">
                                {module}
                            </Tag>
                        ))}
                    </>
                ),
            },
            {
                dataIndex: 'markedForDeletion',
                title: 'Marked for deletion?',
                sorter: true,
                render: (value) => (value ? <Tag color="error">Yes</Tag> : <Tag color="success">No</Tag>),
            },

            {
                fixed: 'right',
                align: 'center',
                render: (v) => (
                    <div className="space-x-2 whitespace-nowrap">
                        <Link
                            size="small"
                            to="/admin-console/organizations/$orgId"
                            params={{
                                orgId: BigInt(v.id),
                            }}
                        >
                            Edit
                        </Link>
                        <Link
                            size="small"
                            to="/admin-console/organizations/$orgId/users"
                            params={{
                                orgId: BigInt(v.id),
                            }}
                        >
                            Manage users
                        </Link>
                        <Link
                            size="small"
                            to="/admin-console/organizations/$orgId/wallet"
                            params={{
                                orgId: BigInt(v.id),
                            }}
                        >
                            Manage wallet
                        </Link>
                        {(currentUser.isPrivilegedSuperAdmin || import.meta.env.VITE_ENV !== 'production') && (
                            <Button
                                size="small"
                                danger
                                onClick={() => {
                                    setOrgIdToDelete(v.id);
                                    showConfirmationModal(v.name);
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                ),
            },
        ],
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                ...options,
                orderBy: options?.orderBy ?? prev.orderBy,
            }));
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    return (
        <>
            {DeleteConfirmationModal}

            <div className="mb-4 flex items-center justify-start">
                <Input.Search
                    placeholder="Search organizations"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        if (value) {
                            setQueryVariables({
                                ...queryVariables,
                                where: {
                                    name: { contains: value, mode: 'insensitive' },
                                },
                            });
                        } else {
                            setQueryVariables({
                                ...queryVariables,
                                where: undefined,
                            });
                        }
                    }}
                    className="w-96"
                />
            </div>

            <Card>
                <Table {...tableProps} size="small" />
            </Card>
        </>
    );
}
