import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute, matchByPath } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';
import { SYSTEM_ORG_ID } from 'shared/constants/org';
import { FileRouteTypes } from '@/routeTree.gen';

const searchSchema = z.object({
    redirectTo: z.string().optional(),
    orgId: z.coerce.bigint().optional(),
});

export const Route = createFileRoute('/(authentication)/login')({
    validateSearch: zodValidator(searchSchema),
    component: Login,
});

function Login() {
    const { loginWithRedirect } = useAuth0();
    const { redirectTo, orgId } = Route.useSearch();

    useEffect(() => {
        const redirectToWithoutQuery = (redirectTo as string)?.split('?')[0];
        const params = matchByPath('/', redirectToWithoutQuery || '', {
            to: '/console/$orgId',
        });
        let orgIdParam: bigint | undefined;
        if (orgId) {
            orgIdParam = orgId;
        } else if (params?.orgId) {
            orgIdParam = BigInt(params.orgId);
        } else if (redirectTo && redirectTo.startsWith('/admin-console' satisfies FileRouteTypes['to'])) {
            orgIdParam = SYSTEM_ORG_ID.SYSTEM_CONSOLE;
        }

        void loginWithRedirect({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: 'openid profile email offline_access',
                isLogin: true,
                ...(orgIdParam && { orgId: orgIdParam }),
            },
            appState: {
                redirectTo: redirectTo || '',
                ...(orgIdParam && { requestOrgId: orgIdParam }),
            },
        });
    }, []);

    return null;
}
