import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useOrgId } from '@/hooks/Org/useOrgId';

export const Route = createFileRoute('/console/$orgId/tools/')({
    component() {
        const orgId = useOrgId();

        return <Navigate to="/console/$orgId/tools/ai-inbox" params={{ orgId }} />;
    },
});
