import { errorMessage } from '@/components/Common/errorMessage';
import { useCreateSignalStore } from '@/context/signal';
import { ProductTypeByPk } from '@/graphql/queries/productType';
import { MessagesNotificationSub } from '@/graphql/subscription/notification';
import { useCustomDocumentTitle } from '@/hooks/useCustomDocumentTitle';
import { useIsOffline } from '@/hooks/useIsOffline';
import { ClaimDetailsActions } from './Actions';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { App, Badge, Skeleton, Tabs } from 'antd';
import { useState } from 'react';
import { ThirdPartyContactConfig } from 'shared/types/claim';
import { ProductTypeDef } from 'shared/types/productType';
import { CLAIM_DETAILS_SECTIONS } from 'shared/navigation/navRoutes';
import { ClaimContext } from './ClaimContext';
import { ClaimSignalContext, ClaimSignals } from './ClaimSignalContext';
import { Header } from './Header';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useUser } from '@/hooks/User/useUser';
import { claimFormStore } from './store';
import { RecordNotFoundError } from '@/utils/error';
import { useNavigate } from '@/hooks/useNavigate';
import { useOrg } from '@/hooks/Org/useOrg';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';
import { CLAIM_STATUSES } from 'shared/constants/claim';
import { trpc } from '@/trpc';

export const Route = createFileRoute('/console/$orgId/claims/details/$id')({
    staticData: {
        breadcrumb: {
            title: 'Claim details',
        },
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ClaimDetails,
});

function ClaimDetails() {
    const { message } = App.useApp();
    const currentOrg = useOrg();
    const orgId = useOrgId();
    const user = useUser();
    const { id } = Route.useParams();
    const signalsCtx = useCreateSignalStore<ClaimSignals>();
    const isOffline = useIsOffline();
    const { pathname } = useLocation();
    const trpcClient = trpc.useUtils();
    const { mutateAsync } = trpc.claim.updateClaim.useMutation();
    const navigate = useNavigate();
    const [getProductType] = useLazyQuery(ProductTypeByPk);

    const [productTypeDataTabName, setProductTypeDataTabName] = useState<string | undefined>();
    const [thirdPartyContacts, setThirdPartyContacts] = useState<ThirdPartyContactConfig>([]);

    const { formData: claim } = claimFormStore.useInit({
        entity: 'claim',
        loadDependencies: [id],

        async onSave(_, changedValues) {
            try {
                message.info('Saving...');

                await mutateAsync({
                    id,
                    set: changedValues,
                });

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad({ setReadonly }) {
            try {
                const data = await trpcClient.claim.getClaim.fetch({ id });

                setReadonly(
                    [CLAIM_STATUSES.CLOSED, CLAIM_STATUSES.CLOSED_IN_RECOVERY_ONLY].includes(data.status as any),
                );

                if (data.productTypeId) {
                    const productType = await getProductType({
                        variables: {
                            id: data.productTypeId,
                            orgId,
                        },
                        fetchPolicy: 'network-only',
                    });

                    if (productType?.data?.ProductTypeByPk?.definition) {
                        setProductTypeDataTabName(
                            (productType.data.ProductTypeByPk.definition as ProductTypeDef)?.claimTabLabel,
                        );
                    }

                    setThirdPartyContacts(productType?.data?.ProductTypeByPk?.thirdPartyContactTypes || []);
                }
                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The claim you are trying to access either doesn't exist or you don't have permissions to access it.",
                );
                navigate({
                    to: '/console/$orgId/claims',
                    params: { orgId },
                });
            } else {
                errorMessage.show(error);
            }
        },
    });

    useCustomDocumentTitle(`Claim - ${claim?.generatedId || id} - Curium`);

    const { data: messagesNotifications } = useSubscription(MessagesNotificationSub, {
        variables: {
            where: {
                isActive: { _eq: true },
                commLogId: { _isNull: false },
                userId: { _eq: user.id },
                orgId: { _eq: orgId },
                CommLog: { claimId: { _eq: id } },
            },
        },
        fetchPolicy: 'network-only',
        shouldResubscribe: true,
        skip: isOffline,
    });

    const isProductTypeDataExists = Object.values(claim?.productTypeData || {}).length;

    return claim && id === claim.id ? (
        <ClaimContext.Provider
            value={{
                thirdPartyContactConfig: thirdPartyContacts,
            }}
        >
            <ClaimSignalContext.Provider value={signalsCtx}>
                <Header />
                <ClaimDetailsActions />
                <Tabs
                    destroyInactiveTabPane
                    type="line"
                    activeKey={pathname.split('/')[6]}
                    onTabClick={(key) =>
                        navigate({
                            to: `/console/$orgId/claims/details/$id/${key}`,
                            params: {
                                orgId,
                                id,
                            },
                        })
                    }
                    items={[
                        {
                            key: CLAIM_DETAILS_SECTIONS.MANAGE,
                            label: 'Manage claim',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.POLICY_INFO,
                            label: 'Policy info',
                            children: <Outlet />,
                        },
                        ...(isProductTypeDataExists
                            ? [
                                  {
                                      key: CLAIM_DETAILS_SECTIONS.PRODUCT_TYPE_DATA,
                                      label: productTypeDataTabName || 'Product Type Data',
                                      children: <Outlet />,
                                  },
                              ]
                            : []),
                        {
                            key: CLAIM_DETAILS_SECTIONS.COMMS,
                            label: (
                                <Badge
                                    count={messagesNotifications?.NotificationAggregate.aggregate?.count || 0}
                                    size="small"
                                    offset={[5, -5]}
                                >
                                    Communication
                                </Badge>
                            ),
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.CONTACTS,
                            label: 'Contacts',
                            children: <Outlet />,
                        },
                        ...(currentOrg.enabledModules.includes('billing')
                            ? [
                                  {
                                      key: CLAIM_DETAILS_SECTIONS.EXPENSES,
                                      label: 'Expenses & Billing',
                                      children: <Outlet />,
                                  },
                              ]
                            : []),
                        {
                            key: CLAIM_DETAILS_SECTIONS.FINANCIALS,
                            label: 'Financials',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.LIBRARY,
                            label: 'Library',
                            children: <Outlet />,
                        },
                        {
                            key: CLAIM_DETAILS_SECTIONS.HISTORY,
                            label: 'History',
                            children: <Outlet />,
                        },
                    ]}
                />
            </ClaimSignalContext.Provider>
        </ClaimContext.Provider>
    ) : (
        <Skeleton active />
    );
}
