import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { DEBUG_MODE } from '@/config/debug';
import { ActionBar } from '@/components/Common/ActionBar';
import { Alert, Button, Card, Form, Space, Typography } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { ScrollToTopOnMount } from '@/components/Common/ScrollToTopOnMount';
import { CardTitle } from '@/components/Common/CardTitle';
import { ChildFormInput } from '@/components/Claim/Questionnaire/Children/childFormInput';
import { QuestionnaireService } from '@/services/QuestionnaireService';
import { ChildMultiSelect } from '@/components/Claim/Questionnaire/Children/childMultiSelect';
import {
    ChildSpecifiedItem,
    SingleChildSpecifiedItemHandle,
} from '@/components/Claim/Questionnaire/Children/childSpecifiedItem';
import { ChildSingleSelect } from '@/components/Claim/Questionnaire/Children/childSingleSelect';
import {
    CardBase as QCard,
    CardForm,
    CardMultiSelect,
    CardSingleSelect,
    CardSpecifiedItems,
    CardText,
} from 'shared/model/Card';

interface Props {
    card: QCard;
    proceedToNextCard: () => void;
    onSaveDraftOpen?: () => void;
    readOnly: boolean;
    onSave: () => void;
    goToPrevCard: () => void;
    service: QuestionnaireService;
    displaySaveButton?: boolean;
}

export const CardWrapper = observer(
    ({
        card,
        proceedToNextCard,
        readOnly,
        onSave,
        goToPrevCard,
        service,
        displaySaveButton = true,
        onSaveDraftOpen,
    }: Props) => {
        const specifiedItemRef = useRef<SingleChildSpecifiedItemHandle>(null);
        const [formValues, setFormValues] = React.useState<Record<string, any>>({});

        const [form] = Form.useForm();

        const onFinish = (values: any, goNext = true) => {
            if (card.type === 'FORM') {
                // Save form values to card
                if (card instanceof CardForm) {
                    const formValues: Record<string, any> = values;
                    Object.keys(formValues).forEach((key) => {
                        card.children?.forEach((child) => {
                            if (child.id.toString() === key) {
                                child.value = formValues[key];
                            }
                        });
                    });
                    form.resetFields();
                    form.setFieldsValue(formValues);
                }
                if (goNext) proceedToNextCard();
            } else if (card.type === 'SINGLE_SELECT') {
                if (goNext) proceedToNextCard();
            } else if (card.type === 'SPECIFIED_ITEMS') {
                if (goNext) specifiedItemRef.current?.goToNext(proceedToNextCard);
            } else {
                if (goNext) proceedToNextCard();
            }
        };

        useEffect(() => {
            // Restore form values from card
            if (card instanceof CardForm) {
                const formValues: Record<string, any> = {};
                card.children?.forEach((child) => {
                    formValues[child.id] = child.value;
                });
                form.resetFields();
                form.setFieldsValue(formValues);
            }
        }, [card.id]);

        const onBack = () => {
            if (card instanceof CardForm) {
                // Save form values to card
                const formValues: Record<string, any> = form.getFieldsValue();
                Object.keys(formValues).forEach((key) => {
                    card.children?.forEach((child) => {
                        if (child.id.toString() === key) {
                            child.value = formValues[key];
                        }
                    });
                });
                goToPrevCard();
            } else if (card.type === 'SPECIFIED_ITEMS') {
                specifiedItemRef.current?.goToPrev(goToPrevCard);
            } else {
                goToPrevCard();
            }
        };

        return (
            <Form
                layout="vertical"
                size="large"
                name="_questionnaire"
                form={form}
                onFinish={onFinish}
                onFieldsChange={() => {
                    const values = form.getFieldsValue();
                    // TODO: Add values from previous cards to enable cross card validation
                    setFormValues(values);
                }}
            >
                <ScrollToTopOnMount dep={card.id} />
                <Card
                    key={card.id}
                    title={<CardTitle title={card.title} subTitle={card.description} />}
                    className="crm-ant-card-flexible-header"
                >
                    {DEBUG_MODE && <div>ID: {card.id}</div>}
                    {DEBUG_MODE && <div>NEXT QUESTION: {card.nextCardId}</div>}
                    {DEBUG_MODE && <div>QUESTION TYPE: {card.type}</div>}
                    {card instanceof CardSingleSelect && (
                        <div className="flex flex-wrap">
                            <Space wrap>
                                {card.children?.map((child) => (
                                    <ChildSingleSelect
                                        card={card}
                                        child={child}
                                        readOnly={readOnly}
                                        form={form}
                                        key={child.id}
                                    />
                                ))}
                            </Space>
                        </div>
                    )}
                    {card instanceof CardMultiSelect && (
                        <div className="flex flex-wrap">
                            <Space wrap>
                                {card.children?.map((child) => (
                                    <ChildMultiSelect
                                        card={card}
                                        child={child}
                                        proceedToNextCard={proceedToNextCard}
                                        readOnly={readOnly}
                                        key={child.id}
                                    />
                                ))}
                            </Space>
                        </div>
                    )}
                    {card instanceof CardSpecifiedItems && (
                        <div className="flex flex-col flex-wrap">
                            {card.children?.map((child) => (
                                <ChildSpecifiedItem
                                    child={child}
                                    readOnly={readOnly}
                                    service={service}
                                    key={child.id}
                                    ref={specifiedItemRef}
                                />
                            ))}
                        </div>
                    )}
                    {card instanceof CardForm && (
                        <div>
                            {card.children?.map((child) => (
                                <ChildFormInput
                                    card={card}
                                    formValues={formValues}
                                    readOnly={readOnly}
                                    key={child.id}
                                    child={child}
                                />
                            ))}
                        </div>
                    )}
                    {card instanceof CardText && (
                        <>
                            <Typography.Text>{card.content}</Typography.Text>
                        </>
                    )}
                    {card.validationErrors.length > 0 && (
                        <Alert
                            showIcon
                            type="error"
                            message="Validation error"
                            className="mt-2"
                            description={
                                <>
                                    {card.validationErrors.map((vr, idx) => (
                                        <div key={idx} className="mb-2">
                                            {vr.error?.message}
                                        </div>
                                    ))}
                                </>
                            }
                        />
                    )}
                </Card>
                <ActionBar
                    renderLeft={() => (
                        <Button icon={<ArrowLeftOutlined />} onClick={onBack} size="large">
                            Back
                        </Button>
                    )}
                    renderRight={() => (
                        <>
                            {!readOnly && !!displaySaveButton && (
                                <Button icon={<SaveOutlined />} onClick={onSave} size="large">
                                    Save
                                </Button>
                            )}
                            {!readOnly && onSaveDraftOpen && (
                                <Button
                                    icon={<SaveOutlined />}
                                    onClick={() => {
                                        const values = form.getFieldsValue();
                                        onFinish(values, false);
                                        onSaveDraftOpen();
                                    }}
                                    size="large"
                                >
                                    Save
                                </Button>
                            )}
                            {(card.type !== 'SINGLE_SELECT' || card.answered) && (
                                <Button type="primary" htmlType="submit" size="large">
                                    Next
                                </Button>
                            )}
                        </>
                    )}
                />
            </Form>
        );
    },
);
