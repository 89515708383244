import { errorMessage } from '@/components/Common/errorMessage';
import { UpdateUser } from '@/graphql/mutations/user';
import { useUser } from '@/hooks/User/useUser';
import { useAuthStore } from '@/stores/AuthStore';
import { safePropPath, sleep } from '@/utils/general';
import { useMutation } from '@apollo/client';
import { Avatar, Card, Form, Input } from 'antd';
import Joi from 'joi';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';

type State = {
    name: string;
};

const ProfileSettings = observer(() => {
    const state = useLocalObservable<State>(() => ({
        name: '',
    }));
    const [, setSaving] = useState(false);
    const [form] = Form.useForm();
    const authStore = useAuthStore();
    const user = useUser();
    const [updateUser] = useMutation(UpdateUser);

    useEffect(() => {
        form.resetFields();
        state.name = user.name || '';
        form.setFieldsValue(state);
    }, []);

    const onSave = async () => {
        try {
            setSaving(true);
            await updateUser({
                variables: { data: { name: state.name }, userId: user.id },
            });
            await sleep(1000 * 2);
            const accessToken = await authStore.getAccessToken(user.orgId, false);
            // Refresh
            await authStore.reloadUser(accessToken);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Card>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onSave}>
                <div className="mb-4 flex justify-center">
                    <Avatar src={user.photoUrl} size={96} />
                </div>
                <Form.Item
                    label="Name"
                    name={safePropPath<State>('name')}
                    required
                    rules={[
                        {
                            validator: async () => {
                                const res = Joi.object({
                                    name: Joi.string().min(1).max(30).required(),
                                }).validate({ name: state.name });
                                if (res.error) throw new Error('Field is required');
                            },
                        },
                    ]}
                >
                    <Input value={state.name} onChange={(e) => (state.name = e.target.value)} disabled readOnly />
                </Form.Item>
                <Form.Item label="E-mail">
                    <Input value={user.email || ''} readOnly disabled />
                </Form.Item>
                {/*<Form.Item wrapperCol={{ offset: 6, span: 18 }}>*/}
                {/*    <Button type="primary" htmlType="submit" loading={saving}>*/}
                {/*        Save*/}
                {/*    </Button>*/}
                {/*</Form.Item>*/}
            </Form>
        </Card>
    );
});

export const Route = createFileRoute('/console/$orgId/user-settings/profile')({
    staticData: {
        breadcrumb: {
            title: 'Profile',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: ProfileSettings,
});
