import { ShareableRouteContext } from '@/types/router';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/organizations/$orgId')({
    params: {
        parse({ orgId }) {
            return {
                orgId: BigInt(orgId),
            };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.admin.org.getOrg.ensureData({ orgId: params.orgId });

        return {
            breadcrumb: {
                async title() {
                    const org = await trpcUtils.admin.org.getOrg.ensureData({ orgId: params.orgId });
                    return org?.name || '';
                },
            },
        };
    },
    component: Outlet,
});
