import { useState } from 'react';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useApolloClient } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import { NewMessage } from '@/components/Claim/NewMessage';
import { useClaimReserveService } from '@/hooks/useClaimReserveService';
import { useFormatter } from '@/hooks/useFormatter';
import { generateCommsTemplateData } from 'shared/utils/comm';
import { claimFormStore } from '../../store';
import { CommLogWithNotes } from '../~route';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/comms/add')({
    validateSearch({
        email,
        phone,
        taskToCompleteId,
    }): Partial<{ email: string; phone: string; taskToCompleteId: number }> {
        return {
            email: email as string,
            phone: phone as string,
            taskToCompleteId: taskToCompleteId ? +taskToCompleteId : undefined,
        };
    },
    component: CommsAdd,
});

function CommsAdd() {
    const orgId = useOrgId();
    const claim = claimFormStore.use.formData();
    const apolloClient = useApolloClient();
    const reserveData = useClaimReserveService(claim.id, orgId);
    const formatter = useFormatter();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    return (
        <NewMessage
            entity="claim"
            entityId={claim.id}
            open={open}
            templateData={generateCommsTemplateData(claim, reserveData, formatter)}
            onClose={(refresh) => {
                setOpen(false);

                if (refresh) {
                    void apolloClient.refetchQueries({
                        include: [CommLogWithNotes],
                    });
                }

                navigate({
                    to: '/console/$orgId/claims/details/$id/comms',
                    params: {
                        orgId,
                        id: claim.id,
                    },
                    fromComponent: 'drawer',
                });
            }}
        />
    );
}
