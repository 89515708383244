import { gql } from '@/__generated__';
import { ExpenseItemsByClaimIdQuery, ExpenseItemsByClaimIdQueryVariables, OrderBy } from '@/__generated__/graphql';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { errorMessage } from '@/components/Common/errorMessage';
import { User } from '@/components/Common/User';
import { ExpenseDefs } from '@/global/expense';
import { UpdateExpenseItemByPk } from '@/graphql/mutations/expenses';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { useEventEmitter } from '@/hooks/useEventEmitter';
import { useFormatter } from '@/hooks/useFormatter';
import { enumExpenseAndBillingEvents, ExpenseAndBillingEvents } from '@/types/events';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import type { MenuProps } from 'antd';
import { Button, Card, Col, Dropdown, message, Row, Space, Table, Typography } from 'antd';
import { Key, useState } from 'react';
import { claimFormStore } from '../store';
//import { InvoiceEdit } from './InvoiceEdit';
import { Invoices } from './Invoices';
import { useUser } from '@/hooks/User/useUser';
import { useNavigate } from '@/hooks/useNavigate';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import './index.sass';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/expenses')({
    component: Expenses,
});

type ExpenseItem = ExpenseItemsByClaimIdQuery['ExpenseItem'][number];

type MenuItem = Required<MenuProps>['items'][number];

function Expenses() {
    const claim = claimFormStore.use.formData();
    const orgId = useOrgId();
    const user = useUser();
    const isReadonly = claimFormStore.use.isReadonly();
    const navigate = useNavigate();
    const { useListener } = useEventEmitter<ExpenseAndBillingEvents>();
    const { formatCurrency } = useFormatter();
    const [updateExpenseItemByPk] = useMutation(UpdateExpenseItemByPk);
    const [deleteExpenseItemByPk, { loading: deleting }] = useMutation(DeleteExpenseItemByPk);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [, setSelectedRows] = useState<ExpenseItem[]>([]);
    const [, setOpenInvoiceDrawer] = useState(false);
    // const [invoiceIdToEdit, setInvoiceIdToEdit] = useState(-1);
    const [variables, setVariables] = useState<ExpenseItemsByClaimIdQueryVariables>({
        claimId: claim.id,
        orderBy: [{ id: OrderBy.asc }],
        limit: 10,
        offset: 0,
    });

    const { data, refetch, loading } = useQuery(ExpenseItemsByClaimId, {
        fetchPolicy: 'network-only',
        variables,
        onError: (error) => {
            errorMessage.show(error);
        },
    });

    useListener(
        enumExpenseAndBillingEvents.refetchExpenses,
        () => {
            refetch(variables);
        },
        [],
    );

    useListener(
        enumExpenseAndBillingEvents.cancelExpenseSelection,
        () => {
            setSelectedRowKeys([]);
            setSelectedRows([]);
        },
        [],
    );

    const getActionItems = (record: ExpenseItem): MenuItem[] => {
        const items: MenuItem[] = [
            {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                    navigate({
                        to: '/console/$orgId/claims/details/$id/expenses/$expenseId/edit',
                        params: {
                            orgId,
                            id: claim.id,
                            expenseId: record.id,
                        },
                    });
                },
            },
            {
                key: 'delete',
                label: 'Delete',
                danger: true,
                onClick: async () => {
                    try {
                        await deleteExpenseItemByPk({
                            variables: {
                                id: record.id,
                                orgId: record.orgId,
                            },
                        });
                        refetch(variables);
                    } catch (e) {
                        errorMessage.show(e);
                    }
                },
            },
        ];

        if (user.roles.includes('org_admin')) {
            items.splice(-1, 0, {
                key: 'update_status',
                label: 'Update Status',
                children: ExpenseDefs.aryStatus.map(
                    (status): MenuItem => ({
                        key: status,
                        label: status,
                        onClick: async () => {
                            try {
                                message.info('Updating status...');
                                await updateExpenseItemByPk({
                                    variables: {
                                        input: { status },
                                        id: record.id,
                                        orgId,
                                    },
                                });
                                message.destroy();
                                message.success('Status was successfully updated');
                                refetch(variables);
                            } catch (e) {
                                errorMessage.show(e);
                            }
                        },
                    }),
                ),
            });
        }

        if (record.status === ExpenseDefs.enumStatus.approved && user.roles.includes('org_admin')) {
            items.splice(-1, 0, {
                key: 'invoice',
                label: 'Invoice',
                onClick: () => {
                    setSelectedRows([record]);
                    setOpenInvoiceDrawer(true);
                },
            });
        }

        return items;
    };

    const { tableProps } = useAntdTableOld<ExpenseItem>({
        data: {
            rows: data?.ExpenseItem,
            loading: loading || deleting,
            total: data?.ExpenseItemAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length === 0 ? variables.orderBy : options?.orderBy,
                claimId: claim.id,
            });
        },
        rowKey: 'id',
        columns: [
            {
                title: 'Expense item',
                dataIndex: 'ExpenseType.name',
                sorter: true,
            },
            {
                title: 'Date',
                dataIndex: 'date',
                sorter: true,
            },
            {
                title: 'User',
                dataIndex: 'userId',
                sorter: true,
                render: (value) => <User userId={value} />,
            },
            {
                title: 'Rate code',
                dataIndex: 'rateCode',
                sorter: true,
            },
            {
                title: 'Notes',
                dataIndex: 'note',
                sorter: true,
            },
            {
                title: 'Quantity',
                dataIndex: 'qty',
                sorter: true,
            },
            {
                title: 'Rate',
                dataIndex: 'rate',
                sorter: true,
                render: (value) => formatCurrency(Number(value)),
            },
            {
                title: 'Total',
                dataIndex: 'rate',
                sorter: true,
                render: (value, record: ExpenseItem) => {
                    return formatCurrency(Number(value) * Number(record.qty));
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
            },
            {
                render: (_, record) =>
                    !isReadonly && (
                        <Dropdown
                            trigger={['click']}
                            onOpenChange={(visible) => {
                                if (visible) {
                                    setSelectedRowKeys((prev) => prev.filter((id) => id !== record.id));
                                    setSelectedRows((prev) => prev.filter((item) => item.id !== record.id));
                                }
                            }}
                            disabled={record.status === ExpenseDefs.enumStatus.invoiced}
                            menu={{ items: getActionItems(record) }}
                            placement="bottomRight"
                            arrow
                        >
                            <Button icon={<MoreOutlined />} type="text" />
                        </Dropdown>
                    ),
                fixed: 'right',
            },
        ],
    });
    return (
        <>
            <Outlet />

            {/*
            TODO: Fix this
            {!disabled && openInvoiceDrawer && (
                <InvoiceEdit
                    open={openInvoiceDrawer}
                    onClose={() => {
                        refetch(variables);
                        setOpenInvoiceDrawer(false);
                        setSelectedRowKeys([]);
                        setSelectedRowKeys([]);
                        setInvoiceIdToEdit(-1);
                    }}
                    idToEdit={invoiceIdToEdit}
                    claim={claim}
                    setIdToEdit={(id) => {
                        setInvoiceIdToEdit(id);
                        setSelectedRowKeys([]);
                        setSelectedRows([]);
                    }}
                    expenses={invoiceIdToEdit === -1 ? selectedRows : undefined}
                />
            )} */}

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card
                        title={<Typography.Title level={4}>Expenses</Typography.Title>}
                        extra={
                            <Space className="flex">
                                <CollapsiblePanel open={!!selectedRowKeys.length}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenInvoiceDrawer(true);
                                        }}
                                    >
                                        Invoice selected
                                    </Button>
                                </CollapsiblePanel>
                                <Button
                                    icon={<PlusOutlined />}
                                    disabled={isReadonly}
                                    onClick={() => {
                                        navigate({
                                            to: '/console/$orgId/claims/details/$id/expenses/add',
                                            params: {
                                                orgId,
                                                id: claim.id,
                                            },
                                        });
                                    }}
                                >
                                    Add new expense
                                </Button>
                            </Space>
                        }
                    >
                        <Table
                            {...tableProps}
                            columns={(() => {
                                if (user.roles.includes('org_admin')) {
                                    tableProps.columns.splice(tableProps.columns.length - 1, 0, Table.SELECTION_COLUMN);
                                }
                                return tableProps.columns;
                            })()}
                            rowSelection={
                                !isReadonly && user.roles.includes('org_admin')
                                    ? {
                                          columnTitle: 'Create Invoice',
                                          onChange: (selectedRowKeys, selectedRows) => {
                                              setSelectedRowKeys(selectedRowKeys);
                                              setSelectedRows(selectedRows);
                                          },
                                          selectedRowKeys,
                                          getCheckboxProps: (record) => {
                                              return record.status !== ExpenseDefs.enumStatus.approved
                                                  ? { disabled: true }
                                                  : {};
                                          },
                                          columnWidth: '100px',
                                      }
                                    : undefined
                            }
                            scroll={{ x: true }}
                            rowClassName={(record) => {
                                return record.status === ExpenseDefs.enumStatus.invoiced
                                    ? 'crm-table-row-disabled'
                                    : '';
                            }}
                            size="small"
                        />
                    </Card>
                </Col>
                {user.roles.includes('org_admin') && (
                    <Col span={24}>
                        <Invoices disabled={isReadonly} />
                    </Col>
                )}
            </Row>
        </>
    );
}

const DeleteExpenseItemByPk = gql(/* GraphQL */ `
    mutation DeleteExpenseItemByPk($id: bigint!, $orgId: bigint!) {
        deleteExpenseItemByPk(id: $id, orgId: $orgId) {
            id
        }
    }
`);

export const ExpenseItemsByClaimId = gql(/* GraphQL */ `
    query ExpenseItemsByClaimId(
        $claimId: bigint!
        $limit: Int = 10
        $offset: Int = 0
        $orderBy: [ExpenseItemOrderBy!] = {}
    ) {
        ExpenseItem(where: { claimId: { _eq: $claimId } }, limit: $limit, offset: $offset, orderBy: $orderBy) {
            claimId
            userId
            rateCode
            rate
            qty
            note
            orgId
            id
            date
            status
            appliedTax
            invoiceItemId
            ExpenseType {
                id
                name
            }
        }
        ExpenseItemAggregate(where: { claimId: { _eq: $claimId } }) {
            aggregate {
                count
            }
        }
    }
`);
