import { useState } from 'react';
import { Button, Card, Spin, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { OrderBy, ReportDsQuery, ReportDsQueryVariables } from '@/__generated__/graphql';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { ReportAdd } from './ReportAdd';
import { useFormatter } from '@/hooks/useFormatter';
import { FileLink } from '@/components/Common/FileLink';
import { useQuery } from '@apollo/client';
import { gql } from '@/__generated__/gql';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/reporting')({
    staticData: {
        breadcrumb: {
            title: 'Reporting',
        },
        roles: ['user', 'org_admin'],
        modules: ['claim', 'compliance'],
        restrictToEmployee: false,
    },
    component: Reporting,
});

type Report = ReportDsQuery['Report'][number];

function Reporting() {
    const { formatISODate, formatISODateTime } = useFormatter();
    const [showReportAdd, setShowReportAdd] = useState(false);
    const [variables, setVariables] = useState<ReportDsQueryVariables>({
        where: undefined,
        orderBy: [{ createdAt: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(ReportDs, {
        fetchPolicy: 'network-only',
        variables,
    });

    const { tableProps } = useAntdTableOld<Report>({
        rowKey: 'id',
        data: {
            rows: data?.Report,
            loading,
            total: data?.ReportAggregate?.aggregate?.count,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
        columns: [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Start date',
                dataIndex: 'startDate',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                title: 'End date',
                dataIndex: 'endDate',
                sorter: true,
                render: (value) => formatISODate(value),
            },
            {
                title: 'Link',
                dataIndex: 'id',
                render: (_, { Files }) =>
                    Files ? (
                        <FileLink fileKey={Files.at(0)!.key} fileName={Files.at(0)!.name} label="Download" />
                    ) : (
                        <Spin spinning />
                    ),
            },
            {
                title: 'Created at',
                dataIndex: 'createdAt',
                sorter: true,
                render: (value) => formatISODateTime(value),
            },
        ],
    });

    return (
        <>
            <ReportAdd
                open={showReportAdd}
                onClose={() => {
                    setShowReportAdd(false);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setShowReportAdd(true);
                    }}
                >
                    Generate report
                </Button>
            </div>

            <Card>
                <Table scroll={{ x: true }} size="small" {...tableProps} />
            </Card>
        </>
    );
}

const ReportDs = gql(/* GraphQL */ `
    query ReportDs($limit: Int!, $offset: Int!, $orderBy: [ReportOrderBy!], $where: ReportBoolExp) {
        Report(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
            id
            endDate
            createdAt
            name
            startDate
            userId
            User {
                ...UserInOrgBasicFields
            }
            Files {
                ...FileFragment
            }
        }
        ReportAggregate {
            aggregate {
                count
            }
        }
    }
`);
