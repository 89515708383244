import { trpc } from '@/trpc';
import { skipToken } from '@tanstack/react-query';
import { Button, Card, Descriptions, Drawer, Empty, Tabs, Typography, Tag, Spin } from 'antd';
import type { EmailAddress } from 'shared/types/email';
import { errorMessage } from '../Common/errorMessage';
import { MailOutlined, InfoCircleOutlined, FileTextOutlined, CodeOutlined, DatabaseOutlined } from '@ant-design/icons';

export type EmailDetailsAction = ['view', id: bigint] | ['hide'];

interface EmailDetailsDrawerProps {
    action: EmailDetailsAction;
    onClose: () => void;
    title?: string;
}

export function EmailDetailsDrawer({ action, onClose, title }: EmailDetailsDrawerProps) {
    const id = action[0] === 'view' ? action[1] : null;

    const { data, isPending } = trpc.emailClassification.getEmailClassification.useQuery(id ? { id } : skipToken, {
        enabled: action[0] === 'view',
        throwOnError(error) {
            errorMessage.show(error);
            return true;
        },
    });

    return (
        <Drawer
            title={
                <div className="flex items-center gap-2">
                    <MailOutlined className="text-blue-500" />
                    <span>{title || 'Email Details'}</span>
                </div>
            }
            open={action[0] === 'view'}
            onClose={onClose}
            width={1000}
            footer={
                <div className="flex justify-end">
                    <Button onClick={onClose} size="large">
                        Close
                    </Button>
                </div>
            }
            className="email-details-drawer"
        >
            {isPending ? (
                <div className="flex h-full items-center justify-center">
                    <Spin size="large" tip="Loading email details..." />
                </div>
            ) : data ? (
                <div className="space-y-6">
                    <Card className="shadow-sm transition-shadow duration-200 hover:shadow-md">
                        <div className="flex items-center gap-4">
                            <Typography.Text strong className="text-lg">
                                Type:
                            </Typography.Text>
                            <Tag color="blue" className="px-4 py-1 text-base">
                                {data.issueType}
                            </Tag>
                        </div>
                    </Card>

                    {data.summary && (
                        <Card
                            title={
                                <div className="flex items-center gap-2">
                                    <InfoCircleOutlined className="text-blue-500" />
                                    <span>Summary</span>
                                </div>
                            }
                            className="shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded-lg bg-gray-50 p-6 text-base">
                                {data.summary}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    {data.justification && (
                        <Card
                            title={
                                <div className="flex items-center gap-2">
                                    <FileTextOutlined className="text-blue-500" />
                                    <span>Justification</span>
                                </div>
                            }
                            className="shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded-lg bg-gray-50 p-6 text-base">
                                {data.justification}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    {data.breachedRegulation && (
                        <Card
                            title={
                                <div className="flex items-center gap-2 text-red-500">
                                    <InfoCircleOutlined />
                                    <span>Breached Regulation</span>
                                </div>
                            }
                            className="border-red-100 shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <Typography.Paragraph className="mt-2 whitespace-pre-wrap rounded-lg bg-red-50 p-6 text-base">
                                {data.breachedRegulation}
                            </Typography.Paragraph>
                        </Card>
                    )}

                    <Card
                        title={
                            <div className="flex items-center gap-2">
                                <MailOutlined className="text-blue-500" />
                                <span>Email Content</span>
                            </div>
                        }
                        className="shadow-sm transition-shadow duration-200 hover:shadow-md"
                    >
                        <Tabs
                            defaultActiveKey="header"
                            type="card"
                            items={[
                                {
                                    key: 'header',
                                    label: (
                                        <span className="flex items-center gap-2">
                                            <InfoCircleOutlined />
                                            Header
                                        </span>
                                    ),
                                    children: (
                                        <div className="mt-4 max-h-[400px] overflow-auto rounded-lg bg-gray-50 p-6">
                                            <Card className="shadow-sm">
                                                <Descriptions
                                                    column={1}
                                                    className="email-header [&_.ant-descriptions-item-content:is(.ant-descriptions-item-content)]:flex [&_.ant-descriptions-item-content:is(.ant-descriptions-item-content)]:items-center [&_.ant-descriptions-item-content:is(.ant-descriptions-item-content)]:pb-4 [&_.ant-descriptions-item-label:is(.ant-descriptions-item-label)]:flex [&_.ant-descriptions-item-label:is(.ant-descriptions-item-label)]:items-center [&_.ant-descriptions-item-label:is(.ant-descriptions-item-label)]:pb-4 [&_.ant-descriptions-item-label:is(.ant-descriptions-item-label)]:font-bold [&_.ant-descriptions-item-label:is(.ant-descriptions-item-label)]:text-gray-600"
                                                >
                                                    <Descriptions.Item label="Subject">
                                                        <Typography.Text strong className="text-lg">
                                                            {data.subject}
                                                        </Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Email ID">
                                                        <Typography.Text copyable className="text-blue-500">
                                                            {data.emailId}
                                                        </Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="From">
                                                        {displayEmailAddresses(data.from)}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="To">
                                                        {displayEmailAddresses(data.to)}
                                                    </Descriptions.Item>
                                                    {data.cc?.length > 0 && (
                                                        <Descriptions.Item label="CC">
                                                            {displayEmailAddresses(data.cc)}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.bcc?.length > 0 && (
                                                        <Descriptions.Item label="BCC">
                                                            {displayEmailAddresses(data.bcc)}
                                                        </Descriptions.Item>
                                                    )}
                                                </Descriptions>
                                            </Card>
                                        </div>
                                    ),
                                },
                                {
                                    key: 'html',
                                    label: (
                                        <span className="flex items-center gap-2">
                                            <CodeOutlined />
                                            HTML
                                        </span>
                                    ),
                                    children: data.bodyHtml ? (
                                        <div
                                            className="mt-4 max-h-[400px] overflow-auto rounded-lg bg-gray-50 p-6"
                                            dangerouslySetInnerHTML={{
                                                __html: data.bodyHtml,
                                            }}
                                        />
                                    ) : (
                                        <Empty
                                            className="my-8"
                                            description={
                                                <span className="text-gray-500">No HTML content available</span>
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: 'text',
                                    label: (
                                        <span className="flex items-center gap-2">
                                            <FileTextOutlined />
                                            Plain Text
                                        </span>
                                    ),
                                    children: (
                                        <Typography.Paragraph className="mt-4 max-h-[400px] overflow-auto whitespace-pre-wrap rounded-lg bg-gray-50 p-6">
                                            {data.bodyText || 'No plain text content available'}
                                        </Typography.Paragraph>
                                    ),
                                },
                            ]}
                        />
                    </Card>

                    {(data.extractedData as any) && (
                        <Card
                            title={
                                <div className="flex items-center gap-2">
                                    <DatabaseOutlined className="text-blue-500" />
                                    <span>Extracted Data</span>
                                </div>
                            }
                            className="shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <pre className="m-0 mt-2 max-h-[300px] overflow-auto rounded-lg bg-gray-50 p-6 text-sm">
                                {JSON.stringify(data.extractedData, null, 2)}
                            </pre>
                        </Card>
                    )}
                </div>
            ) : null}
        </Drawer>
    );
}

function displayEmailAddresses(emailAddresses: EmailAddress[] | undefined) {
    if (!emailAddresses) {
        return '-';
    }
    return emailAddresses
        .map((email) => {
            if (email.name) {
                return `${email.name} <${email.email}>`;
            }
            return email.email;
        })
        .join(', ');
}
