import { createFileRoute } from '@tanstack/react-router';
import { Result } from 'antd';
import { Link } from '@/components/Common/Link';

export const Route = createFileRoute('/(authentication)/payment-pending')({
    component: PaymentPending,
});

function PaymentPending() {
    return (
        <div className="grid h-screen w-full place-items-center bg-gray-50">
            <Result
                status="warning"
                title="Account Top Up Required"
                subTitle={
                    <div className="text-center">
                        <p>Your account balance needs to be topped up to continue using the service.</p>
                        <p className="mt-2">
                            We have sent an invoice to your email address with instructions on how to add funds to your
                            account.
                        </p>
                        <div className="mt-6">
                            <Link to="/login" type="link">
                                Back to login
                            </Link>
                        </div>
                    </div>
                }
                className="mx-auto w-full max-w-lg rounded-lg bg-white p-8 shadow-sm"
            />
        </div>
    );
}
