import { useEffect } from 'react';
import { App, Card } from 'antd';
import { createFileRoute } from '@tanstack/react-router';
import { Form, Checkbox, Divider, Skeleton, Button, Space } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { RouterInputs, trpc } from '@/trpc';
import { skipToken } from '@tanstack/react-query';
import { useUserId } from '@/hooks/User/useUserId';

export const Route = createFileRoute('/console/$orgId/user-settings/notifications')({
    staticData: {
        breadcrumb: {
            title: 'Notifications',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: NotificationSettings,
});

type UserNotificationInput = RouterInputs['user']['updateUserNotifications']['set'];

function NotificationSettings() {
    const userId = useUserId();
    const { message } = App.useApp();
    const { data, isPending } = trpc.user.getUserNotifications.useQuery(userId ? { userId } : skipToken);
    const { mutateAsync: updateUserNotifications, isPending: isUpdating } =
        trpc.user.updateUserNotifications.useMutation();

    const [form] = Form.useForm<UserNotificationInput>();

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data]);

    const onSave = async (values: UserNotificationInput) => {
        try {
            void message.loading('Saving...');

            await updateUserNotifications({
                userId,
                set: values,
            });

            void message.destroy();
            void message.loading('Saved.');
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        }
    };

    return (
        <Card>
            {isPending ? (
                <Skeleton active />
            ) : (
                <Form form={form} onFinish={onSave}>
                    <Divider plain orientation="left">
                        Tasks
                    </Divider>
                    <Form.Item name="notificationAssignedToTask" valuePropName="checked">
                        <Checkbox>Assigned to task</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationNewUnallocatedTask" valuePropName="checked">
                        <Checkbox>New unallocated task</Checkbox>
                    </Form.Item>
                    <Divider plain orientation="left">
                        Complaints
                    </Divider>
                    <Form.Item name="notificationComplaintCreated" valuePropName="checked">
                        <Checkbox>Complaint submitted</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationComplaintCreatedInMyExternalOrg" valuePropName="checked">
                        <Checkbox>Complaint created for my handling party</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationAssignedToComplaintAsOwner" valuePropName="checked">
                        <Checkbox>Assigned as owner</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationComplaintIndicatorChanged" valuePropName="checked">
                        <Checkbox>Complaint indicator changed</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationComplaintPublicSubmitted" valuePropName="checked">
                        <Checkbox>Public complaint submitted</Checkbox>
                    </Form.Item>
                    <Divider plain orientation="left">
                        Incidents
                    </Divider>
                    <Form.Item name="notificationIncidentSubmitted" valuePropName="checked">
                        <Checkbox>Incident submitted</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationIncidentAssignedAsOwner" valuePropName="checked">
                        <Checkbox>Assigned as owner</Checkbox>
                    </Form.Item>
                    <Form.Item name="notificationIncidentIndicatorChanged" valuePropName="checked">
                        <Checkbox>Incident indicator changed</Checkbox>
                    </Form.Item>
                    <Space className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={isUpdating}>
                            Save
                        </Button>
                    </Space>
                </Form>
            )}
        </Card>
    );
}
