import { Col, Row } from 'antd';
import { createFileRoute } from '@tanstack/react-router';
import { errorMessage } from '@/components/Common/errorMessage';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { UserOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { parse } from 'json2csv';

export const Route = createFileRoute('/admin-console/reports')({
    staticData: {
        breadcrumb: {
            title: 'Reports',
        },
    },
    component: Reports,
});

function Reports() {
    const spanConfig = {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 6,
    };
    const trpcClient = useTrpcClient();

    const handleDownloadCSV = async () => {
        try {
            const activeUsers = await trpcClient.admin.report.activeUsers.query();

            const csv = parse(activeUsers, {
                fields: ['id', 'email', 'fullName', 'firstName', 'lastName'],
            });

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);

            link.setAttribute('href', url);
            link.setAttribute('download', `active-users-${new Date().toISOString().split('T')[0]}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            errorMessage.show(error);
        }
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Users Statistics */}
            <Col {...spanConfig}>
                <Card
                    title={
                        <div className="flex items-center gap-2">
                            <UserOutlined className="text-green-500" />
                            <span>Active users </span>
                        </div>
                    }
                    className="h-full"
                >
                    <div className="text-sm text-gray-400">Useful for email campaigns and user outreach</div>
                    <Button type="primary" size="small" className="float-right mt-4" onClick={handleDownloadCSV}>
                        Download CSV
                    </Button>
                </Card>
            </Col>
        </Row>
    );
}
