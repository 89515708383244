import { errorMessage } from '@/components/Common/errorMessage';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { createFileRoute } from '@tanstack/react-router';
import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { navRoutes } from 'shared/navigation/navRoutes';

export const Route = createFileRoute('/console/$orgId/get-file')({
    staticData: {
        breadcrumb: {
            title: 'Get file',
        },
    },
    component: GetFile,
});

function GetFile() {
    const { key } = useSearchParams(navRoutes.getFile);
    const [downloadedSuccessfully, setDownloadedSuccessfully] = useState(false);
    const trpcClient = useTrpcClient();

    const downloadFile = async () => {
        try {
            message.info('Downloading file...');

            const decodedKey = decodeURIComponent(key!);

            const url = await trpcClient.file.getDownloadUrl.query({
                key: decodedKey,
            });
            window.open(url, '_self');
            setDownloadedSuccessfully(true);
        } catch (e) {
            errorMessage.show(e);
        } finally {
            message.destroy();
        }
    };

    useEffect(() => {
        if (key) {
            void downloadFile();
        }
    }, [key]);

    return downloadedSuccessfully ? (
        <Alert
            message="File downloaded successfully."
            description="You can close this tab now."
            type="success"
            showIcon
        />
    ) : (
        <Alert message="File download failed." description="Please try again." type="error" showIcon />
    );
}
