import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Card, Input, Popconfirm, Space, Table } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { HandlingPartyEdit } from './HandlingPartyEdit';
import { commonActionColumn } from '@/utils/table';
import { grey } from '@ant-design/colors';
import { useState } from 'react';
import { RouterInputs, trpc } from '@/trpc';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { Action } from '@/types/common';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/handling-parties')({
    staticData: {
        breadcrumb: {
            title: 'Handling parties',
        },
        ui: {
            title: 'Handling parties configuration',
        },
        modules: ['claim', 'compliance', 'risk'],
    },
    component: ConfigurationHandlingParties,
});

function ConfigurationHandlingParties() {
    const { message } = App.useApp();
    const deleteHandlingPartyMutation = trpc.handlingParty.deleteHandlingParty.useMutation();

    const [action, setAction] = useState<Action>(['none']);
    const [input, setInput] = useState<RouterInputs['handlingParty']['listHandlingParties']>({
        orderBy: { id: 'desc' },
        limit: 10,
        offset: 0,
    });

    const { data, isPending, refetch } = trpc.handlingParty.listHandlingParties.useQuery(input, {
        throwOnError: (error) => {
            errorMessage.show(error.message);
            return false;
        },
    });

    const { tableProps } = useAntdTable({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending || deleteHandlingPartyMutation.isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setInput({
                ...input,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? options?.orderBy : input.orderBy,
            });
        },
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
                render: String,
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'isDisabled',
                title: 'Is disabled?',
                sorter: true,
                render: (value) => <div style={{ color: value ? 'red' : 'green' }}>{value ? 'Yes' : 'No'}</div>,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                message.info('Deleting...');

                                await deleteHandlingPartyMutation.mutateAsync({
                                    id: value,
                                });

                                message.destroy();
                                message.success('Deleted.');

                                void refetch();
                            } catch (e) {
                                message.destroy();
                                errorMessage.show(e);
                            }
                        }}
                    >
                        <Button
                            type="text"
                            size="small"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
    });

    return (
        <>
            <HandlingPartyEdit
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <Space className="mb-4 flex justify-between">
                <Input.Search
                    placeholder="Search"
                    onSearch={(value) =>
                        setInput((prev) => ({
                            ...prev,
                            where: {
                                name: {
                                    contains: value,
                                    mode: 'insensitive',
                                },
                            },
                        }))
                    }
                    onClear={() =>
                        setInput((prev) => ({
                            ...prev,
                            where: undefined,
                        }))
                    }
                    allowClear
                    enterButton="Search"
                    className="max-w-96"
                />
                <Button icon={<PlusOutlined />} onClick={() => setAction(['add'])}>
                    Add new
                </Button>
            </Space>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => setAction(['edit', id]),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
