import { COMPLAINT_STATUS, COMPLAINANT_TYPE } from '../types/complaint';

export type Option = { readonly label: string; readonly code: number };

export const COMPLAINT_MILESTONES = {
    closed: {
        internalName: 'closed',
        label: 'Closed',
        labelWhenCompleted: 'Closed',
    },
    received: {
        internalName: 'received',
        label: 'Received',
        labelWhenCompleted: 'Received',
    },
    registered: {
        internalName: 'registered',
        label: 'Registered',
        labelWhenCompleted: 'Registered',
    },
    reOpened: {
        internalName: 'reopened',
        label: 'Re-opened',
        labelWhenCompleted: 'Re-opened',
    },
    ownerChanged: {
        internalName: 'owner_changed',
        label: 'Owner changed to {{owner}}',
        labelWhenCompleted: 'Owner changed to {{owner}}',
    },
    acknowledgeReceipt: {
        internalName: 'acknowledge_receipt',
        label: 'Acknowledge receipt',
        labelWhenCompleted: 'Receipt Acknowledged',
    },
    respondToClaimant: {
        internalName: 'respond_to_claimant',
        label: 'Respond to the claimant',
        labelWhenCompleted: 'Responded',
    },
    resolve: {
        internalName: 'resolve',
        label: 'Resolve',
        labelWhenCompleted: 'Resolved',
    },
};

export const COMPLAINANT_TYPES: Array<Option> = [
    { label: COMPLAINANT_TYPE.INDIVIDUAL, code: 1 },
    { label: COMPLAINANT_TYPE.SMALL_BUSINESS, code: 2 },
    { label: COMPLAINANT_TYPE.OTHER, code: 3 },
    { label: COMPLAINANT_TYPE.UNKNOWN, code: 9 },
];

export const GENDERS: Array<Option> = [
    { label: 'Male', code: 1 },
    { label: 'Female', code: 2 },
    { label: 'Non-binary', code: 3 },
    { label: 'Unknown or not applicable', code: 9 },
];
export const AGES: Array<Option> = [
    { label: 'Less than 18 years', code: 1 },
    { label: '18-24 years', code: 2 },
    { label: '25-34 years', code: 3 },
    { label: '35-44 years', code: 4 },
    { label: '45-54 years', code: 5 },
    { label: '55-64 years', code: 6 },
    { label: '65-74 years', code: 7 },
    { label: '75 years and over', code: 8 },
    { label: 'Unknown or not applicable', code: 9 },
];
export const STATUSES: Array<Option> = [
    { label: COMPLAINT_STATUS.OPEN, code: 1 },
    { label: COMPLAINT_STATUS.RE_OPENED, code: 1 },
    { label: COMPLAINT_STATUS.CLOSED, code: 2 },
];
export const CHANNELS: Array<Option> = [
    { label: 'Face to face', code: 1 },
    { label: 'Phone', code: 2 },
    { label: 'Email', code: 3 },
    { label: 'Web chat', code: 4 },
    { label: 'Online form', code: 5 },
    { label: 'Social media', code: 6 },
    { label: 'Referral from AFCA', code: 7 },
    { label: 'Other', code: 8 },
    { label: 'Unknown', code: 9 },
];
export const RELATED_TO_AFS_LICENSEE: Array<Option> = [
    { label: 'Yes', code: 1 },
    { label: 'No', code: 2 },
    { label: 'Unknown', code: 9 },
];
export const PRODUCTS: Array<Option> = [
    { label: 'Consumer credit insurance', code: 40 },
    { label: 'Home building', code: 41 },
    { label: 'Home contents', code: 42 },
    { label: 'Landlord insurance', code: 43 },
    { label: 'Motor vehicle—Comprehensive', code: 44 },
    { label: 'Motor vehicle—Third-party (fire and theft)', code: 45 },
    { label: 'Motor vehicle—Third-party', code: 46 },
    { label: 'Motor vehicle—Uninsured third-party', code: 47 },
    { label: 'Personal and domestic property—Mobile phone', code: 48 },
    {
        label: 'Personal and domestic property—Domestic pet/horse',
        code: 49,
    },
    { label: 'Personal and domestic property—Caravan/trailer', code: 50 },
    { label: 'Personal and domestic property—Pleasure craft', code: 51 },
    {
        label: 'Personal and domestic property—Valuables/other moveable property',
        code: 52,
    },
    { label: 'Residential strata title', code: 53 },
    { label: 'Sickness and accident insurance', code: 54 },
    { label: 'Ticket insurance', code: 55 },
    { label: 'Travel insurance', code: 56 },
    { label: 'Trust bond', code: 57 },
    { label: 'Other domestic insurance', code: 58 },
    { label: 'Brown goods', code: 59 },
    { label: 'Motor vehicles', code: 60 },
    { label: 'White goods', code: 61 },
    { label: 'Other extended warranty', code: 62 },
    { label: 'Medical indemnity insurance', code: 63 },
    { label: 'Other professional indemnity', code: 64 },
    { label: 'Commercial property', code: 65 },
    { label: 'Commercial vehicle', code: 66 },
    { label: 'Computer and electronic breakdown', code: 67 },
    { label: 'Contractors all risk', code: 68 },
    { label: 'Fire or accident damage', code: 69 },
    { label: 'Glass', code: 70 },
    { label: 'Industrial special risk', code: 71 },
    { label: 'Land transit', code: 72 },
    { label: 'Livestock', code: 73 },
    { label: 'Loss of profits/business interruption', code: 74 },
    { label: 'Machinery breakdowns', code: 75 },
    { label: 'Money', code: 76 },
    { label: 'Public liability', code: 77 },
    { label: 'Thefts', code: 78 },
    { label: 'Other small business/farm insurance', code: 79 },
];

export const OUTCOMES = [
    { label: 'Service-based remedy', code: 1 },
    { label: 'Monetary remedy', code: 2 },
    { label: 'Contract/policy variation', code: 3 },
    { label: 'Decision changed', code: 4 },
    { label: 'Other remedy', code: 5 },
    { label: 'Withdrawn/discontinued', code: 6 },
    { label: 'Referred to another financial firm', code: 7 },
    { label: 'No remedy provided/ apology or explanation only', code: 8 },
] as const;

export type Outcome = (typeof OUTCOMES)[number]['label'];

export const RELATIONSHIPS = [
    'Insured',
    'Relative / friend',
    'Broker',
    'Power of Attorney',
    'Legal Representative',
    'Executor of an Estate',
    'Service provider',
];

export const COMPLAINANT_SOLUTIONS = ['Apology', 'Compensation', 'Refund', 'Change of decision', 'Free service'];

export const ISSUES: Array<{ category: string; issues: Array<Option> }> = [
    {
        category: 'Advice',
        issues: [
            { label: 'Quality of advice', code: 1 },
            { label: 'Failure to provide advice', code: 2 },
            { label: 'Other advice-related issues', code: 3 },
        ],
    },
    {
        category: 'Charges',
        issues: [
            { label: 'Break costs', code: 4 },
            { label: 'Deductible or excess', code: 5 },
            { label: 'Commissions', code: 6 },
            { label: 'Fees/costs', code: 7 },
            { label: 'Interest', code: 8 },
            { label: 'Foreign exchange (forex) rate', code: 9 },
            { label: 'Premiums', code: 10 },
            { label: 'Tax', code: 11 },
            { label: 'No claim bonus', code: 12 },
            { label: 'Other charges-related issues', code: 13 },
        ],
    },
    {
        category: 'Consumer Data Right (CDR)',
        issues: [
            { label: 'CDR data collection', code: 14 },
            { label: 'CDR data use or disclosure', code: 15 },
            { label: 'CDR data maintenance', code: 16 },
            {
                label: 'CDR security and destruction/de-identification',
                code: 17,
            },
            { label: 'CDR data correction', code: 18 },
            { label: 'CDR advice', code: 19 },
            { label: 'Other CDR-related issues', code: 20 },
        ],
    },
    {
        category: 'Disclosure',
        issues: [
            { label: 'Break costs disclosure', code: 26 },
            { label: 'Fee disclosure', code: 27 },
            { label: 'Failure to provide disclosure documents', code: 28 },
            { label: 'Product/service information', code: 29 },
            { label: 'Other disclosure-related issues', code: 30 },
        ],
    },
    {
        category: 'Financial difficulty/debt collection',
        issues: [
            {
                label: 'Financial firm failure to respond to request for assistance',
                code: 31,
            },
            { label: 'Decline of financial difficulty request', code: 32 },
            { label: 'Financial difficulty arrangement', code: 33 },
            { label: 'Default judgment obtained', code: 34 },
            { label: 'Default notice', code: 35 },
            { label: 'Request to suspend enforcement proceedings', code: 36 },
            { label: 'Disputed liability', code: 37 },
            { label: 'Initial debt collection contact', code: 38 },
            { label: 'Handling of debt collection', code: 39 },
            {
                label: 'Other issues relating to financial difficulty/debt collection',
                code: 40,
            },
        ],
    },
    {
        category: 'Decision related to insurance',
        issues: [
            { label: 'Insurance coverage terms', code: 45 },
            { label: 'Cancellation of policy', code: 46 },
            { label: 'Claim amount', code: 47 },
            { label: 'Denial of claim—Complainant non-disclosure', code: 48 },
            { label: 'Denial of claim—Driving under influence', code: 49 },
            { label: 'Denial of claim—Exclusion/condition', code: 50 },
            { label: 'Denial of claim—Fraudulent claim', code: 51 },
            { label: 'Denial of claim—No policy or contract', code: 52 },
            { label: 'Denial of claim—No proof of loss', code: 53 },
            { label: 'Denial of claim—Other', code: 54 },
        ],
    },
    {
        category: 'Decision - general',
        issues: [
            {
                label: 'Interpretation of product terms and conditions',
                code: 58,
            },
            { label: 'Unfair contract terms', code: 59 },
            { label: 'Unconscionable conduct', code: 60 },
            { label: 'Denial of application or variation request', code: 61 },
            {
                label: 'Margin call notice and/or investment liquidation',
                code: 62,
            },
            { label: 'Valuation', code: 63 },
            { label: 'Commercial practice or policy', code: 64 },
            { label: 'Other decision-related issues', code: 65 },
        ],
    },
    {
        category: 'Instructions',
        issues: [
            { label: 'Delay in following instructions', code: 66 },
            { label: 'Failure to follow instructions/agreement', code: 67 },
            { label: 'Other instructions-related issues', code: 68 },
        ],
    },
    {
        category: 'Privacy & confidentiality',
        issues: [
            { label: 'Failure/refusal to provide access', code: 70 },
            { label: 'Unauthorised information disclosed', code: 71 },
            {
                label: 'Other issues relating to privacy and/or confidentiality',
                code: 72,
            },
        ],
    },
    {
        category: 'Scams/fraud',
        issues: [
            { label: 'Victim of scam', code: 73 },
            { label: 'Fraud/forgery by financial firm', code: 74 },
            { label: 'Fraud/forgery by a third party', code: 75 },
            { label: 'Other scam/fraud-related issues', code: 76 },
        ],
    },
    {
        category: 'Service',
        issues: [
            { label: 'Account administration error', code: 77 },
            { label: 'Delay in claim handling', code: 78 },
            { label: 'Delay in complaint handling', code: 79 },
            { label: 'General service delay', code: 80 },
            { label: 'Failure to provide special needs assistance', code: 81 },
            { label: 'Incorrect financial information provided', code: 82 },
            { label: 'Loss of documents/personal property', code: 83 },
            { label: 'Management of complainant details', code: 84 },
            { label: 'Technical problems', code: 85 },
            { label: 'Branch closure or opening hours', code: 86 },
            { label: 'Loyalty or reward points', code: 87 },
            { label: 'Other service-related issues', code: 88 },
        ],
    },
    {
        category: 'Transactions',
        issues: [
            { label: 'Chargebacks—Declined (consumer)', code: 89 },
            { label: 'Chargebacks—Delayed (consumer)', code: 90 },
            { label: 'Chargebacks—Merchant', code: 91 },
            { label: 'Dishonoured transactions', code: 92 },
            { label: 'Incorrect payment', code: 93 },
            { label: 'Mistaken internet payment', code: 94 },
            { label: 'Unauthorised transaction', code: 95 },
            { label: 'Other transaction-related issues', code: 96 },
        ],
    },
];

export const COMPLAINT_STATISTIC_SETTINGS = {
    COMPLAINTS_PER_USER_STEPS: [0, 2],
    COMPLAINTS_OPEN_MORE_THAN_30_DAYS_PERCENTAGE_STEPS: [50, 80],
} as const;
