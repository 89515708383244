import Joi from 'joi';
import LogRocket from 'logrocket';
import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import { getLaunchDarklyProvider } from './components/Providers/LaunchDarklyProvider';
import { TanstackRouterProvider } from './components/Providers/TanstackRouterProvider';
import './config/sentry';

configure({
    enforceActions: 'never',
});

//@ts-expect-error BigInt.prototype.toJSON is not defined in TypeScript
BigInt.prototype.toJSON = function () {
    const int = Number.parseInt(this.toString());
    return int ?? this.toString();
};

(async () => {
    const LaunchDarklyProvider = await getLaunchDarklyProvider();

    try {
        if (['production', 'staging'].includes(import.meta.env.VITE_ENV)) {
            LogRocket.init('cuen3v/curium');
        }
    } catch (e) {
        console.error('Error initializing LogRocket', e);
    }

    const container = document.getElementById('root');
    const root = createRoot(container!);

    window.Joi = Joi;

    root.render(
        <LaunchDarklyProvider>
            <TanstackRouterProvider />
        </LaunchDarklyProvider>,
    );
})();
