import { observer, useLocalObservable } from 'mobx-react-lite';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Table } from 'antd';
import { useTableDataSource } from '@/hooks/Table/useTableDataSource';
import { CommTemplateBoolExp, CommTemplateDsQuery } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { CommTemplateEdit } from './CommTemplateEdit';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { gql } from '@/__generated__';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CommTemplateDs } from '@/graphql/queries/commTemplate';
import { commonActionColumn } from '@/utils/table';
import { grey } from '@ant-design/colors';
import { createFileRoute } from '@tanstack/react-router';

type CommTemplate = CommTemplateDsQuery['CommTemplate'][number];

type State = {
    commTemplateIdToEdit?: number;
};

const ConfigurationCommsTemplates = observer(() => {
    const orgId = useOrgId();
    const [getCommTemplateDs] = useLazyQuery(CommTemplateDs);
    const [deleteCommTemplateByPk] = useMutation(DeleteCommTemplateByPk);

    const state = useLocalObservable<State>(() => ({}));

    const { tableProps, refetch, setLoading } = useTableDataSource<CommTemplate, CommTemplateBoolExp>({
        rowKey: 'id',
        columns: [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: true,
                align: 'center',
            },
            {
                dataIndex: 'name',
                title: 'Name',
                sorter: true,
            },
            {
                dataIndex: 'internalName',
                title: 'Internal Name',
                sorter: true,
            },
            {
                dataIndex: 'channel',
                title: 'Channel',
                sorter: true,
            },
            {
                ...commonActionColumn,
                render: (value) => (
                    <Popconfirm
                        title="Are you sure?"
                        onPopupClick={(e) => e.stopPropagation()}
                        onConfirm={async () => {
                            try {
                                setLoading(true);
                                await deleteCommTemplateByPk({
                                    variables: {
                                        id: value,
                                        orgId,
                                    },
                                });

                                void refetch('network-only');
                            } catch (e) {
                                errorMessage.show(e);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        <Button
                            type="text"
                            size="small"
                            icon={<DeleteOutlined style={{ color: grey.primary }} />}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </Popconfirm>
                ),
            },
        ],
        getResults: async (options) => {
            try {
                const { data } = await getCommTemplateDs({
                    variables: {
                        limit: options!.limit! || 10,
                        offset: options!.offset! || 0,
                        ...(options?.orderBy && { orderBy: options.orderBy }),
                    },
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                return {
                    rows: data?.CommTemplate,
                    total: data?.CommTemplateAggregate?.aggregate?.count || 0,
                };
            } catch (e) {
                return { error: e };
            }
        },
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
        onError: (error) => errorMessage.show(error),
    });

    return (
        <>
            <CommTemplateEdit
                commTemplateId={state.commTemplateIdToEdit}
                open={!!state.commTemplateIdToEdit}
                onClose={() => {
                    state.commTemplateIdToEdit = undefined;
                    void refetch('network-only');
                }}
            />
            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        state.commTemplateIdToEdit = -1;
                    }}
                >
                    Add new
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: () => (state.commTemplateIdToEdit = id),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
});

export const Route = createFileRoute('/console/$orgId/configuration/comms-templates')({
    staticData: {
        breadcrumb: {
            title: 'Communication templates',
        },
        ui: {
            title: 'Communication templates configuration',
        },
        modules: ['claim'],
    },
    component: ConfigurationCommsTemplates,
});

const DeleteCommTemplateByPk = gql(/* GraphQL */ `
    mutation DeleteCommTemplateByPk($id: bigint!, $orgId: bigint!) {
        deleteCommTemplateByPk(id: $id, orgId: $orgId) {
            id
        }
    }
`);
