import { useAuthStore } from '@/stores/AuthStore';
import { ApolloContext } from '@/types/apollo';
import { logInDev } from '@/utils/general';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { cache } from './cache';
import { PropsWithChildren, useEffect, useMemo } from 'react';

export function ApolloSetup({ children }: PropsWithChildren) {
    // Get non-reactive authStore state
    const { setApolloClient, setWsLink, getAuthToken } = useAuthStore.getState();

    const httpLink = createHttpLink({
        uri: import.meta.env.VITE_HASURA_URL,
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            logInDev('GraphQLErrors', graphQLErrors, operation, networkError);
        }
        if (networkError) {
            logInDev(`[onError: Network error]: ${networkError}`);
        }
        forward(operation);
    });

    const authLink = new ApolloLink((operation, forward) => {
        const ctx = operation.getContext() as ApolloContext | undefined;
        if (ctx && ctx.userType === 'anonymous') {
            operation.setContext({
                headers: {
                    ...operation.getContext().headers,
                    'x-recaptcha-token': ctx.recaptchaToken,
                },
            });
            return forward(operation);
        }

        if (operation.operationName === 'IntrospectionQuery') {
            return forward(operation);
        }

        const authToken = getAuthToken();
        if (!authToken) {
            return forward(operation);
        } else {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${authToken}`,
                },
            });
            return forward(operation);
        }
    });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: import.meta.env.VITE_HASURA_URL_WSS,
            connectionParams: () => {
                logInDev('Setting wsLink auth header');
                return {
                    reconnect: true,
                    lazy: true,
                    headers: {
                        ...(getAuthToken() && {
                            authorization: `Bearer ${getAuthToken()}`,
                        }),
                    },
                };
            },
        }),
    );

    const retryLink = new RetryLink({
        delay: {
            // 5 seconds initial delay
            initial: 5 * 1000,
            // 2 minutes max delay
            max: 2 * 60 * 1000,
            jitter: true,
        },
        attempts: (count, operation) => {
            if (count > 10) {
                return false;
            }

            const definition = getMainDefinition(operation.query);
            if (definition.kind === 'OperationDefinition' && definition.operation === 'mutation' && count > 5) {
                return false;
            }

            return true;
        },
    });

    const link = ApolloLink.from([
        errorLink,
        retryLink,
        authLink,
        split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            httpLink,
        ),
    ]);

    const client = useMemo(
        () =>
            new ApolloClient({
                cache,
                link,
                // Provide some optional constructor fields
                name: 'react-web-client',
                queryDeduplication: false,
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                    },
                },
            }),
        [],
    );

    useEffect(() => {
        setApolloClient(client);
        setWsLink(wsLink);
    }, [client, wsLink]);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
