import Decimal from 'decimal.js';
import { DateTime } from 'luxon';

type LuxonFormatToken =
    | 'yyyy'
    | 'MM'
    | 'dd'
    | 'HH'
    | 'mm'
    | 'MMMM'
    | 'ss'
    | 'dd MMM'
    | 'yyyy-MM-dd'
    | 'HH:mm'
    | 'HH:mm:ss'
    | 'yyyy-MM-dd HH:mm:ss'
    | 'dd/MM/yyyy HH:mm'
    | 'dd/MM/yyyy'
    | 'dd/MM/yyyy HH:mm:ss'
    | 'dd/MM/yyyy hh:mm a'
    | 'h:mm a'
    | 'date-time'
    | 'date-short'
    | 'time';

const FORMAT_MAPPING: Partial<Record<LuxonFormatToken, string>> = {
    'date-time': 'dd/MM/yyyy HH:mm:ss',
    'date-short': 'dd/MM/yyyy',
    time: 'HH:mm a',
} as const;

export const LUXON_FORMAT_TOKENS = {
    'h:mm a': 'h:mm a',
    'HH:mm:ss': 'HH:mm:ss',
    'dd MMM': 'dd MMM',
    'yyyy-MM-dd': 'yyyy-MM-dd',
    'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd HH:mm:ss',
    'dd/MM/yyyy': 'dd/MM/yyyy',
    'dd/MM/yyyy HH:mm': 'dd/MM/yyyy HH:mm',
    'dd/MM/yyyy HH:mm:ss': 'dd/MM/yyyy HH:mm:ss',
    'dd/MM/yyyy hh:mm a': 'dd/MM/yyyy hh:mm a',
} as const;

export function formatDateTime(dt: Date | null | undefined, dtFormat: LuxonFormatToken = 'dd/MM/yyyy HH:mm') {
    return dt ? DateTime.fromJSDate(dt).toFormat(dtFormat) : '';
}

export function formatISODateTime(isoDt: string | null | undefined, dtFormat: LuxonFormatToken = 'dd/MM/yyyy HH:mm') {
    return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat) : '';
}

export function formatISOTime(isoDt: string | null | undefined, dtFormat: LuxonFormatToken = 'HH:mm') {
    return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat) : '';
}

export function formatDate(dt: Date | null | undefined, dtFormat: LuxonFormatToken = 'dd/MM/yyyy') {
    return dt ? DateTime.fromJSDate(dt).toFormat(FORMAT_MAPPING[dtFormat] || dtFormat) : '';
}

export function formatISODate(isoDt: string | null | undefined, dtFormat: LuxonFormatToken = 'dd/MM/yyyy') {
    return isoDt ? DateTime.fromISO(isoDt).toFormat(dtFormat) : '';
}

export function formatDateToRelative(dt: Date | null | undefined) {
    if (!dt) {
        return '';
    }

    const formattedDate = DateTime.fromJSDate(dt);
    const { months, years } = formattedDate.diffNow();

    if (months < 1) {
        return formattedDate.toRelative({ locale: 'en' });
    }

    if (years < 1) {
        return this.formatDate(dt, 'dd MMM');
    }

    return this.formatDate(dt);
}

export function numberToMonth(month: number, monthFormat: LuxonFormatToken = 'MMMM') {
    return DateTime.fromObject({ year: 2000, month: month + 1 }).toFormat(monthFormat);
}

export function formatCurrency(
    value: Decimal | bigint | number | null | undefined,
    options?: { maximumFractionDigits: number },
) {
    if (value === null || value === undefined) {
        return '-';
    }

    return Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        maximumFractionDigits: options?.maximumFractionDigits,
    }).format(Decimal.isDecimal(value) ? (value.toString() as any) : value);
}
