import { AsYouType, CountryCode } from 'libphonenumber-js';
import {
    formatCurrency as formatCurrencyOriginal,
    formatDate,
    formatDateTime,
    formatDateToRelative,
    formatISODate,
    formatISODateTime,
    formatISOTime,
    LUXON_FORMAT_TOKENS,
    numberToMonth,
} from 'shared/utils/formatting';

export function useFormatter(
    defaultOptions: Parameters<typeof formatCurrencyOriginal>[1] = { maximumFractionDigits: 2 },
) {
    //TODO: Later we'll load formatting configuration from the client settings

    return {
        fDateShortDayJs: 'DD/MM/YYYY',
        fDateShortLxn: 'dd/MM/yyyy',
        fDateTimeDayJs: 'DD/MM/YYYY h:mm A',
        fDateTimeLxn: 'dd/MM/yyyy HH:mm:ss',
        fTimeDayJs: 'h:mm A',
        fTimeLxn: 'HH:mm a',
        lxnDateTimeFormats: LUXON_FORMAT_TOKENS,

        formatCurrency(
            value: Parameters<typeof formatCurrencyOriginal>[0],
            options?: Parameters<typeof formatCurrencyOriginal>[1],
        ) {
            return formatCurrencyOriginal(value, {
                maximumFractionDigits:
                    options?.maximumFractionDigits !== undefined
                        ? options.maximumFractionDigits
                        : defaultOptions.maximumFractionDigits,
            });
        },

        formatDateTime,
        formatISODateTime,
        formatISOTime,
        formatDate,
        formatISODate,
        formatDateToRelative,
        numberToMonth,

        formatPhoneNumber(phoneNumber: string | null | undefined, defaultCountryCode?: CountryCode) {
            if (!phoneNumber) {
                return '';
            }

            const asYouType = new AsYouType(defaultCountryCode);
            return asYouType.input(phoneNumber);
        },
    };
}

export type Formatter = ReturnType<typeof useFormatter>;
