import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { errorMessage } from '@/components/Common/errorMessage';
import { Highlight } from '@/components/Common/Highlight';
import { Link } from '@/components/Common/Link';
import { ControlSelect } from '@/components/Control/ControlSelect';
import { CountrySelect } from '@/components/Obligation/CountrySelect';
import { LegislationSelect } from '@/components/Obligation/LegislationSelect';
import { SubObligationSelect } from '@/components/Obligation/SubObligationSelect';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useNavigate } from '@/hooks/useNavigate';
import { useUser } from '@/hooks/User/useUser';
import { useSafePath } from '@/hooks/useSafePath';
import { grey } from '@ant-design/colors';
import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
    App,
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { OBLIGATION_STATUSES } from 'shared/constants/obligation';
import { ObligationStatus } from 'shared/types/obligation';
import { Falsy, isTruthy } from 'shared/utils/boolean';
import { ComponentPreview } from '@/components/Common/ComponentPreview';
import { htmlToText } from '@/utils/text.ts';
import { NewTabLink } from '@/components/Common/NewTabLink';
import { commonActionColumn } from '@/utils/table';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { SortOrder } from '@/utils/general';

export const Route = createFileRoute('/console/$orgId/compliance/obligations/')({
    staticData: {
        ui: {
            title: 'Obligations',
            subtitle: 'Create, track, and manage obligations',
        },
    },
    component: ObligationSearch,
});

type Obligation = RouterOutputs['obligation']['listObligations']['rows'][number];

type Filter = {
    order: number;
    countries: string[];
    legislationIds: bigint[];
    sections: string[];
    controlIds: bigint[];
    status: string;
};

function ObligationSearch() {
    const { message } = App.useApp();
    const orgId = useOrgId();
    const user = useUser();
    const navigate = useNavigate();

    const deleteObligationMutation = trpc.obligation.deleteObligation.useMutation();
    const resetObligationToLibraryVersionMutation = trpc.obligation.resetObligationToLibraryVersion.useMutation();

    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState('');
    const [variables, setVariables] = useState<RouterInputs['obligation']['listObligations']>({
        where: undefined,
        orderBy: [{ order: SortOrder.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, isPending, refetch } = trpc.obligation.listObligations.useQuery(variables, {
        throwOnError: (error) => {
            errorMessage.show(error.message);
            return false;
        },
    });

    const [form] = Form.useForm<Filter>();
    const safePath = useSafePath<Filter>();

    const { tableProps } = useAntdTable<Obligation>({
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy?.length ? [...options.orderBy, { order: SortOrder.desc }] : variables.orderBy,
            });
        },
        rowKey: 'id',
        columns: [
            {
                title: 'ID',
                dataIndex: 'order',
                className: 'align-top',
                sorter: true,
            },
            {
                title: 'Identification code',
                dataIndex: 'identificationCode',
                className: 'align-top',
                sorter: true,
                render: (value, record) => {
                    const idPrefix = record?.Legislation?.idPrefix || '';
                    return `${idPrefix}${value || ''}`;
                },
            },
            {
                title: 'Legislation',
                dataIndex: 'Legislation',
                className: 'align-top',
                sorter: true,
                render: (value) => <Highlight data={value?.name} highlights={[search]} />,
            },
            {
                title: 'Sub obligation',
                dataIndex: 'section',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => (
                    <ComponentPreview>
                        <Highlight data={value} highlights={[search]} />
                    </ComponentPreview>
                ),
            },
            {
                title: 'Core obligation',
                dataIndex: 'coreObligation',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => (
                    <ComponentPreview>
                        <Highlight data={value} highlights={[search]} />
                    </ComponentPreview>
                ),
            },
            {
                title: 'What it means',
                dataIndex: 'whatItMeans',
                sorter: true,
                className: 'max-w-xs align-top',
                render: (value) => <ComponentPreview>{htmlToText(value)}</ComponentPreview>,
            },
            {
                title: 'Federal register of legislation URLs',
                dataIndex: 'federalRegisterOfLegislationURLs',
                className: 'max-w-xs align-top',
                render: (value?: { url: string; name?: string }[]) => (
                    <ComponentPreview noOfLines={3}>
                        {value?.map((link) => (
                            <a
                                key={Math.random().toString(16)}
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className="block"
                            >
                                {link.name || link.url}
                            </a>
                        ))}
                    </ComponentPreview>
                ),
            },
            {
                title: 'Regulator',
                dataIndex: 'regulator',
            },
            {
                title: 'Key controls',
                dataIndex: 'Controls',
                render: (_, { Controls }) => (
                    <Space size={[0, 8]} wrap>
                        {Controls?.map((item) => <Tag key={item.Control.id}>{item.Control.objective}</Tag>)}
                    </Space>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                render: (value: ObligationStatus) => OBLIGATION_STATUSES[value] || value,
            },
            {
                title: 'Applies to',
                render: (_, { HandlingParties }) =>
                    HandlingParties?.map((item) => <Tag key={item.HandlingParty.id}>{item.HandlingParty.name}</Tag>),
            },
            {
                ...commonActionColumn,
                render: (_, { libraryItemId, order, id }) => (
                    <Space>
                        {libraryItemId !== null && (
                            <Popconfirm
                                title={`Are you sure you want to reset obligation #${order} to library version?`}
                                onPopupClick={(e) => e.stopPropagation()}
                                onConfirm={async () => {
                                    try {
                                        await resetObligationToLibraryVersionMutation.mutateAsync({
                                            id,
                                        });

                                        message.success('Obligation reset to library version.');
                                        void refetch();
                                    } catch (e) {
                                        errorMessage.show(e);
                                    }
                                }}
                            >
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Popconfirm>
                        )}
                        <NewTabLink
                            to="/console/$orgId/compliance/obligations/details/$id/$section"
                            params={{
                                orgId,
                                id,
                                section: 'manage',
                            }}
                        />
                        {user?.isOrgAdmin && (
                            <Popconfirm
                                title={`Are you sure you want to delete obligation #${order}?`}
                                onPopupClick={(e) => e.stopPropagation()}
                                onConfirm={async () => {
                                    try {
                                        await deleteObligationMutation.mutateAsync({
                                            id: BigInt(id),
                                        });

                                        message.success('Obligation deleted.');
                                        void refetch();
                                    } catch (error) {
                                        errorMessage.show(error);
                                    }
                                }}
                            >
                                <Button
                                    size="small"
                                    type="text"
                                    icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Popconfirm>
                        )}
                    </Space>
                ),
            },
        ],
    });

    useEffect(() => {
        onSearch();
    }, [search]);

    const onSearch = () => {
        const item = form.getFieldsValue();

        const filters: (Falsy | RouterInputs['obligation']['listObligations']['where'])[] = [
            search && {
                OR: [
                    { section: { contains: search, mode: 'insensitive' } },
                    { coreObligation: { contains: search, mode: 'insensitive' } },
                    { whatItMeans: { contains: search, mode: 'insensitive' } },
                    {
                        Obligation_Control: {
                            some: {
                                Control: {
                                    OR: [
                                        {
                                            Owner: {
                                                OR: [
                                                    {
                                                        User: {
                                                            email: {
                                                                contains: search,
                                                                mode: 'insensitive',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        firstName: {
                                                            contains: search,
                                                            mode: 'insensitive',
                                                        },
                                                    },
                                                    {
                                                        lastName: {
                                                            contains: search,
                                                            mode: 'insensitive',
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            ControlTests: {
                                                some: {
                                                    Tester: {
                                                        OR: [
                                                            {
                                                                User: {
                                                                    email: {
                                                                        contains: search,
                                                                        mode: 'insensitive',
                                                                    },
                                                                },
                                                            },
                                                            {
                                                                firstName: {
                                                                    contains: search,
                                                                    mode: 'insensitive',
                                                                },
                                                            },
                                                            {
                                                                lastName: {
                                                                    contains: search,
                                                                    mode: 'insensitive',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                ],
            },
            item.controlIds?.length && {
                Obligation_Control: { some: { controlId: { in: item.controlIds } } },
            },
            item.legislationIds?.length && {
                legislationId: { in: item.legislationIds },
            },
            item.order && { order: item.order },
            item.countries?.length && { country: { in: item.countries } },
            item.sections?.length && { section: { in: item.sections } },
            item.status && { status: item.status },
        ];

        const where: RouterInputs['obligation']['listObligations']['where'] = {
            AND: filters.filter(isTruthy),
        };

        setVariables((prev) => ({ ...prev, where }));
    };

    return (
        <>
            <Space className="mb-4 flex items-center justify-between">
                <Input.Search
                    placeholder="Search"
                    onSearch={setSearch}
                    onClear={() => {
                        setSearch('');
                    }}
                    allowClear
                    enterButton="Search"
                    className="max-w-96"
                />
                <Space>
                    <Tooltip title="Filters">
                        <Button type="text" size="large" title="Filter" onClick={() => setShowFilter((prev) => !prev)}>
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Link
                        to="/console/$orgId/compliance/obligations/add"
                        params={{ orgId }}
                        icon={<PlusOutlined />}
                        type="primary"
                    >
                        New obligation
                    </Link>
                </Space>
            </Space>
            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={form} layout="vertical" onFinish={onSearch}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="ID" name={safePath('order')}>
                                    <InputNumber className="w-full" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Country" name={safePath('countries')}>
                                    <CountrySelect mode="multiple" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Legislation" name={safePath('legislationIds')}>
                                    <LegislationSelect mode="multiple" showSearch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Sub Obligation" name={safePath('sections')}>
                                    <SubObligationSelect mode="multiple" showSearch />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Key Controls" name={safePath('controlIds')}>
                                    <ControlSelect mode="multiple" showSearch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Status" name={safePath('status')}>
                                    <Select
                                        options={Object.entries(OBLIGATION_STATUSES).map(([key, value]) => ({
                                            label: value,
                                            value: key,
                                        }))}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Space className="flex justify-end">
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    onSearch();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Space>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) =>
                            navigate(
                                {
                                    to: '/console/$orgId/compliance/obligations/details/$id/$section',
                                    params: {
                                        orgId,
                                        id,
                                        section: 'manage',
                                    },
                                },
                                e,
                            ),
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
