import { OrgRestriction } from 'types/org';
import { UserRole } from '../constants/user';

interface HasuraClaims {
    'x-hasura-allowed-roles': string[];
    'x-hasura-auth0-user-id': string;
    'x-hasura-default-role': string;
    'x-hasura-external-org-ids': string;
    'x-hasura-hp-ids': string;
    'x-hasura-is-payment-admin': 'TRUE' | 'FALSE';
    'x-hasura-is-super-admin': 'TRUE' | 'FALSE';
    'x-hasura-is-support-account': 'TRUE' | 'FALSE';
    'x-hasura-is-support-account-approved': 'TRUE' | 'FALSE';
    'x-hasura-org-id': string;
    'x-hasura-roles': string;
    'x-hasura-roles-pa': string;
    'x-hasura-user-id': string;
}

interface CuriumClaims {
    auth0UserId: string;
    handlingParties: number[];
    isPaymentAdmin: boolean;
    isSuperAdmin: boolean;
    isSupportAccount: boolean;
    isSupportAccountApproved: boolean;
    orgId: number;
    roles: UserRole[];
    userId: number;
    email: string;
    isFirstSsoLogin: boolean;
    oid?: string;
    canViewUnallocatedClaims: boolean;
    isOrgEnabled: boolean;
    isOrgInitialized: boolean;
    maxAllowedUsers: number;
    orgEnabledModules: string[];
    firstName: string;
    lastName: string;
    orgRestrictions: OrgRestriction[];
}

export interface JwtClaims {
    'https://hasura.io/jwt/claims': HasuraClaims;
    'https://curium.app/claims': CuriumClaims;
    iss: string;
    sub: string;
    aud: string[];
    iat: number;
    exp: number;
    scope: string;
    azp: string;
}

export function decodeJwtToken(token: string | undefined) {
    if (!token) {
        return undefined;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((item) => '%' + ('00' + item.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload) as JwtClaims;
}

export function isTokenValid(decodedToken: JwtClaims, requiredRoles: Array<UserRole>, requiredOrgId: number) {
    return (
        decodedToken['https://curium.app/claims'].roles.some((r) => requiredRoles.includes(r)) &&
        decodedToken['https://curium.app/claims'].orgId === requiredOrgId
    );
}
