import { Tabs } from 'antd';
import { SupplierSearch } from '@/components/Supplier/SupplierSearch';
import { SupplierDefinitions } from '@/components/Supplier/SupplierDefinitions';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/suppliers/')({
    staticData: {
        ui: {
            title: 'Suppliers configuration',
        },
    },
    component: SuppliersConfiguration,
});

function SuppliersConfiguration() {
    return (
        <Tabs
            type="line"
            defaultActiveKey="list"
            items={[
                {
                    key: 'list',
                    label: 'Supplier list',
                    children: <SupplierSearch />,
                },
                {
                    key: 'definitions',
                    label: 'Definitions',
                    children: <SupplierDefinitions />,
                },
            ]}
        />
    );
}
