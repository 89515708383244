import { useTrpc } from '@/hooks/useTrpc';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { Alert, Card } from 'antd';
import { useEffect } from 'react';
import { z } from 'zod';
import { CreatingNewOrg } from './steps/CreatingNewOrg';
import { EmailVerificationSent } from './steps/EmailVerificationSent';
import { OrganizationNameForm } from './steps/OrganizationNameForm';
import { OrgCreated } from './steps/OrgCreated';
import { PasswordForm } from './steps/PasswordForm';
import { PaymentForm } from './steps/PaymentForm';
import { PaymentSuccessful } from './steps/PaymentSuccessful';
import { CreateNewAccount } from './steps/CreateNewAccount';
import { StartForm } from './steps/StartForm';
import { TopUpForm } from './steps/TopUpForm';
import { useStore } from './store';
import { EmailVerified } from './steps/EmailVerified';
import { ShareableRouteContext } from '@/types/router';
import { Loading } from './steps/Loading';

const SignUpSearchSchema = z.object({
    accessKey: z.string().optional(),
    paymentSuccess: z.string().optional(),
});

export const Route = createFileRoute('/sign-up')({
    component: SignUp,
    validateSearch: zodValidator(SignUpSearchSchema),
    async beforeLoad({ context: { ldClient } }): Promise<ShareableRouteContext> {
        const isSignUpPageEnabled = await ldClient.variation('is-sign-up-page-enabled', false);

        if (!isSignUpPageEnabled) {
            throw redirect({ to: '/not-found' });
        }
    },
});

function SignUp() {
    const { trpcClient } = useTrpc();
    const currentStep = useStore((state) => state.currentStep);
    const errorCode = useStore((state) => state.errorCode);
    const actions = useStore((state) => state.actions);
    const { accessKey, paymentSuccess } = Route.useSearch();

    useEffect(() => {
        const getEmail = async () => {
            try {
                if (!accessKey) {
                    actions.setCurrentStep('startForm');
                    return;
                }

                const data = await trpcClient.signUp.getSaveDataByAccessKey.query({
                    accessKey,
                });
                actions.setData(data);
                actions.setAccessKey(accessKey);

                if (paymentSuccess) {
                    actions.setPaymentStatus('success');
                    actions.setCurrentStep('paymentSuccessful');
                } else {
                    actions.setCurrentStep('emailVerified');
                }
            } catch {
                actions.setError({
                    errorCode: 'unknown',
                    errorMessage: 'An unknown error occurred. Please try again later.',
                });
            }
        };
        getEmail();
    }, [accessKey]);

    const renderStep = () => {
        switch (currentStep) {
            case 'startForm':
                return <StartForm />;
            case 'passwordForm':
                return <PasswordForm />;
            case 'organizationNameForm':
                return <OrganizationNameForm />;
            case 'emailVerificationSent':
                return <EmailVerificationSent />;
            case 'createNewAccount':
                return <CreateNewAccount />;
            case 'creatingNewOrg':
                return <CreatingNewOrg />;
            case 'organizationCreated':
                return <OrgCreated />;
            case 'topUpForm':
                return <TopUpForm />;
            case 'paymentForm':
                return <PaymentForm />;
            case 'paymentSuccessful':
                return <PaymentSuccessful />;
            case 'emailVerified':
                return <EmailVerified />;
            case 'loading':
                return <Loading />;
            default:
                return null;
        }
    };

    return (
        <>
            {currentStep === 'paymentForm' && (
                <div className="min-h-screen w-full bg-gray-50">
                    <div className="flex min-h-screen items-center justify-center">
                        <div className="mx-auto w-full max-w-3xl px-4">
                            {errorCode === 'unknown' && (
                                <Alert message="An unknown error occurred. Please try again later." type="error" />
                            )}
                            <div className="flex justify-center p-2">{renderStep()}</div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep !== 'paymentForm' && (
                <div className="min-h-screen w-full bg-gray-50">
                    <div className="flex min-h-screen items-center justify-center">
                        <div className="w-full max-w-sm">
                            {errorCode === 'unknown' && (
                                <Alert message="An unknown error occurred. Please try again later." type="error" />
                            )}
                            <Card className="mx-4 my-8">{renderStep()}</Card>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
