import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterInputs, trpc } from '@/trpc';
import { sanitizeHtml } from '@/utils/text';
import { createFileRoute } from '@tanstack/react-router';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Form, Input, message, Select, Space, Switch, Tag, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { CONFIDENCE_MAP, ISSUE_TYPE_MAP } from '../config';

export const Route = createFileRoute('/console/$orgId/tools/ai-inbox/inbox/$id/review-email-classification')({
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ReviewEmailClassification,
});

type FormValues = RouterInputs['emailClassification']['reviewEmailClassification'];

const FormItem = Form.Item<FormValues>;

type EmailAddress = { name?: string; email: string };

const formatEmailAddress = (email: EmailAddress) => {
    if (email.name) {
        return `${email.name} <${email.email}>`;
    }
    return email.email;
};

const formatEmailAddresses = (emails: EmailAddress | EmailAddress[] | null | undefined) => {
    if (!emails) return '';
    const emailArray = Array.isArray(emails) ? emails : [emails];
    return emailArray.map(formatEmailAddress).join(', ');
};

function ReviewEmailClassification() {
    const { id } = Route.useParams();
    const orgId = useOrgId();
    const navigate = useNavigate();
    const [form] = Form.useForm<FormValues>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { trpcClient, trpcUtils } = useTrpc();
    const [open, setOpen] = useState(true);
    const [isEmailDetailsOpen, setIsEmailDetailsOpen] = useState(false);

    const { data } = trpc.emailClassification.getEmailClassification.useQuery(
        { id },
        {
            throwOnError(error) {
                message.error(error.message);
                return false;
            },
        },
    );

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                issueType: data?.issueType || 'UNKNOWN',
                confidence: data?.confidence || 'LOW',
                isBreachOfRegulation: data?.isBreachOfRegulation || false,
                breachedRegulation: data?.breachedRegulation || '',
                justification: data?.justification || '',
            });
        }
    }, [data]);

    const handleSubmit = async (values: FormValues) => {
        if (!data) return;

        try {
            setIsSubmitting(true);
            message.loading('Reviewing email classification...');
            await trpcClient.emailClassification.reviewEmailClassification.mutate({
                id: Number(data.id),
                issueType: values.issueType,
                confidence: values.confidence,
                isBreachOfRegulation: values.isBreachOfRegulation,
                breachedRegulation: values.breachedRegulation,
                justification: values.justification,
            });
            message.destroy();
            message.success('Email classification reviewed successfully');
            trpcUtils.emailClassification.invalidate();
            setOpen(false);
        } catch (error) {
            message.destroy();
            errorMessage.show(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Drawer
            title="Review e-mail"
            open={open}
            onClose={() => setOpen(false)}
            afterOpenChange={(open) => {
                if (!open) {
                    navigate({
                        to: '/console/$orgId/tools/ai-inbox/inbox',
                        params: { orgId },
                    });
                }
            }}
            width={1000}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="primary"
                        loading={isSubmitting}
                        htmlType="submit"
                        form={`review-email-classification-${id}`}
                    >
                        Submit Review
                    </Button>
                </Space>
            }
            classNames={{ body: 'bg-body' }}
        >
            {data && (
                <>
                    <Card
                        title={
                            <div
                                className="flex cursor-pointer items-center justify-between"
                                onClick={() => setIsEmailDetailsOpen(!isEmailDetailsOpen)}
                            >
                                <span>Email Details</span>
                                <Button
                                    type="text"
                                    icon={isEmailDetailsOpen ? <UpOutlined /> : <DownOutlined />}
                                    className="ml-2"
                                />
                            </div>
                        }
                        className="mb-6"
                        classNames={{ body: 'p-0', header: 'cursor-pointer' }}
                    >
                        <CollapsiblePanel open={isEmailDetailsOpen}>
                            <div className="px-8 py-6">
                                <div className="border-b pb-2">
                                    {data.subject && <div className="mb-3 text-lg font-medium">{data.subject}</div>}
                                    <div className="space-y-1 text-sm">
                                        {data.from && (
                                            <div className="grid grid-cols-[60px,1fr] gap-2">
                                                <span className="text-gray-500">From:</span>
                                                <span>{formatEmailAddresses(data.from)}</span>
                                            </div>
                                        )}
                                        {data.to && (
                                            <div className="grid grid-cols-[60px,1fr] gap-2">
                                                <span className="text-gray-500">To:</span>
                                                <span>{formatEmailAddresses(data.to)}</span>
                                            </div>
                                        )}
                                        {data.cc && formatEmailAddresses(data.cc) && (
                                            <div className="grid grid-cols-[60px,1fr] gap-2">
                                                <span className="text-gray-500">Cc:</span>
                                                <span>{formatEmailAddresses(data.cc)}</span>
                                            </div>
                                        )}
                                        {data.bcc && formatEmailAddresses(data.bcc) && (
                                            <div className="grid grid-cols-[60px,1fr] gap-2">
                                                <span className="text-gray-500">Bcc:</span>
                                                <span>{formatEmailAddresses(data.bcc)}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Divider className="my-4" />

                                <div>
                                    <div
                                        className="prose max-w-none"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(data.bodyHtml) || data.bodyText,
                                        }}
                                    />
                                </div>
                            </div>
                        </CollapsiblePanel>
                    </Card>

                    <Card title="Review Classification">
                        <Form<FormValues>
                            form={form}
                            id={`review-email-classification-${id}`}
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem
                                        name="issueType"
                                        label="Issue Type"
                                        rules={[{ required: true, message: 'Please select an issue type' }]}
                                    >
                                        <Select
                                            size="large"
                                            options={Object.entries(ISSUE_TYPE_MAP).map(
                                                ([value, { label, color }]) => ({
                                                    label: (
                                                        <Tag color={color} className="px-[8px] py-[2px] text-base">
                                                            {label}
                                                        </Tag>
                                                    ),
                                                    value,
                                                }),
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        name="confidence"
                                        label="Certainty"
                                        rules={[{ required: true, message: 'Please enter a certainty' }]}
                                    >
                                        <Select
                                            size="large"
                                            options={Object.entries(CONFIDENCE_MAP).map(
                                                ([value, { label, color }]) => ({
                                                    label: (
                                                        <Tag color={color} className="px-[8px] py-[2px] text-base">
                                                            {label}
                                                        </Tag>
                                                    ),
                                                    value,
                                                }),
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <FormItem
                                name="isBreachOfRegulation"
                                label="Is Breach of Regulation?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </FormItem>

                            <FormItem name="breachedRegulation" label="Breached Regulation">
                                <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
                            </FormItem>

                            <FormItem name="justification" label="Justification">
                                <Input.TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
                            </FormItem>
                        </Form>
                    </Card>
                </>
            )}
        </Drawer>
    );
}
