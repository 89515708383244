import { Button, ButtonProps } from 'antd';
import { createLink, LinkComponent } from '@tanstack/react-router';
import { FC, forwardRef } from 'react';
import clsx from 'clsx';
import { PlusOutlined } from '@ant-design/icons';

export type LinkProps = Omit<ButtonProps, 'href'> & { innerType?: ButtonProps['type'] };

const AntdLinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(({ innerType, className, ...props }, ref) => (
    <Button
        ref={ref}
        type={innerType}
        className={clsx(innerType === 'link' && 'h-auto border-none p-0', className)}
        {...props}
    />
));

const CreatedLinkComponent = createLink(AntdLinkComponent);

export type LinkFC<P extends Record<string, any> = LinkProps> = LinkComponent<FC<P>>;

const Add: LinkFC<Pick<LinkProps, 'type'> & { label: `New ${string}` }> = ({
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    ...props
}) => {
    return (
        <Link icon={<PlusOutlined />} {...(props as any)}>
            {label}
        </Link>
    );
};

const Link: LinkFC & { Add: typeof Add } = ({ preload, type, innerType, ...props }) => {
    return <CreatedLinkComponent preload={preload || 'intent'} innerType={innerType || type} {...(props as any)} />;
};

Link.Add = Add;

export { Link };
