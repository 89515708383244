import { useState } from 'react';
import { Button, Card, Table } from 'antd';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { PlusOutlined } from '@ant-design/icons';
import { PdsAction, PdsByProductTypeEdit } from './PdsByProductTypeEdit';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';
import { RouterOutputs, trpc } from '@/trpc';
import { useZodState } from '@/hooks/useZodState';
import { listPdsInputSchema } from 'server/schemas/pds';
import { errorMessage } from '@/components/Common/errorMessage';

export const Route = createFileRoute('/console/$orgId/configuration/product-types/$productTypeId/')({
    staticData: {
        ui: {
            title: 'PDS configuration',
        },
    },
    component: ConfigurationPdsByProductType,
});

type Pds = RouterOutputs['pds']['listPds']['rows'][number];

function ConfigurationPdsByProductType() {
    const orgId = useOrgId();
    const { productTypeId } = Route.useParams();
    const [action, setAction] = useState<PdsAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listPdsInputSchema);

    const { data, isLoading, refetch } = trpc.pds.listPds.useQuery(
        {
            ...queryParams,
            where: {
                ...queryParams.where,
                productTypeId,
            },
        },
        {
            enabled: !!productTypeId,
            throwOnError(error) {
                errorMessage.show(error);
                return false;
            },
        },
    );

    const { tableProps } = useAntdTable<Pds>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Insurer',
                dataIndex: 'Insurer.name',
            },
            {
                title: 'Product type',
                dataIndex: 'ProductType.name',
            },
            {
                ...commonActionColumn,
                render: (_, { id, productTypeId }) => (
                    <Link
                        to="/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId"
                        params={{ orgId, productTypeId, pdsId: id }}
                        type="link"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Manage PDS versions
                    </Link>
                ),
            },
        ],
    });

    return (
        <>
            <PdsByProductTypeEdit
                action={action}
                productTypeId={productTypeId}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    New PDS
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick() {
                            setAction(['edit', id]);
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
