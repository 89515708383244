import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useIsDashboardsEnabled } from './hooks';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { DashboardSection } from 'shared/navigation/navRoutes';

export const Route = createFileRoute('/console/$orgId/dashboard/')({
    component: DashboardHome,
});

function DashboardHome() {
    const orgId = useOrgId();
    const isDashboardsEnabled = useIsDashboardsEnabled();

    const section = Object.entries(isDashboardsEnabled).find(([, value]) => value)?.[0] as DashboardSection | undefined;

    return section ? (
        <Navigate
            to="/console/$orgId/dashboard/$section"
            params={{
                orgId,
                section,
            }}
        />
    ) : (
        <Navigate
            to="/console/$orgId"
            params={{
                orgId,
            }}
        />
    );
}
