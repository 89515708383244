import { useState } from 'react';
import { Button, Card, Table } from 'antd';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { PlusOutlined } from '@ant-design/icons';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { PdsVersionByPdsEdit, PdsVersionAction } from './PdsVersionByPdsEdit';
import { useQuery } from '@apollo/client';
import { PdsById } from '@/graphql/queries/pds';
import { commonActionColumn } from '@/utils/table';
import { Link } from '@/components/Common/Link';
import { createFileRoute } from '@tanstack/react-router';
import { errorMessage } from '@/components/Common/errorMessage';
import { RouterOutputs, trpc } from '@/trpc';
import { useZodState } from '@/hooks/useZodState';
import { listPdsVersionInputSchema } from 'server/schemas/pdsVersion';
import { ShareableRouteContext } from '@/types/router';
export const Route = createFileRoute('/console/$orgId/configuration/product-types/$productTypeId/pds/$pdsId')({
    staticData: {
        ui: {
            title: 'PDS version configuration',
        },
    },
    params: {
        parse({ pdsId }) {
            return {
                pdsId: BigInt(pdsId),
            };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.pds.getPds.ensureData({
            id: params.pdsId,
        });

        return {
            breadcrumb: {
                async title() {
                    const pds = await trpcUtils.pds.getPds.ensureData({
                        id: params.pdsId,
                    });

                    return pds.name;
                },
            },
        };
    },
    component: ConfigurationPdsVersionsByPds,
});

type PdsVersion = RouterOutputs['pdsVersion']['listPdsVersion']['rows'][number];

function ConfigurationPdsVersionsByPds() {
    const orgId = useOrgId();
    const { pdsId, productTypeId } = Route.useParams();
    const { formatDate } = useFormatter();
    const [action, setAction] = useState<PdsVersionAction>(['none']);
    const [queryParams, setQueryParams] = useZodState(listPdsVersionInputSchema);
    const { data, isPending, refetch } = trpc.pdsVersion.listPdsVersion.useQuery(
        {
            ...queryParams,
            where: {
                ...queryParams.where,
                pdsId,
            },
        },
        {
            enabled: !!pdsId,
            throwOnError(error) {
                errorMessage.show(error);
                return false;
            },
        },
    );
    const { data: pds } = useQuery(PdsById, {
        variables: {
            id: pdsId,
            orgId,
        },
    });

    const { tableProps } = useAntdTable<PdsVersion>({
        rowKey: 'id',
        data: {
            rows: data?.rows,
            loading: isPending,
            total: data?.total,
        },
        onQueryVariableChange: (options) => {
            setQueryParams((prev) => ({
                ...prev,
                ...options,
            }));
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                render: String,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
            },
            {
                title: 'Start date',
                dataIndex: 'startDate',
                render: (value) => formatDate(value),
            },
            {
                title: 'End date',
                dataIndex: 'endDate',
                render: (value) => formatDate(value),
            },
            {
                title: 'Questionnaire',
                dataIndex: 'Questionnaire.name',
            },
            {
                title: 'Workflow',
                dataIndex: 'Workflow.name',
            },
            {
                ...commonActionColumn,
                render: (_, { id, questionnaireId }) =>
                    !!questionnaireId && (
                        <Link
                            to="/public/claim-lodgement/$orgId/$pdsVersionId/$questionnaireId/$productTypeId"
                            params={{
                                orgId: String(orgId),
                                pdsVersionId: String(id),
                                questionnaireId: String(questionnaireId),
                                productTypeId: String(productTypeId),
                            }}
                            search={{
                                data: window.btoa(
                                    JSON.stringify({
                                        insurerName: pds?.PdsByPk?.Insurer?.name,
                                    }),
                                ),
                            }}
                            target="_blank"
                            type="dashed"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Public lodgement
                        </Link>
                    ),
            },
        ],
    });

    return (
        <>
            <PdsVersionByPdsEdit
                pdsId={pdsId}
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <div className="mb-4 flex justify-end">
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    New PDS version
                </Button>
            </div>

            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    size="small"
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick() {
                            setAction(['edit', id]);
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}
