import { useTrpc } from '@/hooks/useTrpc';
import { Button, message } from 'antd';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/admin-playground')({
    component: AdminPlayground,
    staticData: {
        ui: {
            title: 'Admin Playground',
            subtitle: 'Page for running various admin test',
        },
        breadcrumb: {
            title: 'Admin Playground',
        },
        privilegedSuperAdminOnly: true,
    },
});

function AdminPlayground() {
    const { trpc } = useTrpc();
    const { mutateAsync: indicatorNotificationTest } = trpc.admin.playground.indicatorNotificationTest.useMutation();
    const { mutateAsync: stripeTest } = trpc.admin.playground.stripeTest.useMutation();
    const handleIndicatorNotificationTest = async () => {
        try {
            await indicatorNotificationTest();
        } catch (error) {
            console.error(error);
            message.error('Failed to test indicator notification');
        }
    };

    const handleStripeTest = async () => {
        try {
            const result = await stripeTest();
            console.log(result);
        } catch (error) {
            console.error(error);
            message.error('Failed to test stripe');
        }
    };
    return (
        <div>
            <Button onClick={handleIndicatorNotificationTest}>Test indicator notification</Button>
            <Button onClick={handleStripeTest}>Test stripe</Button>
        </div>
    );
}
