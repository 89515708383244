import { Link } from '@/components/Common/Link';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useGenerateNavigationUIItems } from '@/hooks/useGenerateNavigationUIItems';
import { useUser } from '@/hooks/User/useUser';
import { NavigationItem } from '@/utils/navigation';
import { AlertOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Card, Space, Typography } from 'antd';

const navigationItems: NavigationItem[] = [
    {
        icon: AlertOutlined,
        title: 'AI Inbox',
        to: '/console/$orgId/tools/ai-inbox',
    },
];

export const Route = createFileRoute('/console/$orgId/tools-only-home-page')({
    component: ToolsOnlyHomePage,
});

function ToolsOnlyHomePage() {
    const user = useUser();
    const orgId = useOrgId();
    const claimNavItems = useGenerateNavigationUIItems(navigationItems);

    return (
        <div className="p-8">
            <Card className="mb-8 text-center">
                <Typography.Title level={2} className="mb-4">
                    Welcome, {user.firstName || user.name}!
                </Typography.Title>
                <Typography.Text className="text-lg">What would you like to do today?</Typography.Text>
            </Card>

            {!!claimNavItems.length && (
                <Card title="Tools" className="mb-8">
                    <Space size="large" align="center" className="w-full justify-center">
                        {claimNavItems.map(({ to, icon, label }) => (
                            <Link
                                key={to}
                                to={to}
                                params={{
                                    orgId,
                                }}
                                type="default"
                                size="large"
                                icon={icon}
                            >
                                {label}
                            </Link>
                        ))}
                    </Space>
                </Card>
            )}
        </div>
    );
}
