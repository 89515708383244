import { Tabs } from '@/components/Navigation/Tabs';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { ShareableRouteContext } from '@/types/router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/tools/ai-inbox')({
    staticData: {
        breadcrumb: {
            title: 'Compliance AI Inbox',
        },
        ui: {
            title: 'Compliance AI Inbox',
            subtitle: 'Compliance AI Inbox',
        },
    },
    beforeLoad(): ShareableRouteContext {
        return {
            ui: {
                title: 'Compliance AI Inbox',
            },
        };
    },
    component: ComplianceAiInbox,
});

function ComplianceAiInbox() {
    const navigate = useNavigate();
    const orgId = useOrgId();

    return (
        <Tabs
            defaultNavigate={() =>
                navigate({
                    to: '/console/$orgId/tools/ai-inbox/inbox',
                    params: {
                        orgId,
                    },
                })
            }
            items={[
                {
                    label: 'Inbox',
                    to: '/console/$orgId/tools/ai-inbox/inbox',
                },
                {
                    label: 'Dashboard',
                    to: '/console/$orgId/tools/ai-inbox/dashboard',
                },
            ]}
        />
    );
}
