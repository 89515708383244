import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useFormatter } from '@/hooks/useFormatter';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { Badge, Button, Empty, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { ScanWebsiteAction, ScanWebsiteDrawer } from './ScanWebsiteDrawer';
import { ViewScrapedDataAction, ViewScrapedDataDrawer } from './ViewScrapedDataDrawer';
import { createFileRoute } from '@tanstack/react-router';
import { ReloadOutlined } from '@ant-design/icons';

export const Route = createFileRoute('/console/$orgId/tools/website-checker')({
    staticData: {
        breadcrumb: {
            title: 'Website checker',
        },
        ui: {
            title: 'Website checker',
        },
        featureFlag: 'complianceWebsiteChecker',
    },
    component: ComplianceWebsiteChecker,
});

type QueryVariables = RouterInputs['scraper']['listScrapedData'];

type ScrapedData = RouterOutputs['scraper']['listScrapedData']['rows'][number];

function getStatusColor(status: string) {
    switch (status.toLowerCase()) {
        case 'success':
            return 'success';
        case 'downloading':
        case 'analyzing':
            return 'processing';
        case 'error':
            return 'error';
        default:
            return 'default';
    }
}

function getTimeSinceLastScan(date: Date) {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (days > 0) {
        return `${days}d ${hours}h ago`;
    }
    return `${hours}h ago`;
}

function ComplianceWebsiteChecker() {
    const { formatDateTime } = useFormatter();
    const [scanDrawerAction, setScanDrawerAction] = useState<ScanWebsiteAction>(['none']);
    const [viewDrawerAction, setViewDrawerAction] = useState<ViewScrapedDataAction>(['none']);

    const [variables, setVariables] = useState<QueryVariables>({
        limit: 10,
        offset: 0,
    });

    const { data, isPending, refetch } = trpc.scraper.listScrapedData.useQuery(variables);

    const { tableProps } = useAntdTable<ScrapedData>({
        data: {
            rows: data?.rows || [],
            loading: isPending,
            total: data?.total,
        },
        rowKey: 'id',
        columns: [
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
                render: (url: string) => (
                    <Typography.Text ellipsis style={{ maxWidth: 300 }}>
                        <Tooltip title={url}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                {url}
                            </a>
                        </Tooltip>
                    </Typography.Text>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status: string) => {
                    const color = getStatusColor(status);
                    const displayText = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
                    return <Badge status={color} text={displayText} />;
                },
            },
            {
                title: 'Pages Scanned',
                dataIndex: 'pagesScraped',
                render: (data) => <Tag>{data?.toString()}</Tag>,
            },
            {
                title: 'Last Scan',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: 200,
                render: (date: Date) => <Tooltip title={formatDateTime(date)}>{getTimeSinceLastScan(date)}</Tooltip>,
            },
            {
                title: 'Error',
                dataIndex: 'error',
                key: 'error',
                width: 200,
                render: (error: string) =>
                    error ? (
                        <Tooltip title={error}>
                            <Typography.Text type="danger" ellipsis style={{ maxWidth: 200 }}>
                                {error}
                            </Typography.Text>
                        </Tooltip>
                    ) : null,
            },
            {
                title: 'Actions',
                key: 'actions',
                width: 200,
                render: (_, record) => (
                    <Space>
                        <Button
                            type="link"
                            onClick={() => {
                                setViewDrawerAction(['view', record.id]);
                            }}
                        >
                            View
                        </Button>
                        <Button type="link" onClick={() => setScanDrawerAction(['scan-existing', record.url])}>
                            Re-scan
                        </Button>
                    </Space>
                ),
            },
        ],
        paginationConfig: {
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total: number) => `Total ${total} items`,
        },
        onQueryVariableChange(options) {
            setVariables({
                ...variables,
                limit: options?.limit ?? variables.limit,
                offset: options?.offset ?? variables.offset,
                orderBy: options?.orderBy?.length ? options?.orderBy : variables.orderBy,
            });
        },
    });

    return (
        <>
            <Space className="mb-6 flex justify-end">
                <Button onClick={() => refetch()} icon={<ReloadOutlined />}>
                    Refresh
                </Button>
                <Button type="primary" onClick={() => setScanDrawerAction(['scan-new'])}>
                    Scan New Website
                </Button>
            </Space>
            <Table
                {...tableProps}
                scroll={{ x: 'max-content' }}
                className="rounded-lg shadow-sm"
                locale={{
                    emptyText: (
                        <Empty
                            className="flex h-[450px] flex-col justify-center"
                            description="No websites to display."
                        />
                    ),
                }}
            />

            <ScanWebsiteDrawer
                action={scanDrawerAction}
                onClose={() => {
                    setScanDrawerAction(['none']);
                    refetch();
                }}
            />

            <ViewScrapedDataDrawer action={viewDrawerAction} onClose={() => setViewDrawerAction(['none'])} />
        </>
    );
}
