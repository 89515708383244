import { errorMessage } from '@/components/Common/errorMessage';
import { StripePayment } from '@/components/Payment/StripePayment';
import { useTrpc } from '@/hooks/useTrpc';
import { createFileRoute } from '@tanstack/react-router';
import { Alert, Button, Card, Input, message, Modal, notification, Space, Statistic, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { zodValidator } from '@tanstack/zod-adapter';

const PaymentSuccessParamSchema = z.object({
    paymentSuccess: z.string().optional(),
});

export const Route = createFileRoute('/console/$orgId/configuration/billing/balance')({
    component: Balance,
    validateSearch: zodValidator(PaymentSuccessParamSchema),
});

export function Balance() {
    const { paymentSuccess } = Route.useSearch();
    const { trpc } = useTrpc();
    const [amount, setAmount] = useState<number>(0);
    const [paymentClientSecret, setPaymentClientSecret] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const utils = trpc.useUtils();

    useEffect(() => {
        if (paymentSuccess) {
            message.success('Payment successful');
            void utils.billing.getWalletBalance.invalidate();
        }
    }, [paymentSuccess]);

    const { data: balance = 0, isLoading: isBalanceLoading } = trpc.billing.getWalletBalance.useQuery(undefined, {
        throwOnError(error) {
            errorMessage.show(error);
            return false;
        },
    });
    const { data: billingInfo } = trpc.billing.getBillingInfo.useQuery();
    const createPaymentIntentMutation = trpc.billing.createPaymentIntent.useMutation();

    const handleAddFunds = async () => {
        if (amount < 10) {
            errorMessage.show('Minimum top-up amount is $10');
            return;
        }

        if (!billingInfo) {
            notification.error({
                message: 'Please complete billing information first.',
            });
            return;
        }

        try {
            setLoading(true);
            const result = await createPaymentIntentMutation.mutateAsync({
                amount: Math.round(amount * 100), // Convert to cents
            });
            setPaymentClientSecret(result.clientSecret);
        } catch (error) {
            errorMessage.show(error);
        } finally {
            setLoading(false);
        }
    };

    const calculateGST = (baseAmount: number) => baseAmount * 0.1;
    const calculateTotal = (baseAmount: number) => baseAmount + calculateGST(baseAmount);

    return (
        <>
            <div className="flex justify-center">
                <Card title="Wallet Balance" loading={isBalanceLoading} className="w-full max-w-[600px]">
                    <Space direction="vertical" size="large" className="w-full">
                        <div>
                            <Alert
                                message="About Your Wallet"
                                description="Your wallet balance is used to pay for services and features within our platform. Funds can be added at any time and are immediately available for use."
                                type="info"
                                showIcon
                                className="mb-4"
                            />
                            <Statistic
                                title={
                                    <Typography.Text strong>
                                        Current Balance
                                        <Typography.Text type="secondary" className="ml-2">
                                            (Available for immediate use)
                                        </Typography.Text>
                                    </Typography.Text>
                                }
                                value={Number(balance)}
                                precision={2}
                                prefix="$"
                            />
                        </div>
                        <div className="border-t pt-4">
                            <Typography.Title level={5}>Add Funds to Your Wallet</Typography.Title>
                            <Typography.Paragraph type="secondary" className="mb-4">
                                Top up your wallet balance to ensure uninterrupted access to our services. We accept all
                                major credit cards through our secure payment processor, Stripe. Minimum deposit amount
                                is $10 (excluding GST).
                            </Typography.Paragraph>
                            <Space direction="vertical" size="middle">
                                <Space>
                                    <Input
                                        type="number"
                                        prefix="$"
                                        min={10}
                                        value={amount}
                                        onChange={(e) => setAmount(Number(e.target.value))}
                                        style={{ width: 200 }}
                                        placeholder="Enter amount"
                                    />
                                    <Button
                                        type="primary"
                                        onClick={handleAddFunds}
                                        disabled={amount < 10}
                                        loading={loading}
                                    >
                                        Add Funds
                                    </Button>
                                </Space>
                                {amount > 0 && (
                                    <Card size="small" className="w-[300px] bg-gray-50">
                                        <Space direction="vertical" className="w-full">
                                            <div className="flex justify-between">
                                                <Typography.Text>Base amount:</Typography.Text>
                                                <Typography.Text>${amount.toFixed(2)}</Typography.Text>
                                            </div>
                                            <div className="flex justify-between">
                                                <Typography.Text>GST (10%):</Typography.Text>
                                                <Typography.Text>${calculateGST(amount).toFixed(2)}</Typography.Text>
                                            </div>
                                            <div className="mt-2 flex justify-between border-t pt-2">
                                                <Typography.Text strong>Total amount:</Typography.Text>
                                                <Typography.Text strong>
                                                    ${calculateTotal(amount).toFixed(2)}
                                                </Typography.Text>
                                            </div>
                                        </Space>
                                    </Card>
                                )}
                                {amount > 0 && amount < 10 && (
                                    <Typography.Text type="danger">
                                        Minimum deposit amount is $10 (excluding GST)
                                    </Typography.Text>
                                )}
                            </Space>
                        </div>
                    </Space>
                </Card>
            </div>

            <Modal
                title="Add Funds to Wallet"
                open={!!paymentClientSecret}
                onCancel={() => setPaymentClientSecret(null)}
                footer={null}
                width="fit-content"
            >
                {paymentClientSecret && (
                    <div>
                        <Typography.Paragraph className="mb-4">
                            Please enter your payment details below. Your card will be charged $
                            {calculateTotal(amount).toFixed(2)} (including GST).
                        </Typography.Paragraph>
                        <StripePayment clientSecret={paymentClientSecret} />
                    </div>
                )}
            </Modal>
        </>
    );
}
