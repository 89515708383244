import { AppProvider } from '@/components/Providers/AppProvider';
import { Auth0Provider } from '@/components/Providers/Auth0Provider';
import { EventEmitterProvider } from '@/components/Providers/EventEmitterProvider';
import { LaunchDarklyClientSetup } from '@/components/Providers/LaunchDarklyProvider';
import { TrpcProvider } from '@/components/Providers/TrpcProvider';
import { UIProvider } from '@/components/Providers/UIProvider';
import { ApolloSetup } from '@/graphql';
import { RouteContext } from '@/types/router';
import { createRootRouteWithContext, stripSearchParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

const DEFAULT_VALUES = {
    search: '',
};

export const Route = createRootRouteWithContext<RouteContext>()({
    validateSearch: zodValidator(
        z.object({
            search: z.string().default(DEFAULT_VALUES.search),
        }),
    ),
    search: {
        middlewares: [stripSearchParams(DEFAULT_VALUES)],
    },
    component: Root,
});

function Root() {
    return (
        <LaunchDarklyClientSetup>
            <ApolloSetup>
                <Auth0Provider>
                    <TrpcProvider>
                        <EventEmitterProvider>
                            <UIProvider>
                                <AppProvider />
                            </UIProvider>
                        </EventEmitterProvider>
                    </TrpcProvider>
                </Auth0Provider>
            </ApolloSetup>
        </LaunchDarklyClientSetup>
    );
}
