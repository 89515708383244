import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { errorMessage } from '@/components/Common/errorMessage';
import { useUserEvent } from '@/hooks/Logging/useUserEvent';
import { useNavigate } from '@/hooks/useNavigate';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useAuthStore } from '@/stores/AuthStore';
import { logInDev } from '@/utils/general';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { App, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { SYSTEM_ORG_ID } from 'shared/constants/org';
import { decodeJwtToken } from 'shared/utils/jwt';
import invariant from 'tiny-invariant';

export const Route = createFileRoute('/(authentication)/callback')({
    component: AuthCallback,
});

export function AuthCallback() {
    const { message } = App.useApp();
    const queryClient = useQueryClient();
    const trpcClient = useTrpcClient();
    const authStore = useAuthStore();
    const authInProgressRef = useRef(false);
    const { insertEvent } = useUserEvent();
    const { handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const asyncWrapper = async () => {
            Sentry.getCurrentScope().setTransactionName('Login Callback');
            queryClient.clear();

            logInDev('AuthCallback useEffect');

            if (authInProgressRef.current && !authStore.isLoginInProgress) {
                return;
            }

            try {
                authInProgressRef.current = true;

                logInDev('logging in from /callback');

                const searchParams = new URLSearchParams(window.location.search);
                const error = searchParams.get('error');

                if (error) {
                    if (error === 'login_required') {
                        await errorMessage.showAsync(new Error('Login required'));
                    } else if (searchParams.get('error_description') === 'payment_required_for_login') {
                        navigate({ to: '/payment-pending' });
                        return;
                    } else if (error === 'access_denied') {
                        await errorMessage.showAsync(searchParams.get('error_description'), {
                            title: 'Access Denied',
                        });
                    }
                    console.error('AuthCallback: error', error);
                    authStore.logout();
                    return;
                }

                const appStateWrapper = (await handleRedirectCallback()) as {
                    appState?: { redirectTo?: string; requestOrgId?: string };
                };

                logInDev('AuthCallback: appStateWrapper', appStateWrapper);

                const redirectTo = decodeURIComponent(appStateWrapper?.appState?.redirectTo || '');
                const orgId = appStateWrapper?.appState?.requestOrgId;

                logInDev('AuthCallback: orgId', orgId);

                let accessToken = await authStore.getAccessToken(orgId ? BigInt(orgId) : undefined, true);

                const tokenDecoded = decodeJwtToken(accessToken || '');
                const isFirstSsoLogin = tokenDecoded?.['https://curium.app/claims'].isFirstSsoLogin;

                if (isFirstSsoLogin) {
                    try {
                        await trpcClient.user.initializeSsoUser.mutate({
                            accessToken,
                        });
                        // Get new access token, it should have the updated user metadata
                        accessToken = await authStore.getAccessToken(orgId ? BigInt(orgId) : undefined, true);
                    } catch (e) {
                        await errorMessage.showAsync(e);
                        navigate({ to: '/' });
                        return;
                    }
                }

                await authStore.login(accessToken);

                logInDev('AuthCallback: login complete');

                await insertEvent('login', 'User logged in successfully');

                const org = useAuthStore.getState().org;
                invariant(org, 'org is required');

                if (redirectTo && !redirectTo.includes('/login')) {
                    navigate({ href: redirectTo });
                } else {
                    if (org.id === SYSTEM_ORG_ID.SYSTEM_CONSOLE) {
                        navigate({ to: '/admin-console' });
                    } else {
                        navigate({ to: '/console/$orgId', params: { orgId: org.id } });
                    }
                }
            } catch (e) {
                if (e instanceof Error && e.message.toLowerCase().includes('invalid state')) {
                    // This is a known issue with Auth0 where the state is invalid.
                    // See: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
                    logInDev('AuthCallback: invalid state, logging out');

                    await authStore.logout();
                    return;
                } else {
                    await errorMessage.showAsync(e);
                }
                message.info('Logging out...');
                authStore.logout();
            } finally {
                authInProgressRef.current = false;
                message.destroy();
            }
        };

        void asyncWrapper();
    }, []);

    return (
        <ComponentLoading isFullPage>
            <div className="hover:shadow-3xl mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl transition-all duration-500 ease-in-out">
                <div className="mb-6">
                    <img src="/static/curium-logo.svg" alt="Company Logo" className="mx-auto h-16 w-auto" />
                </div>
                <h2 className="text-primary-600 mb-4 text-3xl font-bold">Welcome!</h2>
                <p className="mb-8 text-lg text-gray-700">
                    We're securely authenticating your account. This will only take a moment.
                </p>
                <div className="flex flex-col items-center justify-center">
                    <Spin size="large" />
                    <p className="text-md mt-6 text-gray-600">Preparing your personalized experience...</p>
                </div>
            </div>
        </ComponentLoading>
    );
}
