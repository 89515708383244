import { ConfigurationItemEditor } from '@/components/Configuration/ConfigurationItemEditor';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/tasks')({
    staticData: {
        breadcrumb: {
            title: 'Tasks',
        },
        ui: {
            title: 'Tasks configuration',
        },
        modules: ['claim'],
    },
    component: ConfigurationTasks,
});

function ConfigurationTasks() {
    return (
        <ConfigurationItemEditor
            title="Task types"
            settingToEdit={'TASK_TYPES'}
            description="Rename, add or remove a task type."
            editor={{ height: '300px' }}
        />
    );
}
