import { useRef, useId } from 'react';
import { RefSelectProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { InputWrapper, VInputProps } from '@/components/Common/ViewForm/InputWrapper';
import { DefaultFormData } from '@/stores/FormStore/types';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';
import { useUser } from '@/hooks/User/useUser';
import { modal } from '../../Modal';

//TODO: Pick props from HandlingPartySelect
type Props<TFormData extends DefaultFormData> = VInputProps<TFormData> & {
    forAdminShowAllOrgs?: boolean;
    showUnallocated?: boolean;
    showAllOrgs?: boolean;
    onAccessLostOk?: () => void;
};

export const VHandlingPartySelect = observer(
    <TFormData extends DefaultFormData>({
        field,
        buttonsPosition = 'right',
        showAllOrgs,
        forAdminShowAllOrgs,
        showUnallocated,
        onAccessLostOk,
        ...props
    }: Props<TFormData>) => {
        const user = useUser();
        const inputRef = useRef<RefSelectProps>(null);
        const id = useId();

        return (
            <InputWrapper
                field={field}
                focusFn={() => {
                    inputRef.current?.focus();
                }}
                containerId={id}
                buttonsPosition={buttonsPosition}
                onSave={async (value, formCtx) => {
                    if (!onAccessLostOk) {
                        await formCtx.save({
                            [field]: value,
                        } as Partial<TFormData>);
                        return;
                    }

                    const hasAccess =
                        user.isOrgAdmin || user.externalOrgIds.some((item) => value.includes(BigInt(item)));

                    if (hasAccess) {
                        await formCtx.save({
                            [field]: value,
                        } as Partial<TFormData>);
                        return;
                    }

                    modal.confirm({
                        getContainer: () => document.getElementById(id)!,
                        title: 'Are you sure?',
                        content: 'If you make this change, the record will no longer be accessible for you.',
                        onOk: async () => {
                            await formCtx.save(
                                {
                                    [field]: value,
                                } as Partial<TFormData>,
                                { reload: false },
                            );
                            onAccessLostOk?.();
                        },
                    });
                }}
                {...props}
            >
                <HandlingPartySelect
                    ref={inputRef}
                    defaultOpen
                    showAllOrgs={showAllOrgs}
                    forAdminShowAllOrgs={forAdminShowAllOrgs}
                    showUnallocated={showUnallocated}
                    containerId={id}
                />
            </InputWrapper>
        );
    },
);
