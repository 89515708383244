import { errorMessage } from '@/components/Common/errorMessage';
import { Link } from '@/components/Common/Link';
import { PageLayout } from '@/components/Layout/PageLayout';
import { useTrpc } from '@/hooks/useTrpc';
import { RouterInputs, trpc } from '@/trpc';
import { OnFinishFailedFn } from '@/types/form';
import {
    AppstoreOutlined,
    BankOutlined,
    EditOutlined,
    InfoCircleOutlined,
    LockOutlined,
    TeamOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Alert, Button, Card, Checkbox, Form, Input, message, Select, Skeleton, Tabs } from 'antd';
import { useEffect, useId, useState } from 'react';
import { AUTH0_CONNECTION } from 'shared/constants/auth0';
import { APP_MODULES } from 'shared/types/app';
import invariant from 'tiny-invariant';
import { ShareableRouteContext } from '@/types/router';
import clsx from 'clsx';

export const Route = createFileRoute('/admin-console/organizations/$orgId/')({
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.admin.org.getOrg.ensureData({ orgId: params.orgId });

        return {
            ui: {
                async title() {
                    const org = await trpcUtils.admin.org.getOrg.ensureData({ orgId: params.orgId });
                    return org?.name || '';
                },
            },
        };
    },

    component: OrgForm,
});

type Org = Pick<
    RouterInputs['admin']['org']['updateOrg']['set'],
    | 'name'
    | 'enabledModules'
    | 'enabledAuth0Connections'
    | 'emailId'
    | 'isEnabled'
    | 'stripeId'
    | 'maxAllowedUsers'
    | 'isInitialized'
    | 'restrictions'
>;

const FormItem = Form.Item<Org>;

const moduleOptions = [
    { label: 'Claims Module', value: APP_MODULES.CLAIM, icon: <AppstoreOutlined /> },
    { label: 'Compliance Module', value: APP_MODULES.COMPLIANCE, icon: <AppstoreOutlined /> },
    { label: 'Risk Module', value: APP_MODULES.RISK, icon: <AppstoreOutlined /> },
    { label: 'Expenses & Billing', value: APP_MODULES.BILLING, icon: <AppstoreOutlined /> },
    { label: 'Tools Module', value: APP_MODULES.TOOLS, icon: <AppstoreOutlined /> },
];

function OrgForm() {
    const formId = useId();
    const { orgId } = Route.useParams();
    const [form] = Form.useForm<Org>();
    const { trpcClient, trpcUtils } = useTrpc();
    const [isEditing, setIsEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [activeTab, setActiveTab] = useState('basic');

    const { data, isPending } = trpc.admin.org.getOrg.useQuery({ orgId });

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data]);

    const onSave = async (values: Org) => {
        try {
            setSaving(true);
            message.loading('Saving...');
            invariant(orgId, 'orgId is required');
            console.log(values);
            await trpcClient.admin.org.updateOrg.mutate({
                orgId,
                set: {
                    name: values.name,
                    isEnabled: values.isEnabled,
                    enabledModules: values.enabledModules,
                    enabledAuth0Connections: values.enabledAuth0Connections,
                    emailId: values.emailId,
                    stripeId: values.stripeId,
                    maxAllowedUsers: values.maxAllowedUsers,
                    isInitialized: values.isInitialized,
                },
            });

            message.destroy();
            message.success('Organization settings saved successfully.');
            setIsEditing(false);
            void trpcUtils.admin.org.invalidate();
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setSaving(false);
        }
    };

    const onFinishFailed: OnFinishFailedFn<Org> = (errorInfo) => {
        errorMessage.validation(errorInfo.errorFields.map((field) => field.errors));
    };

    if (isPending) {
        return <Skeleton active />;
    }

    const renderReadOnlyContent = () => (
        <div className="space-y-6">
            <PageLayout.Header
                rightAddon={
                    <>
                        <Link
                            to="/admin-console/organizations/$orgId/users"
                            params={{ orgId }}
                            type="default"
                            icon={<TeamOutlined />}
                        >
                            Users
                        </Link>
                        <Link
                            to="/admin-console/organizations/$orgId/wallet"
                            params={{ orgId }}
                            type="default"
                            icon={<WalletOutlined />}
                        >
                            Wallet
                        </Link>
                    </>
                }
                primaryActionAddon={
                    <Button type="primary" icon={<EditOutlined />} onClick={() => setIsEditing(true)}>
                        Edit Organization
                    </Button>
                }
            />

            {!data?.isEnabled && (
                <Alert
                    message="Organization Inactive"
                    description="This organization is currently inactive. Users cannot access the platform until it is activated."
                    type="warning"
                    showIcon
                    className="mb-6"
                />
            )}

            <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                items={[
                    {
                        key: 'basic',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <BankOutlined />
                                Basic Information
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <Form layout="vertical">
                                    <div className="grid gap-6 md:grid-cols-2">
                                        <FormItem
                                            label="Organization Name"
                                            tooltip={{
                                                title: 'The display name of the organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input value={data?.name ?? ''} readOnly />
                                        </FormItem>
                                        <FormItem
                                            label="Organization Email ID"
                                            tooltip={{
                                                title: 'The email local name specific to this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input value={data?.emailId ?? ''} readOnly />
                                        </FormItem>
                                        <FormItem
                                            label="Stripe ID"
                                            tooltip={{
                                                title: 'The Stripe identifier for billing purposes',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input value={data?.stripeId || 'Not configured'} readOnly />
                                        </FormItem>
                                        <FormItem
                                            label="Status"
                                            tooltip={{
                                                title: 'Current status of the organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input
                                                value={data?.isEnabled ? 'Active' : 'Inactive'}
                                                readOnly
                                                className={data?.isEnabled ? 'text-green-600' : 'text-red-600'}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Max Allowed Users"
                                            tooltip={{
                                                title: 'Maximum number of users allowed in the organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input
                                                value={
                                                    data?.maxAllowedUsers === -1
                                                        ? 'Unlimited'
                                                        : String(data?.maxAllowedUsers ?? '')
                                                }
                                                readOnly
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Initialization Status"
                                            tooltip={{
                                                title: 'Indicates if the organization has completed initial setup',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <Input
                                                value={data?.isInitialized ? 'Initialized' : 'Not Initialized'}
                                                readOnly
                                                className={data?.isInitialized ? 'text-green-600' : 'text-yellow-600'}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Restrictions"
                                            tooltip={{
                                                title: 'Special restrictions applied to this organization',
                                                icon: <InfoCircleOutlined />,
                                            }}
                                        >
                                            <div className="space-y-2">
                                                {data?.restrictions?.length ? (
                                                    data.restrictions.map((restriction) => (
                                                        <span
                                                            key={restriction}
                                                            className="mr-2 inline-flex items-center gap-1 rounded-full bg-red-50 px-3 py-1 text-sm text-red-700"
                                                        >
                                                            <LockOutlined />
                                                            {restriction === 'payment_required_for_login'
                                                                ? 'Payment Required for Login'
                                                                : restriction}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className="text-gray-500">No restrictions</span>
                                                )}
                                            </div>
                                        </FormItem>
                                    </div>
                                </Form>
                            </Card>
                        ),
                    },
                    {
                        key: 'features',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <AppstoreOutlined />
                                Features
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <Form layout="vertical">
                                    <FormItem
                                        label="Enabled Modules"
                                        tooltip={{
                                            title: 'Modules that are enabled for this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <div className="space-y-2">
                                            {data?.enabledModules?.map((module) => {
                                                const moduleOption = moduleOptions.find((opt) => opt.value === module);
                                                return moduleOption ? (
                                                    <span
                                                        key={module}
                                                        className={clsx(
                                                            'mr-2 inline-flex items-center gap-1 rounded-full px-3 py-1',
                                                            'bg-blue-50 text-sm text-blue-700',
                                                        )}
                                                    >
                                                        {moduleOption.icon}
                                                        {moduleOption.label}
                                                    </span>
                                                ) : null;
                                            })}
                                        </div>
                                    </FormItem>
                                </Form>
                            </Card>
                        ),
                    },
                    {
                        key: 'authentication',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <LockOutlined />
                                Authentication
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <Form layout="vertical">
                                    <FormItem
                                        label="Authentication Methods"
                                        tooltip={{
                                            title: 'Allowed authentication methods for this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <div className="space-y-2">
                                            {data?.enabledAuth0Connections?.map((conn) => {
                                                const connection = Object.values(AUTH0_CONNECTION).find(
                                                    (c) => c.name === conn,
                                                );
                                                return connection ? (
                                                    <span
                                                        key={conn}
                                                        className="mr-2 inline-flex items-center gap-1 rounded-full bg-purple-50 px-3 py-1 text-sm text-purple-700"
                                                    >
                                                        <LockOutlined />
                                                        {connection.label}
                                                    </span>
                                                ) : null;
                                            })}
                                        </div>
                                    </FormItem>
                                </Form>
                            </Card>
                        ),
                    },
                ]}
                className="[&_.ant-tabs-nav-list]:!border-b-0 [&_.ant-tabs-nav]:mb-0 [&_.ant-tabs-nav]:!border-b-0 [&_.ant-tabs-tab.ant-tabs-tab-active]:!border-b-white [&_.ant-tabs-tab.ant-tabs-tab-active]:!bg-white [&_.ant-tabs-tab]:!rounded-b-none [&_.ant-tabs-tab]:!border-b-0"
                type="card"
            />
        </div>
    );

    const renderEditForm = () => (
        <Form<Org>
            layout="vertical"
            form={form}
            onFinish={onSave}
            onFinishFailed={onFinishFailed}
            className="space-y-6"
            id={formId}
        >
            <PageLayout.Header
                rightAddon={<Button onClick={() => setIsEditing(false)}>Cancel</Button>}
                primaryActionAddon={
                    <Button type="primary" form={formId} htmlType="submit" loading={saving}>
                        Save Changes
                    </Button>
                }
            />

            <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                items={[
                    {
                        key: 'basic',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <BankOutlined />
                                Basic Information
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <div className="grid gap-6 md:grid-cols-2">
                                    <FormItem
                                        label="Organization Name"
                                        required
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the organization name',
                                            },
                                            {
                                                min: 2,
                                                message: 'Name must be at least 2 characters',
                                            },
                                        ]}
                                        tooltip={{
                                            title: 'The display name of the organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Input placeholder="Enter organization name" />
                                    </FormItem>
                                    <FormItem
                                        name="emailId"
                                        label="Organization Email ID"
                                        tooltip={{
                                            title: 'The email local name specific to this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                        help="The organization will be able to send emails to this email ID, e.g. contoso@ai-inbox.curium.app"
                                    >
                                        <Input placeholder="Enter email ID, e.g. contoso" />
                                    </FormItem>
                                    <FormItem
                                        label="Stripe ID"
                                        name="stripeId"
                                        tooltip={{
                                            title: 'The Stripe identifier for billing purposes',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Input placeholder="Enter Stripe ID" />
                                    </FormItem>
                                    <FormItem
                                        name="isEnabled"
                                        valuePropName="checked"
                                        tooltip={{
                                            title: 'Disable to temporarily suspend organization access',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Checkbox>Organization is active</Checkbox>
                                    </FormItem>
                                    <FormItem
                                        label="Max Allowed Users"
                                        name="maxAllowedUsers"
                                        tooltip={{
                                            title: 'Maximum number of users allowed in the organization (set to -1 for unlimited)',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                        help="Enter -1 for unlimited users"
                                    >
                                        <Input type="number" placeholder="Enter max users (-1 for unlimited)" />
                                    </FormItem>
                                    <FormItem
                                        name="isInitialized"
                                        valuePropName="checked"
                                        tooltip={{
                                            title: 'Indicates if the organization has completed initial setup',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Checkbox>Organization is initialized</Checkbox>
                                    </FormItem>
                                    <FormItem
                                        name="restrictions"
                                        label="Restrictions"
                                        tooltip={{
                                            title: 'Special restrictions applied to this organization',
                                            icon: <InfoCircleOutlined />,
                                        }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Select restrictions"
                                            allowClear
                                            className="w-full"
                                            options={[
                                                {
                                                    label: (
                                                        <span className="flex items-center gap-2">
                                                            <LockOutlined />
                                                            Payment Required for Login
                                                        </span>
                                                    ),
                                                    value: 'payment_required_for_login',
                                                },
                                            ]}
                                        />
                                    </FormItem>
                                </div>
                            </Card>
                        ),
                    },
                    {
                        key: 'features',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <AppstoreOutlined />
                                Features
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <FormItem
                                    name="enabledModules"
                                    label="Enabled Modules"
                                    tooltip={{
                                        title: 'Select the modules that should be enabled for this organization',
                                        icon: <InfoCircleOutlined />,
                                    }}
                                >
                                    <Checkbox.Group
                                        options={moduleOptions}
                                        className="grid grid-cols-1 gap-4 md:grid-cols-2"
                                    />
                                </FormItem>
                            </Card>
                        ),
                    },
                    {
                        key: 'authentication',
                        label: (
                            <span className="flex items-center gap-2 px-1">
                                <LockOutlined />
                                Authentication
                            </span>
                        ),
                        children: (
                            <Card className="rounded-tl-none border-t-0 shadow-sm">
                                <FormItem
                                    name="enabledAuth0Connections"
                                    label="Authentication Methods"
                                    tooltip={{
                                        title: 'Select the allowed authentication methods for this organization',
                                        icon: <InfoCircleOutlined />,
                                    }}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Select authentication methods"
                                        allowClear
                                        className="w-full"
                                        options={[
                                            {
                                                label: (
                                                    <span className="flex items-center gap-2">
                                                        <LockOutlined />
                                                        {AUTH0_CONNECTION.DATABASE.label}
                                                    </span>
                                                ),
                                                value: AUTH0_CONNECTION.DATABASE.name,
                                            },
                                            {
                                                label: (
                                                    <span className="flex items-center gap-2">
                                                        <LockOutlined />
                                                        {AUTH0_CONNECTION.AZURE_AD.label}
                                                    </span>
                                                ),
                                                value: AUTH0_CONNECTION.AZURE_AD.name,
                                            },
                                        ]}
                                    />
                                </FormItem>
                            </Card>
                        ),
                    },
                ]}
                className="[&_.ant-tabs-nav-list]:!border-b-0 [&_.ant-tabs-nav]:mb-0 [&_.ant-tabs-nav]:!border-b-0 [&_.ant-tabs-tab.ant-tabs-tab-active]:!border-b-white [&_.ant-tabs-tab.ant-tabs-tab-active]:!bg-white [&_.ant-tabs-tab]:!rounded-b-none [&_.ant-tabs-tab]:!border-b-0"
                type="card"
            />
        </Form>
    );

    return isEditing ? renderEditForm() : renderReadOnlyContent();
}
