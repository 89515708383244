import { StoreWithSelectors } from '@/types/zustand';
import { StoreApi, useStore } from 'zustand';

export function createSelectorsForVanillaStore<S extends StoreApi<object>>(store: S) {
    const storeWithSelectors = store as StoreWithSelectors<typeof store>;

    storeWithSelectors.use = Object.keys(storeWithSelectors.getState()).reduce((prev, item) => {
        prev[item] = () =>
            useStore(storeWithSelectors, (state) => state[item as keyof typeof state]);

        return prev;
    }, {} as any);

    return storeWithSelectors;
}
