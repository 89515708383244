import { ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Select } from 'antd';
import { User } from '../User';
import { trpc } from '@/trpc';
import { SYSTEM_ACCOUNT_IDS } from 'shared/constants/user';
import { useUser } from '@/hooks/User/useUser';

type Props = {
    readonly?: boolean;
    placeholder?: string;
    /**
     * If true, only shows users that share handling parties with the current user.
     * handlingPartyIds will work as additional filter in this case
     */
    limitToCurrentUserHandlingParties?: boolean;
    handlingPartyIds?: number[] | bigint[];
    removeSystemAccount?: boolean;
    removeAnonymousAccount?: boolean;
    onlyEmployee?: boolean;
    showDisabledAccounts?: boolean;
    showOtherUser?: boolean;
    onChangeOther?: (value: string | undefined) => void;
    otherValue?: string;
} & ( // TODO: remove valueType prop after full migration from Hasura to trpc
    | {
          value?: number | null;
          onChange?: (
              userId: number | null,
              option: {
                  value: number | null;
                  label: ReactNode;
                  /**
                   * @deprecated Rewrite logic to use userId or option.value instead
                   */
                  name: string;
              },
          ) => void;
          valueType?: 'number';
      }
    | {
          value?: bigint | null;
          onChange?: (
              userId: bigint | null,
              option: {
                  value: bigint;
                  label: ReactNode;
                  /**
                   * @deprecated Rewrite logic to use userId or option.value instead
                   */
                  name: string;
              },
          ) => void;
          valueType: 'bigint';
      }
);

export const UserSelector = observer(
    ({
        value,
        valueType = 'number',
        onChange,
        readonly,
        placeholder,
        handlingPartyIds,
        limitToCurrentUserHandlingParties,
        removeSystemAccount = true,
        removeAnonymousAccount = true,
        onlyEmployee,
        showDisabledAccounts,
        showOtherUser,
        onChangeOther,
        otherValue,
    }: Props) => {
        const [showOtherUserInput, setShowOtherUserInput] = useState(value === SYSTEM_ACCOUNT_IDS.OTHER);
        const currentUser = useUser();
        const { data, isPending } = trpc.user.listUsersForSelector.useQuery(
            {
                where: {
                    AND: [
                        ...(limitToCurrentUserHandlingParties &&
                        !currentUser.isOrgAdmin &&
                        !currentUser.isSuperAdmin &&
                        currentUser?.externalOrgIds?.length
                            ? [
                                  {
                                      HandlingParties: {
                                          some: {
                                              handlingPartyId: {
                                                  in: currentUser.externalOrgIds,
                                              },
                                          },
                                      },
                                  },
                              ]
                            : []),
                        ...(handlingPartyIds?.length
                            ? [
                                  {
                                      HandlingParties: {
                                          some: {
                                              handlingPartyId: {
                                                  in: handlingPartyIds,
                                              },
                                          },
                                      },
                                  },
                              ]
                            : []),
                        ...(removeSystemAccount
                            ? [
                                  {
                                      userId: { not: SYSTEM_ACCOUNT_IDS.SYSTEM },
                                      User: { isSupportAccount: { not: true } },
                                  },
                              ]
                            : []),
                        ...(removeAnonymousAccount ? [{ userId: { not: SYSTEM_ACCOUNT_IDS.ANONYMOUS } }] : []),
                        ...(showOtherUser ? [] : [{ userId: { not: SYSTEM_ACCOUNT_IDS.OTHER } }]),
                        ...(onlyEmployee ? [{ isEmployee: true }] : []),
                        ...(showDisabledAccounts ? [] : [{ disabled: false }]),
                    ],
                },
            },
            {
                select({ rows }) {
                    const map = rows.reduce(
                        (prev, item) => {
                            const key = item.fullName.toLowerCase() || item.email;
                            return { ...prev, [key]: prev[key] ? prev[key] + 1 : 1 };
                        },
                        {} as Record<string, number>,
                    );

                    return rows.map((item) => {
                        const key = item.fullName.toLowerCase() || item.email;
                        const name = map[key] > 1 ? `${item.fullName} (${item.email})` : item.fullName;

                        return {
                            value: valueType === 'bigint' ? item.userId : Number(item.userId),
                            name,
                            label: (
                                <User userId={item.userId} photoUrl={item.photoUrl} name={name} showPopover={false} />
                            ),
                            searchValue:
                                `${item.userId === SYSTEM_ACCOUNT_IDS.OTHER ? 'other' : item.fullName} ${item.email}`.toLowerCase(),
                        };
                    });
                },
            },
        );

        return (
            <div>
                <Select
                    value={value}
                    onSelect={(value, option) => {
                        if (showOtherUser) {
                            setShowOtherUserInput(value === SYSTEM_ACCOUNT_IDS.OTHER);
                        }

                        onChange?.(value as any, option as any);
                    }}
                    options={data}
                    loading={isPending}
                    placeholder={placeholder}
                    suffixIcon={null}
                    filterOption={(input, option) => !!option?.searchValue?.includes(input.toLowerCase())}
                    showSearch
                    disabled={readonly}
                />
                {showOtherUserInput && (
                    <Input
                        className="mt-2"
                        value={otherValue || ''}
                        onChange={(e) => onChangeOther?.(e.target.value)}
                    />
                )}
            </div>
        );
    },
);
