import { errorMessage } from '@/components/Common/errorMessage';
import { useLocalStore } from '@/hooks/useLocalStore';
import { useSafePath } from '@/hooks/useSafePath';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { createFileRoute } from '@tanstack/react-router';
import { Button, Card, Form, InputNumber, message, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { APP_SETTING } from 'shared/types/AppSetting';

type State = {
    notificationDisabled: string;
    indicatorProcessingBatchSize: string;
    upgradeInProgress: string;
    recaptchaScoreThreshold: string;
};

const AppSettings = observer(() => {
    const [state, initState] = useLocalStore<State>(() => ({
        notificationDisabled: 'false',
        indicatorProcessingBatchSize: '5',
        upgradeInProgress: 'false',
        recaptchaScoreThreshold: '0.5',
    }));
    const trpcClient = useTrpcClient();
    const [processing, setProcessing] = useState(false);
    const safePath = useSafePath<State>();

    const [form] = Form.useForm();

    const load = async () => {
        try {
            const notificationDisabledRes = await trpcClient.admin.appSetting.getAppSettingByName.query({
                name: APP_SETTING.NOTIFICATION_DISABLED,
            });
            const indicatorProcessingBatchSizeRes = await trpcClient.admin.appSetting.getAppSettingByName.query({
                name: APP_SETTING.INDICATOR_PROCESSING_BATCH_SIZE,
            });
            const upgradeInProgressRes = await trpcClient.admin.appSetting.getAppSettingByName.query({
                name: APP_SETTING.UPGRADE_IN_PROGRESS,
            });
            const recaptchaScoreThresholdRes = await trpcClient.admin.appSetting.getAppSettingByName.query({
                name: APP_SETTING.RECAPTCHA_SCORE_THRESHOLD,
            });
            initState({
                indicatorProcessingBatchSize: indicatorProcessingBatchSizeRes?.value || '',
                notificationDisabled: notificationDisabledRes?.value || '',
                upgradeInProgress: upgradeInProgressRes?.value || '',
                recaptchaScoreThreshold: recaptchaScoreThresholdRes?.value || '',
            });
            form.setFieldsValue(state);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    useEffect(() => {
        load();
    }, []);

    const save = async () => {
        try {
            setProcessing(true);
            message.info('Saving...');
            await trpcClient.admin.appSetting.updateAppSetting.mutate({
                name: APP_SETTING.NOTIFICATION_DISABLED,
                value: state.notificationDisabled,
            });
            await trpcClient.admin.appSetting.updateAppSetting.mutate({
                name: APP_SETTING.INDICATOR_PROCESSING_BATCH_SIZE,
                value: state.indicatorProcessingBatchSize,
            });
            await trpcClient.admin.appSetting.updateAppSetting.mutate({
                name: APP_SETTING.UPGRADE_IN_PROGRESS,
                value: state.upgradeInProgress,
            });
            await trpcClient.admin.appSetting.updateAppSetting.mutate({
                name: APP_SETTING.RECAPTCHA_SCORE_THRESHOLD,
                value: state.recaptchaScoreThreshold,
            });
            message.destroy();
            message.success('Saved');
        } catch (e) {
            errorMessage.show(e);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={save}
            onFinishFailed={(errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
            }}
            className="mx-auto max-w-3xl"
        >
            <Card>
                <div className="space-y-6">
                    <Form.Item
                        label={<span className="font-medium">Notification Disabled</span>}
                        required
                        name={safePath('notificationDisabled')}
                        rules={[
                            {
                                validator: async () => {
                                    if (!state.notificationDisabled) throw new Error('Field is required');
                                },
                            },
                        ]}
                    >
                        <Select
                            value={state.notificationDisabled}
                            onChange={(value) => {
                                state.notificationDisabled = value;
                            }}
                            className="w-48"
                        >
                            <Select.Option value="true">Enabled</Select.Option>
                            <Select.Option value="false">Disabled</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<span className="font-medium">Indicator Processing Batch Size</span>}
                        required
                        name={safePath('indicatorProcessingBatchSize')}
                        rules={[
                            {
                                validator: async () => {
                                    if (!state.indicatorProcessingBatchSize) throw new Error('Field is required');
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            value={Number(state.indicatorProcessingBatchSize)}
                            min={1}
                            onChange={(value) => {
                                state.indicatorProcessingBatchSize = (value || 5).toString();
                            }}
                            className="w-48"
                        />
                    </Form.Item>

                    <Form.Item
                        label={<span className="font-medium">Upgrade In Progress</span>}
                        required
                        name={safePath('upgradeInProgress')}
                        rules={[
                            {
                                validator: async () => {
                                    if (!state.upgradeInProgress) throw new Error('Field is required');
                                },
                            },
                        ]}
                    >
                        <Select
                            value={state.upgradeInProgress}
                            onChange={(value) => {
                                state.upgradeInProgress = value;
                            }}
                            className="w-48"
                        >
                            <Select.Option value="true">Yes</Select.Option>
                            <Select.Option value="false">No</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<span className="font-medium">Recaptcha Score Threshold</span>}
                        required
                        name={safePath('recaptchaScoreThreshold')}
                        rules={[
                            {
                                validator: async () => {
                                    if (!state.recaptchaScoreThreshold) throw new Error('Field is required');
                                },
                            },
                        ]}
                        help="Enter a value between 0 and 1"
                    >
                        <InputNumber
                            value={Number(state.recaptchaScoreThreshold)}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(value) => {
                                if (value === 0) {
                                    state.recaptchaScoreThreshold = '0';
                                } else {
                                    state.recaptchaScoreThreshold = (value || 0.5).toString();
                                }
                            }}
                            className="w-48"
                        />
                    </Form.Item>
                </div>
            </Card>

            <div className="mt-4 flex justify-end">
                <Button type="primary" htmlType="submit" loading={processing}>
                    Save Changes
                </Button>
            </div>
        </Form>
    );
});

export const Route = createFileRoute('/admin-console/advanced/app-settings')({
    staticData: {
        breadcrumb: {
            title: 'App settings',
        },
        ui: {
            title: 'App settings',
            variant: 'compact',
        },
    },
    component: AppSettings,
});
