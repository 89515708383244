import { createFileRoute } from '@tanstack/react-router';
import { App, Button, Card, Divider, Form, Input, Select, Space, Switch } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { SupplierDefs } from '@/global/supplier';
import Joi from 'joi';
import { useSafePath } from '@/hooks/useSafePath';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { PhoneNumberInput } from '@/components/Common/PhoneNumberInput';
import { validatePhoneNumber } from '@/utils/phone';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { RouterInputs, trpc } from '@/trpc';
import { Link } from '@/components/Common/Link';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';

export const Route = createFileRoute('/console/$orgId/configuration/suppliers/add')({
    staticData: {
        breadcrumb: {
            title: 'New Supplier',
        },
        ui: {
            variant: 'compact',
        },
    },
    component: SupplierAdd,
});

type Supplier = RouterInputs['supplier']['createSupplier'];

function SupplierAdd() {
    const { message } = App.useApp();
    const navigate = useNavigate();
    const orgId = useOrgId();
    const safePath = useSafePath<Supplier>();
    const { data: supplierCategories, loading: supplierCategoriesLoading } = useConfigServiceLoader((loader) => {
        return loader.fetchSupplier_Categories();
    });
    const { mutateAsync: createSupplier, isPending: loading } = trpc.supplier.createSupplier.useMutation();

    const [form] = Form.useForm<Supplier>();
    const country = Form.useWatch('country', form);

    const save = async (values: Supplier) => {
        try {
            message.info('Saving...');
            const { id } = await createSupplier({
                ...values,
            });
            message.destroy();
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/configuration/suppliers/details/$id',
                params: { orgId, id },
            });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    };

    return (
        <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            onFinish={save}
            onFinishFailed={(errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
            }}
        >
            <Card>
                <Divider orientation="left">Supplier information</Divider>
                <Form.Item
                    label="Name"
                    name={safePath('name')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Category" name={safePath('category')}>
                    <Select
                        loading={supplierCategoriesLoading}
                        options={supplierCategories?.map((category) => ({
                            label: category,
                            value: category,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Contact Person"
                    name={safePath('contactPerson')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    required
                    name={safePath('email')}
                    rules={[
                        {
                            validator: async (_, value) => {
                                const res = Joi.string()
                                    .required()
                                    .email({ tlds: { allow: false } })
                                    .label('Email')
                                    .validate(value);
                                if (res.error?.message) throw new Error(res.error?.message);
                            },
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address line 1"
                    name={safePath('addressLine1')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address line 2" name={safePath('addressLine2')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name={safePath('country')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        options={Object.keys(SupplierDefs.states).map((c) => ({
                            label: c,
                            value: c,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="State"
                    name={safePath('stateOrProvince')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        options={
                            country
                                ? SupplierDefs.states[country].map((state) => ({
                                      label: state,
                                      value: state,
                                  }))
                                : []
                        }
                        disabled={!country}
                    />
                </Form.Item>
                <Form.Item
                    label="Phone number"
                    name={safePath('phone')}
                    rules={[
                        {
                            validator: async (_, value) => {
                                if (!validatePhoneNumber(value)) throw new Error('Invalid phone number');
                            },
                        },
                    ]}
                >
                    <PhoneNumberInput type="tel" />
                </Form.Item>
                <Form.Item label="Servicing the following areas">
                    <Input.Group>
                        <Form.Item name={safePath('servicingAreaStates')}>
                            <Select
                                mode="multiple"
                                className="w-full"
                                options={SupplierDefs.aryServicingAreaState.map((state) => ({
                                    label: state,
                                    value: state,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name={safePath('servicingAreaPostcodes')}
                            rules={[
                                {
                                    validator: (rule, values, callback) => {
                                        const postcodeRegExp =
                                            /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/;
                                        let invalidInputs = [];
                                        if (values) {
                                            invalidInputs = values.filter(
                                                (value: string) => !value.match(postcodeRegExp),
                                            );
                                        }
                                        if (invalidInputs.length === 0 || !values) {
                                            callback();
                                        } else if (invalidInputs.length === 1) {
                                            callback(invalidInputs.join('') + ' is not a valid postcode');
                                        } else {
                                            callback(
                                                invalidInputs.slice(0, -1).join(', ') +
                                                    ' and ' +
                                                    invalidInputs.slice(-1) +
                                                    ' are not valid postcodes',
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                className="w-full"
                                tokenSeparators={[',']}
                                popupClassName="tag-select-dropdown"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    label="AFSL Status"
                    name={safePath('asfStatus')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        options={SupplierDefs.aryAfslStatus.map((status) => ({
                            label: status,
                            value: status,
                        }))}
                    />
                </Form.Item>

                <Divider orientation="left">Financial details</Divider>
                <Form.Item
                    label="BSB"
                    name={safePath('accountBsb')}
                    required
                    rules={[
                        {
                            validator: async (_, value) => {
                                const res = Joi.string()
                                    .pattern(/^\d{3}-?\d{3}$/)
                                    .label('BSB')
                                    .messages({
                                        'string.pattern.base': 'Invalid BSB code',
                                    })
                                    .required()
                                    .validate(value);
                                if (res.error?.message) throw new Error(res.error?.message);
                            },
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Account number"
                    name={safePath('accountNo')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Registered for GST" name={safePath('asfStatus')} valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="ABN"
                    name={safePath('abn')}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Remittance"
                    name={safePath('remittanceEmail')}
                    rules={[
                        {
                            validator: async (_, value) => {
                                const res = Joi.string()
                                    .allow(null, '')
                                    .email({ tlds: { allow: false } })
                                    .label('Remittance')
                                    .validate(value);
                                if (res.error?.message) throw new Error(res.error?.message);
                            },
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Divider orientation="left">Allocation rules</Divider>
                <Form.Item
                    label="Handling parties to use this supplier"
                    name={safePath('handlingPartyIds')}
                    getValueProps={(value) => {
                        return value;
                    }}
                >
                    <HandlingPartySelect placeholder="" showAllOrgs />
                </Form.Item>
            </Card>
            <Space className="mt-2 flex justify-end">
                <Link
                    to="/console/$orgId/configuration/suppliers"
                    params={{
                        orgId,
                    }}
                >
                    Cancel
                </Link>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                </Button>
            </Space>
        </Form>
    );
}
