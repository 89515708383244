import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/public')({
    component: Public,
});

function Public() {
    return (
        <div className="relative flex min-h-screen w-screen flex-col justify-start bg-gray-100 md:justify-center">
            <Outlet />
        </div>
    );
}
