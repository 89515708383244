import { useAuthStore } from '@/stores/AuthStore';
import { debounce } from '@/utils/general';
import { QueryClient } from '@tanstack/react-query';
import { unstable_httpBatchStreamLink } from '@trpc/client';
import { createTRPCQueryUtils, createTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { notification } from 'antd';
import Decimal from 'decimal.js';
import { load } from 'recaptcha-v3';
import type { AppRouter } from 'server/routes/root';
import { decodeJwtToken } from 'shared/utils/jwt';
import { SuperJSON } from 'superjson';

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;

export type RouterInputs = inferRouterInputs<AppRouter>;

export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export type TRPCClient = ReturnType<typeof trpc.useUtils>;

export type StaleTime = number | 'immediate' | 'infinite' | 'default';

export function getStaleTime(staleTime?: StaleTime) {
    if (!staleTime || typeof staleTime === 'number') {
        return staleTime;
    }

    return {
        immediate: 0,
        infinite: Infinity,
        default: undefined,
    }[staleTime];
}

const debouncedNotificationError = debounce((error: Error) => {
    notification.error({
        message: 'Error',
        description: error.message,
        showProgress: true,
        pauseOnHover: true,
    });
}, 300);

// Replace notification.error calls in the QueryClient's defaultOptions:
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            throwOnError(error) {
                debouncedNotificationError(error);
                return false;
            },
        },
        mutations: {
            retry: false,
            throwOnError(error) {
                debouncedNotificationError(error);
                return false;
            },
        },
    },
});

SuperJSON.registerCustom<Decimal, string>(
    {
        isApplicable(value): value is Decimal {
            return Decimal.isDecimal(value);
        },
        serialize(value) {
            return value.toJSON();
        },
        deserialize(value) {
            return new Decimal(value);
        },
    },
    'decimal.js',
);

export const trpcClient = trpc.createClient({
    links: [
        unstable_httpBatchStreamLink({
            url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
            transformer: SuperJSON,
            async headers(opts) {
                const headers: Record<string, string> = {
                    'x-page-url': window.location.href,
                };
                if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                    const recaptcha = await load(import.meta.env.VITE_RECAPTCHA_SITE_KEY);
                    const actionName = opts.opList[0].path;
                    // Sanitize action name
                    const sanitizedActionName = actionName.replace(/[^a-zA-Z]/g, '_');
                    const token = await recaptcha.execute(sanitizedActionName);
                    headers['x-recaptcha-token'] = token;
                }
                const token = useAuthStore.getState().getAuthToken();
                const isExpired = isTokenExpired(token);
                if (token && !isExpired) {
                    headers['authorization'] = `Bearer ${token}`;
                }
                return headers;
            },
        }),
    ],
});

export const trpcQueryUtils = createTRPCQueryUtils({
    queryClient,
    client: trpcClient,
});

function isTokenExpired(token: string | undefined) {
    if (!token) {
        return true;
    }
    const decoded = decodeJwtToken(token);
    if (decoded) {
        const now = new Date();
        const expirationDate = new Date(decoded.exp * 1000);
        return expirationDate < now;
    }
    return true;
}
