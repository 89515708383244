import { ActionBar } from '@/components/Common/ActionBar';
import { ScrollToTopOnMount } from '@/components/Common/ScrollToTopOnMount';
import { ClaimFormState } from '@/hooks/Claim/useClaimFormState';
import { PublicClaimFormState } from '@/hooks/Claim/usePublicClaimFormState';
import { useNavigate } from '@/hooks/useNavigate';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

interface Props {
    state: ClaimFormState | PublicClaimFormState;
    goBack: () => any;
    onSave: () => Promise<any>;
}

export const AdditionalInfo = observer(({ state, goBack, onSave }: Props) => {
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);

    return (
        <Form size="large" name="_additionalInfo">
            <Spin spinning={saving}>
                <ScrollToTopOnMount />
                <Card title="Would you like to tell us anything else?" className="crm-ant-card-flexible-header">
                    <Input.TextArea
                        autoSize={{ minRows: 6 }}
                        value={(state.claim.additionalInfo as string) || ''}
                        onChange={(e) => {
                            state.claim.additionalInfo = e.target.value;
                        }}
                    />
                </Card>
                <ActionBar
                    renderLeft={() => (
                        <Button icon={<ArrowLeftOutlined />} onClick={goBack} loading={saving} size="large">
                            Back
                        </Button>
                    )}
                    renderRight={() => (
                        <>
                            {state.ui.readOnly ? (
                                <Button onClick={() => navigate({ href: '/app/dashboard' })} size="large">
                                    Close
                                </Button>
                            ) : (
                                <>
                                    {!state.ui.readOnly && (
                                        <Button
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            onClick={async () => {
                                                try {
                                                    setSaving(true);
                                                    await onSave();
                                                } finally {
                                                    setSaving(false);
                                                }
                                            }}
                                            size="large"
                                            loading={saving}
                                        >
                                            Lodge Claim
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                />
            </Spin>
        </Form>
    );
});
