import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useOrgId } from '@/hooks/Org/useOrgId';

export const Route = createFileRoute('/console/$orgId/compliance/training/')({
    component() {
        const orgId = useOrgId();

        return <Navigate to="/console/$orgId/compliance/training/search" params={{ orgId }} />;
    },
});
