import { TrainingRecordViewUser } from '@/components/Training/TrainingRecordViewUser';
import { ShareableRouteContext } from '@/types/router';
import { getUserFullName } from '@/utils/general';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/training/user/$userId')({
    params: {
        parse({ userId }) {
            return {
                userId: BigInt(userId),
            };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.user.getUser.ensureData({
            id: params.userId,
            orgId: params.orgId,
        });

        return {
            breadcrumb: {
                async title() {
                    const user = await trpcUtils.user.getUser.ensureData({
                        id: params.userId,
                        orgId: params.orgId,
                    });

                    return getUserFullName(user);
                },
            },
        };
    },
    component: TrainingRecordViewUser,
});
