import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useFormatter } from '@/hooks/useFormatter';
import { RouterInputs, trpc } from '@/trpc';
import { UserAddOutlined } from '@ant-design/icons';
import { createFileRoute } from '@tanstack/react-router';
import { Avatar, Button, Card, Checkbox, Input, Table, Tag } from 'antd';
import { useState } from 'react';
import { USER_SYSTEM_USAGES, UserSystemUsage } from 'shared/constants/user';
import invariant from 'tiny-invariant';
import { EditUser, EditUserAction } from './EditUser';
import { InviteUser } from './InviteUser';

export const Route = createFileRoute('/admin-console/organizations/$orgId/users')({
    staticData: {
        breadcrumb: {
            title: 'Users',
        },
    },
    component: UsersInOrganization,
});

type QueryVariables = RouterInputs['user']['listUsers'];

const DEFAULT_FILTER = {
    AND: [{ userId: { gt: 1 } }, { User: { isSupportAccount: false } }],
} satisfies QueryVariables['where'];

function UsersInOrganization() {
    const { orgId } = Route.useParams();
    const { formatDate } = useFormatter();

    const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false);
    const [userAction, setUserAction] = useState<EditUserAction>(['none']);
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        orgId: BigInt(orgId),
        where: DEFAULT_FILTER,
        orderBy: [{ firstName: 'asc' }],
        limit: 10,
        offset: 0,
    });

    const { data, isLoading, refetch } = trpc.user.listUsers.useQuery({
        orgId,
        where: queryVariables.where,
        orderBy: queryVariables.orderBy,
        limit: queryVariables.limit,
        offset: queryVariables.offset,
    });

    const { tableProps } = useAntdTable({
        rowKey: 'userId',
        data: {
            rows: data?.rows,
            loading: isLoading,
            total: data?.total,
        },
        onQueryVariableChange(options) {
            setQueryVariables((prev) => ({
                ...prev,
                limit: options?.limit || 10,
                offset: options?.offset || 0,
                orderBy: options?.orderBy,
            }));
        },
        columns: [
            {
                dataIndex: 'userId',
                title: 'ID',
                sorter: true,
                align: 'center',
                fixed: 'left',
                render: (value) => BigInt(value).toString(),
            },
            {
                dataIndex: 'firstName',
                title: 'First name',
                sorter: true,
                fixed: 'left',
            },
            {
                title: 'Last name',
                dataIndex: 'lastName',
                sorter: true,
                fixed: 'left',
            },
            {
                title: 'Position',
                dataIndex: 'position',
                sorter: true,
            },
            {
                dataIndex: 'email',
                title: 'Email',
                sorter: true,
                render: (email) => <a href={`mailto:${email}`}>{email}</a>,
            },
            {
                dataIndex: 'photoUrl',
                title: 'Photo',
                render: (photoUrl) => <Avatar src={photoUrl} />,
            },
            {
                dataIndex: 'disabled',
                title: 'Disabled',
                sorter: true,
                render: (value) => <Checkbox checked={!!value} />,
                align: 'center',
            },
            {
                dataIndex: 'canViewUnallocatedClaims',
                title: 'View unallocated claims',
                sorter: true,
                render: (value) => <Checkbox checked={!!value} />,
                align: 'center',
            },
            {
                dataIndex: 'systemUsage',
                title: 'Primary use of the system',
                sorter: true,
                render: (value) => USER_SYSTEM_USAGES[value as UserSystemUsage] || value,
            },
            {
                dataIndex: 'roles',
                title: 'Roles',
                align: 'center',
                render: (value) => (
                    <>
                        {(Array.isArray(value) ? value : []).map((el) => (
                            <Tag key={el}>{el}</Tag>
                        ))}
                    </>
                ),
            },
            {
                title: 'Last login',
                dataIndex: 'lastLoginAt',
                sorter: true,
                render: (value) => formatDate(value, 'date-time'),
            },
            {
                dataIndex: 'createdAt',
                title: 'Created',
                render: (value) => formatDate(value, 'date-time'),
            },
        ],
        paginationConfig: {
            defaultPageSize: 10,
            showSizeChanger: true,
        },
    });

    const search = (searchValue: string) => {
        const where: QueryVariables['where'] = { OR: [] } as const;
        invariant(where.OR, 'where.OR is not defined');
        if (searchValue) {
            where.OR.push({ firstName: { contains: `%${searchValue}%` } });
            where.OR.push({ lastName: { contains: `%${searchValue}%` } });
            where.OR.push({ User: { email: { contains: `%${searchValue}%` } } });
        }

        const newVariables = {
            ...queryVariables,
            where: {
                AND: [...DEFAULT_FILTER.AND, ...(where.OR.length > 0 ? [where] : [])],
            },
        };
        setQueryVariables(newVariables);
    };

    return (
        <>
            <div className="mb-4 flex items-center justify-between">
                <Input.Search
                    placeholder="Search users"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        search(value);
                    }}
                    className="w-96"
                />
                <Button icon={<UserAddOutlined />} onClick={() => setOpenInviteUserDialog(true)}>
                    Invite user
                </Button>
            </div>

            <InviteUser
                open={openInviteUserDialog}
                orgId={BigInt(orgId)}
                onClose={() => {
                    setOpenInviteUserDialog(false);
                    void refetch();
                }}
            />
            <EditUser
                action={userAction}
                orgId={BigInt(orgId)}
                onClose={() => {
                    setUserAction(['none']);
                    void refetch();
                }}
            />
            <Card>
                <Table
                    scroll={{ x: 'max-content' }}
                    {...tableProps}
                    size="small"
                    onRow={(item) => ({
                        onClick: () => {
                            setUserAction(['edit', item.userId]);
                        },
                        className: 'cursor-pointer',
                    })}
                />
            </Card>
        </>
    );
}
