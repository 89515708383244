import { useState } from 'react';
import { gql } from '@/__generated__';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { App, Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, CopyOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { OrderBy, ControlLibraryDsQuery, ControlLibraryDsQueryVariables } from '@/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { errorMessage } from '@/components/Common/errorMessage';
import { CollapsiblePanel } from '@/components/Common/CollapsiblePanel';
import { ControlLibraryItemEdit } from '@/components/Control/ControlLibraryItemEdit';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import { OrgSelector } from '@/components/Common/OrgSelector';
import { InsertControlLibraryOne } from '@/graphql/mutations/control';
import { useNavigate } from '@/hooks/useNavigate';
import { createFileRoute } from '@tanstack/react-router';
import { commonActionColumn } from '@/utils/table';

export const Route = createFileRoute('/admin-console/controls/library/')({
    staticData: {
        ui: {
            title: 'Controls library',
            subtitle: 'Manage your controls library.',
        },
    },
    component: ControlLibrary,
});

type ControlLibraryItem = ControlLibraryDsQuery['ControlLibrary'][number];

type FilterForm = {
    objective?: string;
    owner?: string;
    orgIds?: number[];
};

type SendToOrgForm = {
    orgs: number[];
};

function ControlLibrary() {
    const { message } = App.useApp();
    const [insertControlLibraryOne] = useMutation(InsertControlLibraryOne);
    const [deleteControlLibraryByPk] = useMutation(DeleteControlLibraryByPk);
    const [insertControls, { loading: sendToOrgLoading }] = useMutation(InsertControls);
    const navigate = useNavigate();

    const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
    const [variables, setVariables] = useState<ControlLibraryDsQueryVariables>({
        where: undefined,
        orderBy: [{ id: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });
    const [showFilter, setShowFilter] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ControlLibraryItem[]>([]);

    const { data, loading, refetch } = useQuery(ControlLibraryDs, {
        variables,
        fetchPolicy: 'network-only',
        onError: (e) => errorMessage.show(e),
    });

    const [filterForm] = Form.useForm<FilterForm>();
    const [sendToOrgForm] = Form.useForm<SendToOrgForm>();

    const { tableProps } = useAntdTableOld<ControlLibraryItem>({
        data: {
            rows: data?.ControlLibrary,
            loading,
            total: data?.ControlLibraryAggregate?.aggregate?.count,
        },
        paginationConfig: { hideOnSinglePage: true },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                ...{
                    limit: options?.limit || 10,
                    offset: options?.offset || 0,
                    orderBy: options?.orderBy?.length === 0 ? variables.orderBy : options?.orderBy,
                },
            });
        },
        rowKey: 'id',
        columns: [
            {
                title: 'Control No.',
                dataIndex: 'id',
            },
            {
                title: 'Objective',
                dataIndex: 'objective',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (value) => (
                    <Tooltip placement="bottom" title={value}>
                        <Typography.Paragraph ellipsis style={{ maxWidth: 250, minWidth: 100, marginBottom: 0 }}>
                            {value}
                        </Typography.Paragraph>
                    </Tooltip>
                ),
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
            },
            {
                title: 'Assigned to',
                dataIndex: 'OrgsControls',
                render: (_, record) => record.OrgsControls?.map((item) => item.Org.name).join(', '),
            },
            {
                ...commonActionColumn,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Create a copy">
                            <Button
                                type="link"
                                size="small"
                                icon={<CopyOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCopy(record);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure?"
                                onPopupClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onConfirm={() => {
                                    void onDelete(record.id);
                                }}
                                okButtonProps={{ danger: true }}
                                okText="Delete"
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            },
        ],
    });

    const rowSelection: TableRowSelection<ControlLibraryItem> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: ControlLibraryItem[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const onSearch = () => {
        const values = filterForm.getFieldsValue();
        const where: ControlLibraryDsQueryVariables['where'] = {
            _and: [],
        };

        if (values.owner) {
            const words = values.owner
                .split(' ')
                .map((word) => (word || '').trim())
                .filter((word) => word.length > 0);
            where._and?.push({
                _or: words.map((word) => ({ owner: { _ilike: `%${word}%` } })),
            });
        }
        if (values.objective) {
            const words = values.objective
                .split(' ')
                .map((word) => (word || '').trim())
                .filter((word) => word.length > 0);
            where._and?.push({
                _or: words.map((word) => ({ objective: { _ilike: `%${word}%` } })),
            });
        }

        if (values.orgIds) {
            where._and?.push({
                OrgsControls: {
                    orgId: { _in: values.orgIds },
                },
            });
        }

        setVariables({ ...variables, where });
    };

    const onSendToOrganizations = async (values: SendToOrgForm) => {
        const { orgs } = values;
        const controlsToInsert = selectedRows.flatMap(({ id, objective, description }) =>
            orgs.map((orgId) => ({
                orgId,
                objective,
                description,
                status: 'DRAFT',
                libraryItemId: id,
            })),
        );

        try {
            message.info('Sending to organisations...');
            await insertControls({
                variables: { objects: controlsToInsert },
            });
            message.destroy();
            message.success('Successfully sent to organisations');
            setIsModalVisible(false);
            sendToOrgForm.resetFields();
            void refetch(variables);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onCopy = async (record: ControlLibraryItem) => {
        try {
            message.info('Copying...');

            const { objective, description, owner } = record;
            const { data } = await insertControlLibraryOne({
                variables: {
                    input: {
                        objective,
                        description,
                        owner,
                    },
                },
            });

            message.destroy();
            message.success(`Control copied successfully. Copy Control No: ${data?.insertControlLibraryOne?.id}`);

            void refetch(variables);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const onDelete = async (id: number) => {
        try {
            message.info('Deleting...');
            await deleteControlLibraryByPk({
                variables: {
                    id,
                },
            });
            message.destroy();
            message.success('Deleted.');
            void refetch(variables);
        } catch (e) {
            errorMessage.show(e);
        }
    };

    return (
        <>
            <ControlLibraryItemEdit
                idToEdit={idToEdit}
                open={Boolean(idToEdit)}
                onClose={() => {
                    setIdToEdit(undefined);
                    void refetch(variables);
                }}
            />

            <Modal
                confirmLoading={sendToOrgLoading}
                open={isModalVisible}
                maskClosable={false}
                title="Assign controls to register"
                onCancel={() => {
                    setIsModalVisible(false);
                    sendToOrgForm.resetFields();
                }}
                okText="Submit"
                okButtonProps={{
                    autoFocus: true,
                    htmlType: 'submit',
                    form: 'sendToOrgForm',
                }}
            >
                <Form form={sendToOrgForm} layout="vertical" id="sendToOrgForm" onFinish={onSendToOrganizations}>
                    <Form.Item label="Select the organization(s)" name="orgs">
                        <OrgSelector mode="multiple" />
                    </Form.Item>
                </Form>
            </Modal>

            <CollapsiblePanel open={showFilter}>
                <Card title="Filters" size="small" className="mb-4">
                    <Form form={filterForm} layout="vertical" onFinish={onSearch}>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="Objective" name="objective">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Owner" name="owner">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Assigned to" name="orgIds">
                                    <OrgSelector mode="multiple" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space className="flex justify-end">
                                    <Button
                                        onClick={() => {
                                            filterForm.resetFields();
                                            onSearch();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </CollapsiblePanel>

            <Space className="mb-4 flex justify-between">
                <Button type="primary" onClick={() => setIsModalVisible(true)} disabled={!(selectedRowKeys.length > 0)}>
                    Send to organization
                </Button>
                <Space>
                    <Tooltip title="Filters">
                        <Button type="text" size="large" title="Filter" onClick={() => setShowFilter(!showFilter)}>
                            <FilterOutlined />
                        </Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setIdToEdit(-1);
                        }}
                    >
                        Add control
                    </Button>
                </Space>
            </Space>

            <Card>
                <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection}
                    onRow={({ id }) => ({
                        className: 'cursor-pointer',
                        onClick: (e) => {
                            navigate(
                                {
                                    to: '/admin-console/controls/library/$id/$section',
                                    params: {
                                        id,
                                        section: 'details',
                                    },
                                },
                                e,
                            );
                        },
                    })}
                    {...tableProps}
                />
            </Card>
        </>
    );
}

const ControlLibraryDs = gql(/* GraphQL */ `
    query ControlLibraryDs(
        $where: ControlLibraryBoolExp
        $orderBy: [ControlLibraryOrderBy!]
        $limit: Int
        $offset: Int
    ) {
        ControlLibrary(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
            id
            objective
            description
            owner
            createdAt
            updatedAt
            OrgsControls(distinctOn: orgId) {
                Org {
                    id
                    name
                }
            }
        }
        ControlLibraryAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);

const DeleteControlLibraryByPk = gql(/* GraphQL */ `
    mutation DeleteControlLibraryByPk($id: bigint!) {
        deleteControlLibraryByPk(id: $id) {
            id
        }
    }
`);

const InsertControls = gql(/* GraphQL */ `
    mutation InsertControls($objects: [ControlInsertInput!]!) {
        insertControl(objects: $objects) {
            affectedRows
        }
    }
`);
