import { ActionBar } from '@/components/Common/ActionBar';
import { observer } from 'mobx-react-lite';
import { ScrollToTopOnMount } from '@/components/Common/ScrollToTopOnMount';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, Switch } from 'antd';
import { ClaimFormState } from '@/hooks/Claim/useClaimFormState';
import { PublicClaimFormState } from '@/hooks/Claim/usePublicClaimFormState';
import { useNavigate } from '@/hooks/useNavigate';

interface Props {
    goBack: () => any;
    goNext: () => any;
    onSaveDraftOpen?: () => void;
    state: ClaimFormState | PublicClaimFormState;
    onSave: () => any;
    displaySaveButton?: boolean;
}

export const Conditions = observer(
    ({ state, goBack, goNext, onSave, onSaveDraftOpen, displaySaveButton = true }: Props) => {
        const navigate = useNavigate();

        return (
            <Form size="large">
                <ScrollToTopOnMount />
                <Card
                    title={
                        state.ui.questionnaireDeclarationTitle ||
                        'Please confirm the following with the insured or their representative'
                    }
                    className="crm-ant-card-flexible-header"
                >
                    <div className="flex justify-between">
                        <div className="pb-4 pr-2">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        state.ui.questionnaireDeclarationBody ||
                                        'Please confirm this claim is not the result of an illegal act',
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <Switch
                            checked={state.claim.jsonData?.claimNotResultOfIllegalAct || false}
                            onChange={(checked) => (state.claim.jsonData!.claimNotResultOfIllegalAct = checked)}
                        />
                    </div>
                </Card>
                <ActionBar
                    renderLeft={() => (
                        <Button
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                goBack();
                            }}
                            size="large"
                        >
                            Back
                        </Button>
                    )}
                    renderRight={() => (
                        <>
                            {state.ui.readOnly ? (
                                <Button onClick={() => navigate({ href: '/app/dashboard' })} size="large">
                                    Close
                                </Button>
                            ) : (
                                <>
                                    {!state.ui.readOnly && !!displaySaveButton && (
                                        <Button icon={<SaveOutlined />} onClick={onSave} size="large">
                                            Save
                                        </Button>
                                    )}
                                    {!state.ui.readOnly && onSaveDraftOpen && (
                                        <Button icon={<SaveOutlined />} onClick={onSaveDraftOpen} size="large">
                                            Save
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={goNext}
                                        size="large"
                                        disabled={!state.claim.jsonData!.claimNotResultOfIllegalAct}
                                    >
                                        Next
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                />
            </Form>
        );
    },
);
