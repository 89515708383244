import { Card, Col, Row } from 'antd';
import { useFormatter } from '@/hooks/useFormatter';
import { claimFormStore } from '../store';
import { VTextInput } from '@/components/Common/ViewForm/VTextInput';
import { VTextArea } from '@/components/Common/ViewForm/VTextArea';
import { VDropdown } from '@/components/Common/ViewForm/VDropdown';
import { InsurersByProductTypeId } from '@/graphql/queries/insurer';
import { useLazyQuery } from '@apollo/client';
import { VReadOnly } from '@/components/Common/ViewForm/VReadOnly';
import dayjs from 'dayjs';
import { createFileRoute } from '@tanstack/react-router';
import { VDatePicker } from '@/components/Common/ViewForm/VDatePicker';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/policy-info')({
    component: PolicyInfo,
});

function PolicyInfo() {
    const claim = claimFormStore.use.formData();
    const { formatDateTime, formatDate, fDateTimeDayJs, fDateShortDayJs } = useFormatter();
    const [getInsurersByProductTypeId] = useLazyQuery(InsurersByProductTypeId, {
        variables: {
            productTypeId: claim?.productTypeId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <Row>
            <Col span={16}>
                <Card title="Policy" size="small">
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <VDropdown
                                field="insurerId"
                                label="Insurer"
                                formStore={claimFormStore}
                                formatValueFn={(_, { Insurer }) => Insurer?.name}
                                loaderFn={async () => {
                                    const { data } = await getInsurersByProductTypeId();

                                    return (
                                        data?.Insurer.map(({ id, name }) => ({
                                            value: BigInt(id),
                                            label: name,
                                        })) || []
                                    );
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <VTextInput field="policyName" label="Policy Name" formStore={claimFormStore} />
                        </Col>

                        <Col span={12}>
                            <VTextInput field="policyNo" label="Policy Number" formStore={claimFormStore} />
                        </Col>
                        <Col span={12}>
                            <VReadOnly field="policyStatus" label="Status" formStore={claimFormStore} />
                        </Col>

                        <Col span={12}>
                            <VDatePicker
                                field="policyTermFrom"
                                label="Term From"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatDateTime(value)}
                                format={fDateTimeDayJs}
                                showTime
                                maxDate={claim.policyTermTo ? dayjs(claim.policyTermTo) : undefined}
                                rules={[
                                    {
                                        async validator(_, value: Date) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (claim.policyTermTo && claim.policyTermTo <= value) {
                                                throw new Error('Can not be later than “Term to” date');
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <VDatePicker
                                field="policyTermTo"
                                label="Term To"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatDateTime(value)}
                                format={fDateTimeDayJs}
                                showTime
                                minDate={claim.policyTermFrom ? dayjs(claim.policyTermFrom) : undefined}
                                rules={[
                                    {
                                        async validator(_, value: Date) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (claim.policyTermFrom && value <= claim.policyTermFrom) {
                                                throw new Error('Can not be earlier than “Term From” date');
                                            }
                                        },
                                    },
                                ]}
                                onSave={async (value, { save, getFieldValue }) => {
                                    void save({
                                        policyTermTo: value,
                                        ...(getFieldValue('policyIssuedOn') >= value && {
                                            policyIssuedOn: null,
                                        }),
                                    });
                                }}
                            />
                        </Col>

                        <Col span={12}>
                            <VDatePicker
                                field="policyIssuedOn"
                                label="Policy issue date"
                                formStore={claimFormStore}
                                formatValueFn={(value) => formatDate(value)}
                                format={fDateShortDayJs}
                                maxDate={claim.policyTermTo ? dayjs(claim.policyTermTo) : undefined}
                                rules={[
                                    {
                                        async validator(_, value: Date) {
                                            if (!value) {
                                                throw new Error('Field is required');
                                            }

                                            if (claim.policyTermTo && claim.policyTermTo <= value) {
                                                throw new Error('Can not be later than “Term to” date');
                                            }
                                        },
                                    },
                                ]}
                            />
                        </Col>

                        <Col span={24}>
                            <VTextArea field="policyOtherInfo" label="Other info" formStore={claimFormStore} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
