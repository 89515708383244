import { createFileRoute } from '@tanstack/react-router';
import { Result, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from '@/hooks/useNavigate';
import { useOrg } from '@/hooks/Org/useOrg';

export const Route = createFileRoute('/console/$orgId/configuration/billing/payment/success')({
    component: PaymentSuccessful,
});

function PaymentSuccessful() {
    const navigate = useNavigate();
    const org = useOrg();

    const handleBackToBilling = () => {
        navigate({
            to: '/console/$orgId/configuration/billing',
            params: { orgId: org.id },
        });
    };

    return (
        <div className="flex h-full items-center justify-center p-8">
            <Result
                icon={<CheckCircleFilled className="text-green-500" />}
                status="success"
                title="Payment Successful!"
                subTitle="Thank you for your payment. Your transaction has been completed successfully."
                extra={[
                    <Button type="primary" onClick={handleBackToBilling} key="back">
                        Back to Billing
                    </Button>,
                ]}
            />
        </div>
    );
}
