import { DatePicker } from '@/components/Common/DatePicker';
import { errorMessage } from '@/components/Common/errorMessage';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';
import { Link } from '@/components/Common/Link';
import { UserSelector } from '@/components/Common/UserSelector';
import { ProductSelector } from '@/components/Complaint/ProductSelector';
import { EmailDetailsAction, EmailDetailsDrawer } from '@/components/EmailClassification/EmailDetailsDrawer';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { useNavigate } from '@/hooks/useNavigate';
import { useUser } from '@/hooks/User/useUser';
import { useSafePath } from '@/hooks/useSafePath';
import { RouterInputs, trpc } from '@/trpc';
import { requiredFieldRule } from '@/utils/form.ts';
import { debounce } from '@/utils/general';
import { skipToken } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { App, Button, Card, Divider, Form, Input, message, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { z } from 'zod';

const IncidentAddSearchSchema = z.object({
    emailClassificationId: z.coerce.bigint().optional(),
});

export const Route = createFileRoute('/console/$orgId/compliance/incidents/add')({
    staticData: {
        metadata: {
            title: 'New incident',
        },
        ui: {
            variant: 'compact',
        },
    },
    validateSearch: zodValidator(IncidentAddSearchSchema),
    component: IncidentAdd,
});

type Incident = RouterInputs['incident']['createIncident'];

function IncidentAdd() {
    const { modal } = App.useApp();
    const user = useUser();
    const orgId = useOrgId();
    const { emailClassificationId } = Route.useSearch();
    const { fDateShortDayJs } = useFormatter();
    const { data: impactedProducts, loading: loadingProducts } = useConfigServiceLoader((configService) =>
        configService.fetchIncident_ProductsImpacted(),
    );
    const navigate = useNavigate();
    const [emailDetailsAction, setEmailDetailsAction] = useState<EmailDetailsAction>(['hide']);
    const [form] = Form.useForm<Incident>();
    const safePath = useSafePath<Incident>();
    const handlingPartyIds = Form.useWatch<Incident['handlingPartyIds']>(safePath('handlingPartyIds'), form);
    const reportedByOther = Form.useWatch<Incident['reportedByOther']>(safePath('reportedByOther'), form);
    const ownerId = Form.useWatch<Incident['ownerId']>(safePath('ownerId'), form);

    const { data: emailClassification } = trpc.emailClassification.getEmailClassification.useQuery(
        emailClassificationId ? { id: BigInt(emailClassificationId) } : skipToken,
        {
            throwOnError(error) {
                errorMessage.show(error);
                return false;
            },
        },
    );

    useEffect(() => {
        if (emailClassification) {
            const extractedData = emailClassification.extractedData || ({} as any);
            invariant(extractedData.issueType === 'incident', 'Email classification is not an incident');
            form.setFieldsValue({
                description: emailClassification.summary || extractedData.description || undefined,
            });
        }
    }, [emailClassification]);

    const { mutateAsync: createIncident, isPending: loading } = trpc.incident.createIncident.useMutation();

    const onFinish = debounce(async (values: Incident) => {
        try {
            void message.loading('Saving...');

            const { id } = await createIncident({
                isSignificant: false,
                ...values,
                emailClassificationId,
            });

            message.destroy();
            void message.success('Saved.');

            if (
                !user.isOrgAdmin &&
                !values.handlingPartyIds.some((item) => user.externalOrgIds.includes(Number(item)))
            ) {
                message.destroy();
                modal.warning({
                    content:
                        "Your incident has been logged, but it won't appear in your incident list because it's assigned to a Handling Party you don't have access to.",
                    onOk: () =>
                        navigate({
                            to: '/console/$orgId/compliance/incidents',
                            params: {
                                orgId,
                            },
                        }),
                });
                return;
            }

            navigate({
                to: '/console/$orgId/compliance/incidents/details/$id/$section',
                params: {
                    orgId,
                    id,
                    section: 'manage',
                },
            });
        } catch (e) {
            message.destroy();
            errorMessage.show(e);
        }
    });

    return (
        <>
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinish}
                onFinishFailed={(errorInfo) => {
                    form.scrollToField(errorInfo.errorFields[0].name);
                }}
                initialValues={{ reportedById: BigInt(user?.id) }}
                labelWrap
            >
                <Card>
                    {emailClassification && (
                        <div className="mb-4 flex justify-end">
                            <Button onClick={() => setEmailDetailsAction(['view', emailClassification.id])}>
                                View Incident Details
                            </Button>
                        </div>
                    )}
                    <Divider orientation="left">Incident</Divider>
                    <Form.Item
                        label="When did you become aware of the incident?"
                        name={safePath('dtIdentified')}
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                        ]}
                    >
                        <DatePicker className="w-full" format={fDateShortDayJs} maxDate={dayjs()} />
                    </Form.Item>
                    <Form.Item
                        label="Which product or service does it impact?"
                        name={safePath('productImpacted')}
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                        ]}
                    >
                        <Select
                            loading={loadingProducts}
                            options={impactedProducts?.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Describe what happened"
                        name={safePath('description')}
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                        ]}
                        required
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="Reported by"
                        required
                        dependencies={[safePath('reportedByOther')]}
                        name={safePath('reportedById')}
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value && !reportedByOther) throw new Error('Field is required');
                                },
                            },
                        ]}
                        validateTrigger={['onSelect']}
                    >
                        <UserSelector
                            showOtherUser
                            removeAnonymousAccount={false}
                            otherValue={reportedByOther || undefined}
                            onChangeOther={(value) => {
                                form.setFieldValue('reportedByOther', value);
                            }}
                            valueType="bigint"
                        />
                    </Form.Item>
                    <Form.Item name={safePath('reportedByOther')} hidden />
                    <Form.Item
                        label="Handling parties"
                        name={safePath('handlingPartyIds')}
                        required
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value?.length) {
                                        throw new Error('Field is required');
                                    }
                                },
                            },
                        ]}
                        getValueProps={(value) => value}
                    >
                        <HandlingPartySelect
                            placeholder=""
                            onChange={() => {
                                form.resetFields(['ownerId']);
                            }}
                            showAllOrgs
                        />
                    </Form.Item>
                    <Form.Item
                        label="Owner"
                        name={safePath('ownerId')}
                        required
                        rules={requiredFieldRule}
                        validateTrigger={['onSelect']}
                    >
                        <UserSelector value={ownerId} handlingPartyIds={handlingPartyIds} valueType="bigint" />
                    </Form.Item>
                    <Form.Item label="Products" name={safePath('products')}>
                        <ProductSelector />
                    </Form.Item>
                    <Form.Item label="Policy#" name={safePath('policyNo')}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Claim#" name={safePath('externalClaimId')}>
                        <Input />
                    </Form.Item>
                </Card>
                <Space className="mt-2 flex justify-end">
                    <Link
                        to="/console/$orgId/compliance/incidents"
                        params={{
                            orgId,
                        }}
                    >
                        Cancel
                    </Link>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Space>
            </Form>

            <EmailDetailsDrawer
                action={emailDetailsAction}
                onClose={() => setEmailDetailsAction(['hide'])}
                title="Incident Details - Email"
            />
        </>
    );
}
