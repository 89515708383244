import { claimFormStore } from '../store';
import { createFileRoute } from '@tanstack/react-router';
import { History as HistoryOriginal } from '@/components/Common/History';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/history')({
    component: History,
});

function History() {
    const claim = claimFormStore.use.formData();

    return <HistoryOriginal idPropertyName="claimId" idValue={Number(claim.id)} />;
}
