import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { useFormatter } from '@/hooks/useFormatter';
import { useTrpc } from '@/hooks/useTrpc';
import { useZodState } from '@/hooks/useZodState';
import { RouterOutputs } from '@/trpc';
import type { TableColumnType } from '@/types/common';
import { createFileRoute } from '@tanstack/react-router';
import { Table, Tag, Typography } from 'antd';
import { Decimal } from 'decimal.js';
import { DateTime } from 'luxon';
import { listWalletTransactionsSchema } from 'server/schemas/walletTransaction';

const { Text } = Typography;

export const Route = createFileRoute('/console/$orgId/configuration/billing/transactions')({
    component: Transactions,
});

type Transaction = RouterOutputs['billing']['listWalletTransactions']['rows'][number];

function Transactions() {
    const orgId = useOrgId();
    const { trpc } = useTrpc();
    const [queryParams, setQueryParams] = useZodState(listWalletTransactionsSchema, {
        where: { orgId },
    });
    const { fDateTimeLxn } = useFormatter();

    // Setup transactions query
    const { data: transactionsData, isLoading: transactionsLoading } =
        trpc.billing.listWalletTransactions.useQuery(queryParams);

    const columns: TableColumnType<Transaction>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: bigint) => <Text>{id.toString()}</Text>,
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: true,
            render: (type: string) => <Tag color="blue">{type}</Tag>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            render: (amount: Decimal) => (
                <Text strong type={amount.isNegative() ? 'danger' : 'success'}>
                    {amount.isNegative() ? '-' : '+'}${Math.abs(Number(amount)).toFixed(2)}
                </Text>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (date: Date) => <Text type="secondary">{DateTime.fromJSDate(date).toFormat(fDateTimeLxn)}</Text>,
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            render: (note: string | null) => <Text>{note || 'N/A'}</Text>,
        },
    ];

    const { tableProps } = useAntdTable({
        columns,
        rowKey: 'id',
        data: {
            total: transactionsData?.total ?? 0,
            rows: transactionsData?.rows ?? [],
            loading: transactionsLoading,
        },
        paginationConfig: {
            defaultPageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: true,
        },
        onQueryVariableChange: (options) => {
            setQueryParams({
                ...queryParams,
                ...options,
            });
        },
    });

    return <Table {...tableProps} className="shadow-sm" scroll={{ x: 500 }} size="small" />;
}
