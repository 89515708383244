import { Select, SelectProps } from 'antd';
import { OUTCOMES } from 'shared/constants/complaint';

type Props = Pick<SelectProps<(string | undefined)[]>, 'value' | 'onChange' | 'disabled' | 'className'>;

export const OutcomeSelector = ({ value, ...props }: Props) => (
    <Select
        value={value || []}
        options={OUTCOMES.map(({ label }) => ({ value: label }))}
        mode="multiple"
        allowClear
        maxCount={3}
        {...props}
    />
);
