import { Col, Row } from 'antd';
import { Reserves } from './Reserves';
import { ReservesPayments } from './ReservesPayments';
import { Excess } from '@/components/Claim/Finance/Excess';
import { Recovery } from '@/components/Claim/Finance/Recovery';
import { Reserve } from '@/components/Claim/Finance/Reserve';
import { IncurredCost } from '@/components/Claim/Finance/IncurredCost';
import { FinancialContext, FinancialSignals } from './FinancialContext';
import { useCreateSignalStore } from '@/context/signal';
import { trpc } from '@/trpc';
import { claimFormStore } from '../store';
import { useEffect } from 'react';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/financials')({
    component: Finance,
});

function Finance() {
    const signalStore = useCreateSignalStore<FinancialSignals>();
    const claim = claimFormStore.use.formData();
    const { data, isPending, refetch } = trpc.claim.getFinancialSummary.useQuery({
        claimId: claim.id,
    });

    useEffect(() => {
        void refetch();
    }, [
        signalStore.signals.paymentUpdated,
        signalStore.signals.reserveUpdated,
        claim.recovery,
        claim.excess,
    ]);

    return (
        <FinancialContext.Provider value={signalStore}>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <IncurredCost
                        loading={isPending}
                        reserve={data?.reserve || 0}
                        recovery={data?.recovery || 0}
                        excess={data?.excess || 0}
                    />
                </Col>
                <Col span={6}>
                    <Reserve
                        reserve={data?.reserve || 0}
                        paid={data?.paid || 0}
                        loading={isPending}
                    />
                </Col>
                <Col span={6}>
                    <Excess />
                </Col>
                <Col span={6}>
                    <Recovery />
                </Col>
                <Col span={24}>
                    <Reserves />
                </Col>
                <Col span={24}>
                    <ReservesPayments />
                </Col>
            </Row>
        </FinancialContext.Provider>
    );
}
