import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/organizations')({
    staticData: {
        breadcrumb: {
            title: 'Organizations',
        },
    },
    component: Outlet,
});
