import { TrainingRecordEdit } from '@/components/Training/TrainingRecordEdit';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/training/user/$userId/edit/$recordId')({
    params: {
        parse({ recordId }) {
            return {
                recordId: BigInt(recordId),
            };
        },
    },
    component() {
        const { recordId } = Route.useParams();

        return <TrainingRecordEdit recordId={recordId} />;
    },
});
