import { CHART_CONFIG } from '@/chartConfig';
import { Chart } from '@/components/Chart';
import { Dashboard } from '@/components/Dashboard';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/tools/ai-inbox/dashboard')({
    staticData: {
        breadcrumb: {
            title: 'Dashboard',
        },
    },
    component: ComplianceAIInboxDashboard,
});

function ComplianceAIInboxDashboard() {
    const loading = false;
    const data1 = [
        { type: 'Emails scanned', count: 100 },
        { type: 'Issues identified', count: 200 },
    ];
    const data2 = [
        { month: 'January', count: 1, type: 'Incidents' },
        { month: 'February', count: 12, type: 'Incidents' },
        { month: 'March', count: 22, type: 'Incidents' },
        { month: 'April', count: 32, type: 'Complaints' },
        { month: 'May', count: 42, type: 'Complaints' },
        { month: 'May', count: 21, type: 'Incidents' },
        { month: 'June', count: 52, type: 'Incidents' },
        { month: 'July', count: 62, type: 'Incidents' },
        { month: 'August', count: 72, type: 'Complaints' },
        { month: 'September', count: 82, type: 'Incidents' },
        { month: 'October', count: 92, type: 'Incidents' },
        { month: 'November', count: 102, type: 'Complaints' },
        { month: 'December', count: 112, type: 'Incidents' },
    ];
    const data3 = [
        { month: 'July', type: 'Emails scanned', value: 2 },
        { month: 'July', type: 'Potential issues', value: 3 },
        { month: 'July', type: 'Issues confirmed', value: 1 },
        { month: 'August', type: 'Emails scanned', value: 4 },
        { month: 'August', type: 'Potential issues', value: 12 },
        { month: 'August', type: 'Issues confirmed', value: 23 },
        { month: 'September', type: 'Emails scanned', value: 12 },
        { month: 'September', type: 'Potential issues', value: 3 },
        { month: 'September', type: 'Issues confirmed', value: 34 },
        { month: 'October', type: 'Emails scanned', value: 9 },
        { month: 'October', type: 'Potential issues', value: 12 },
        { month: 'October', type: 'Issues confirmed', value: 2 },
        { month: 'November', type: 'Emails scanned', value: 9 },
        { month: 'November', type: 'Potential issues', value: 2 },
        { month: 'November', type: 'Issues confirmed', value: 3 },
        { month: 'December', type: 'Emails scanned', value: 1 },
        { month: 'December', type: 'Potential issues', value: 4 },
        { month: 'December', type: 'Issues confirmed', value: 4 },
        { month: 'January', type: 'Emails scanned', value: 5 },
        { month: 'January', type: 'Potential issues', value: 4 },
        { month: 'January', type: 'Issues confirmed', value: 4 },
    ];

    return (
        <Dashboard.Row>
            <Dashboard.Item title="Today" colSpan={12}>
                <Chart
                    loading={loading}
                    height={250}
                    items={[
                        {
                            type: 'interval',
                            data: data1 || [],
                            coordinate: { type: 'theta', outerRadius: 1.05 },
                            transform: [{ type: 'stackY' }],
                            encode: { y: 'count', color: 'type' },
                            legend: {
                                color: {
                                    position: 'right',
                                    layout: { justifyContent: 'center' },
                                },
                            },
                            labels: [
                                {
                                    position: 'inside',
                                    text: 'count',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                },
                            ],
                            style: {
                                stroke: CHART_CONFIG.colors.colorMapping.white,
                            },
                            interaction: {
                                elementHighlight: true,
                            },
                        },
                    ]}
                />
            </Dashboard.Item>
            <Dashboard.Item title="Emails by month" colSpan={12}>
                <Chart
                    loading={loading}
                    height={250}
                    items={[
                        {
                            type: 'interval',
                            data: data3 || [],
                            encode: { x: 'month', y: 'value', color: 'type' },
                            transform: [{ type: 'stackY' }],
                            axis: {
                                x: { title: null },
                                y: { title: null },
                            },
                            legend: {
                                color: {
                                    position: 'bottom',
                                    layout: { justifyContent: 'center' },
                                },
                            },
                            style: {
                                radius: CHART_CONFIG.charts.interval.style.radius,
                                maxWidth: CHART_CONFIG.charts.interval.style.maxWidth,
                            },
                        },
                    ]}
                />
            </Dashboard.Item>
            <Dashboard.Item title="Confirmed issues by month" colSpan={12}>
                <Chart
                    loading={loading}
                    height={250}
                    items={[
                        {
                            type: 'line',
                            data: data2 || [],
                            encode: { x: 'month', y: 'count', color: 'type' },
                            axis: {
                                x: { title: null },
                                y: { title: null },
                            },
                            scale: {
                                color: {
                                    range: Object.values(CHART_CONFIG.colors.colorMapping).slice(2, 4),
                                },
                            },
                            style: {
                                lineWidth: CHART_CONFIG.charts.line.style.lineWidth,
                            },
                            legend: {
                                color: {
                                    position: 'top',
                                    layout: { justifyContent: 'center' },
                                },
                            },
                        },
                        {
                            type: 'point',
                            data: data2 || [],
                            encode: { x: 'month', y: 'count', color: 'type', shape: 'circle' },
                            tooltip: null,
                        },
                    ]}
                />
            </Dashboard.Item>
        </Dashboard.Row>
    );
}
