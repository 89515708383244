import { gql } from '@/__generated__';
import { ArrayInput } from '@/components/Common/ArrayInput';
import { errorMessage } from '@/components/Common/errorMessage';
import { HandlingPartySelect } from '@/components/Common/HandlingPartySelect';
import { Link } from '@/components/Common/Link';
import { RichTextInput } from '@/components/Common/RichTextInput';
import { User } from '@/components/Common/User';
import { UserSelector } from '@/components/Common/UserSelector';
import { BreachExamples } from '@/components/Obligation/BreachExamples';
import { ControlsTable } from '@/components/Obligation/ControlsTable';
import { useConfigServiceLoader } from '@/hooks/Configuration/useConfigServiceLoader';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { useSafePath } from '@/hooks/useSafePath';
import { requiredFieldRule } from '@/utils/form';
import { grey } from '@ant-design/colors';
import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { App, Button, Card, Col, Drawer, Form, Input, InputNumber, Row, Select, Table } from 'antd';
import { useState } from 'react';
import { OBLIGATION_STATUSES } from 'shared/constants/obligation';
import { ObligationStatus } from 'shared/types/obligation';
import { BreachExamplesProvider } from '@/components/Obligation/BreachExamples/BreachExamplesContext';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { createFileRoute } from '@tanstack/react-router';
import { ComponentPreview } from '@/components/Common/ComponentPreview';

export const Route = createFileRoute('/console/$orgId/compliance/obligations/add')({
    staticData: {
        breadcrumb: {
            title: 'New obligation',
        },
    },
    component: ObligationAdd,
});

type ObligationInput = RouterInputs['obligation']['createObligation'];
type Control = RouterOutputs['control']['listControls']['rows'][number];

function ObligationAdd() {
    const orgId = useOrgId();
    const { message } = App.useApp();
    const navigate = useNavigate();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [obligationControls, setObligationControls] = useState<Control[]>([]);
    const [breachExamples, setBreachExamples] = useState<string[]>([]);

    const { mutateAsync: createObligation, isPending: createLoading } = trpc.obligation.createObligation.useMutation();

    const { data: countries, loading: countriesLoading } = useConfigServiceLoader((service) =>
        service.fetchCountryList(),
    );
    const { data: legislationItems, isPending: legislationsLoading } = trpc.legislation.listLegislationItems.useQuery({
        orderBy: { id: 'asc' },
    });

    const [form] = Form.useForm<ObligationInput>();
    const safePath = useSafePath<ObligationInput>();
    const legislationId = Form.useWatch('legislationId', form);

    const { loading } = useQuery(GetLastObligationOrder, {
        variables: { orgId },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            form.setFieldsValue({
                order: (data?.Obligation?.[0]?.order || 0) + 1,
            });
        },
    });

    const onFinish = async (values: ObligationInput) => {
        try {
            message.info('Saving...');

            const { id } = await createObligation({
                ...values,
                breachExamples,
                controlIds: obligationControls.map(({ id }) => id),
            });

            message.destroy();
            message.success('Saved.');

            navigate({
                to: '/console/$orgId/compliance/obligations/details/$id/$section',
                params: {
                    id,
                    orgId,
                    section: 'manage',
                },
            });
        } catch (e) {
            errorMessage.show(e);
        }
    };

    const handleSelectControls = (controls: Control[]) => {
        const selectedControlIds = controls.map((control) => control?.id);
        const updatedObligationControls = [
            ...obligationControls.filter((control) => selectedControlIds.includes(control?.id)),
            ...controls.filter((control) => !obligationControls.some((c) => c.id === control?.id)),
        ];

        setObligationControls(updatedObligationControls);
        setDrawerVisible(false);
    };

    return (
        <>
            <Drawer
                title="Select Controls"
                width={900}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                destroyOnClose
            >
                <ControlsTable
                    onSelect={handleSelectControls}
                    onCancel={() => setDrawerVisible(false)}
                    selectedControls={obligationControls}
                />
            </Drawer>

            <Form form={form} id="obligationEdit" layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title="New Obligation" loading={loading}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item label="ID" name={safePath('order')}>
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Identification code"
                                                name={safePath('identificationCode')}
                                            >
                                                <Input
                                                    prefix={
                                                        legislationItems?.rows?.find(
                                                            (item) => item.id === legislationId,
                                                        )?.idPrefix
                                                    }
                                                    className="w-full"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Status"
                                                name={safePath('status')}
                                                rules={requiredFieldRule}
                                            >
                                                <Select
                                                    options={Object.keys(OBLIGATION_STATUSES).map((key) => ({
                                                        label: OBLIGATION_STATUSES[key as ObligationStatus],
                                                        value: key,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item label="Obligation owner" name={safePath('ownerId')}>
                                                <UserSelector valueType="bigint" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Country"
                                                name={safePath('country')}
                                                rules={requiredFieldRule}
                                            >
                                                <Select
                                                    loading={countriesLoading}
                                                    showSearch
                                                    options={countries?.map((item) => ({
                                                        label: item,
                                                        value: item,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Applies to"
                                                name={safePath('handlingPartyIds')}
                                                getValueProps={(value) => value}
                                            >
                                                <HandlingPartySelect placeholder="" showAllOrgs />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Legislation"
                                                name={safePath('legislationId')}
                                                rules={requiredFieldRule}
                                            >
                                                <Select
                                                    loading={legislationsLoading}
                                                    showSearch
                                                    options={legislationItems?.rows?.map(({ name, id }) => ({
                                                        label: name,
                                                        value: id,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="Core obligation"
                                                name={safePath('coreObligation')}
                                                rules={requiredFieldRule}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="What it means" name={safePath('whatItMeans')}>
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="How we are complying"
                                                name={safePath('howWeAreComplying')}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title="Source of obligation">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Sub obligation / section / paragraph"
                                                name={safePath('section')}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Source" name={safePath('source')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Exact wording from the legislation"
                                                name={safePath('legislationDescription')}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Austlii link">
                                                <ArrayInput
                                                    name={safePath('austLIIURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [
                                                                ...requiredFieldRule,
                                                                {
                                                                    type: 'url',
                                                                },
                                                            ],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Federal Register of Legislation link">
                                                <ArrayInput
                                                    name={safePath('federalRegisterOfLegislationURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [
                                                                ...requiredFieldRule,
                                                                {
                                                                    type: 'url',
                                                                },
                                                            ],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title="Regulatory guidance">
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Regulatory guidance"
                                                name={safePath('regulatoryGuidance')}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Regulator" name={safePath('regulator')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Exact wording from the Regulatory guide"
                                                name={safePath('regulatoryGuidanceDescription')}
                                            >
                                                <RichTextInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Links">
                                                <ArrayInput
                                                    name={safePath('regulatoryGuidanceURLs')}
                                                    fields={[
                                                        {
                                                            label: 'Name',
                                                            name: 'name',
                                                            children: <Input placeholder="Name" />,
                                                        },
                                                        {
                                                            label: 'URL',
                                                            name: 'url',
                                                            rules: [
                                                                ...requiredFieldRule,
                                                                {
                                                                    type: 'url',
                                                                },
                                                            ],
                                                            children: <Input placeholder="URL" />,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <BreachExamplesProvider
                                    saveField={(value) => {
                                        setBreachExamples(value);
                                    }}
                                >
                                    <BreachExamples breachExamples={breachExamples || []} />
                                </BreachExamplesProvider>
                            </Col>
                            <Col span={24} className="flex justify-between">
                                <Link
                                    to="/console/$orgId/compliance/obligations"
                                    params={{ orgId }}
                                    icon={<ArrowLeftOutlined />}
                                >
                                    Back
                                </Link>
                                <Button type="primary" htmlType="submit" form="obligationEdit" loading={createLoading}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card
                                    title="Key controls"
                                    styles={{ body: { padding: 10 } }}
                                    extra={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                                setDrawerVisible(true);
                                            }}
                                        >
                                            Add control
                                        </Button>
                                    }
                                >
                                    <Table
                                        size="small"
                                        dataSource={obligationControls}
                                        scroll={{ x: 'max-content' }}
                                        columns={[
                                            {
                                                title: 'No.',
                                                dataIndex: 'id',
                                                sorter: true,
                                                width: 40,
                                                render: String,
                                            },
                                            {
                                                title: 'Objective',
                                                dataIndex: 'objective',
                                                render: (value) => <ComponentPreview>{value}</ComponentPreview>,
                                            },
                                            {
                                                title: 'Owner',
                                                dataIndex: 'ownerId',
                                                render: (value) => <User userId={value} />,
                                            },
                                            {
                                                render: (_, record) => (
                                                    <Button
                                                        type="text"
                                                        icon={<DeleteOutlined style={{ color: grey.primary }} />}
                                                        onClick={() => {
                                                            setObligationControls(
                                                                obligationControls.filter(
                                                                    (control) => control.id !== record.id,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                ),
                                            },
                                        ]}
                                        pagination={false}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const GetLastObligationOrder = gql(/* GraphQL */ `
    query GetLastObligationOrder($orgId: bigint!) {
        Obligation(orderBy: [{ order: DESC }], where: { orgId: { _eq: $orgId } }, limit: 1) {
            order
        }
    }
`);
