import { gql } from '@/__generated__';
import { CommChannelEnum, CommDirectionEnum, CommLogByPkQuery } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { User as UserComponent } from '@/components/Common/User';
import { SetNotificationAsViewed } from '@/graphql/mutations/notification';
import { FileDs } from '@/graphql/queries/file';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useFormatter } from '@/hooks/useFormatter';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { formatEmailAddresses } from '@/utils/comm';
import { textToHtml } from '@/utils/text';
import { gold } from '@ant-design/colors';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Modal, Skeleton, Space, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { FileIcon } from 'react-file-icon';
import { fileExtension } from 'shared/utils/file';
import { useNavigate } from '@/hooks/useNavigate';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/claims/details/$id/comms/message/$messageId')({
    component: MessageView,
});

type CommLog = NonNullable<CommLogByPkQuery['CommLogByPk']>;

function MessageView() {
    const orgId = useOrgId();
    const trpcClient = useTrpcClient();
    const { messageId } = Route.useParams();
    const [getCommLogByPk] = useLazyQuery(CommLogByPk);
    const [setNotificationAsViewed] = useMutation(SetNotificationAsViewed);
    const navigate = useNavigate();
    const { formatISODateTime } = useFormatter();

    const [loading, setLoading] = useState(false);
    const [log, setLog] = useState<CommLog>();
    const [open, setOpen] = useState(true);

    const { data, loading: loadingAttachments } = useQuery(FileDs, {
        fetchPolicy: 'network-only',
        variables: {
            where: { id: { _in: log?.attachments } },
            limit: 100,
            offset: 0,
        },
        skip: !log,
    });

    const markAsRead = async () => {
        if (!log) {
            return;
        }

        try {
            if (log.Notification?.isActive === true) {
                const { data } = await setNotificationAsViewed({
                    variables: { orgId, id: log.Notification.id },
                    fetchPolicy: 'network-only',
                });
                log.Notification.isActive = !!data?.updateNotificationByPk!.isActive;
            }
        } catch (e) {
            errorMessage.show(e);
        }
    };

    useEffect(() => {
        if (!messageId) {
            return;
        }

        async function asyncWrapper() {
            try {
                setLoading(true);

                const { data } = await getCommLogByPk({
                    variables: {
                        id: messageId,
                        orgId,
                    },
                    fetchPolicy: 'network-only',
                });

                if (data?.CommLogByPk) {
                    setLog(data.CommLogByPk);
                    markAsRead();
                }
            } catch (e) {
                errorMessage.show(e);
            } finally {
                setLoading(false);
            }
        }

        void asyncWrapper();
    }, [messageId]);

    if (!log) {
        return;
    }

    return (
        <Modal
            open={open}
            width={1200}
            footer={false}
            styles={{ body: { minHeight: '500px' } }}
            onCancel={() => {
                setOpen(false);

                navigate({
                    to: '/console/$orgId/claims/details/$id/comms',
                    params: {
                        orgId,
                        id: log.claimId,
                    },
                    fromComponent: 'drawer',
                });
            }}
        >
            {loading ? (
                <Skeleton active />
            ) : (
                <div className="m-4">
                    {log.channel === CommChannelEnum.email && (
                        <>
                            <Space className="flex justify-between">
                                <div>
                                    <div className="flex">
                                        <div>
                                            <Typography.Text strong>From: </Typography.Text>
                                            <Typography.Text className="mr-1">{log.from}</Typography.Text>
                                        </div>
                                        {log.fromId && (
                                            <>
                                                (
                                                <UserComponent userId={log.fromId} showAvatar={false} />)
                                            </>
                                        )}
                                    </div>
                                    <Typography.Text strong>To: </Typography.Text>
                                    <Typography.Text>{formatEmailAddresses(log.to)}</Typography.Text>
                                    <br />
                                    <Typography.Text strong>Subject: </Typography.Text>
                                    <Typography.Text>{log.subject}</Typography.Text>
                                </div>
                                <div className="text-right">
                                    {Array.isArray(log.tags) &&
                                        log.tags.map((tag) => (
                                            <Tag className="m-1" key={tag}>
                                                {tag}
                                            </Tag>
                                        ))}
                                </div>
                            </Space>

                            <Divider className="mb-1 mt-1" />
                            <Space className="flex justify-end">
                                <Typography.Text className="flex text-right" disabled>
                                    {formatISODateTime(log.createdAt)}
                                </Typography.Text>
                            </Space>

                            <Typography.Paragraph className="mt-1">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: textToHtml(
                                            log.direction === CommDirectionEnum.inbound
                                                ? log.contentSanitized
                                                : log.content,
                                        ),
                                    }}
                                />
                                {loadingAttachments ? (
                                    <p style={{ color: gold.primary }}>Loading attachments...</p>
                                ) : (
                                    data?.Files.map((file) => {
                                        return (
                                            <div className="flex" key={file.id}>
                                                <Button
                                                    onClick={async (e) => {
                                                        try {
                                                            e.preventDefault();
                                                            const url = await trpcClient.file.getDownloadUrl.query({
                                                                key: file.key,
                                                            });
                                                            window.open(url, '_blank');
                                                        } catch (e) {
                                                            errorMessage.show(e);
                                                        }
                                                    }}
                                                    target="_blank"
                                                    type="link"
                                                >
                                                    <div className="flex items-center">
                                                        <div style={{ width: '20px' }} className="mr-2">
                                                            <FileIcon
                                                                extension={fileExtension(file.name)}
                                                                color="#f8b500"
                                                            />
                                                        </div>
                                                        {file.name}
                                                    </div>
                                                </Button>
                                            </div>
                                        );
                                    })
                                )}
                            </Typography.Paragraph>
                        </>
                    )}
                    {log.channel === CommChannelEnum.sms && (
                        <Typography.Paragraph>{log.content || ''}</Typography.Paragraph>
                    )}
                </div>
            )}
        </Modal>
    );
}

const CommLogByPk = gql(/* GraphQL */ `
    query CommLogByPk($id: bigint!, $orgId: bigint!) {
        CommLogByPk(id: $id, orgId: $orgId) {
            to
            subject
            id
            claimId
            from
            direction
            createdAt
            content
            channel
            cc
            bcc
            attachments
            tags
            contentSanitized
            duration
            fromId
            Notification {
                id
                isActive
            }
        }
    }
`);
