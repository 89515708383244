import { DocumentLibrary } from '@/components/Common/Documents/DocumentLibrary';
import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { App, Skeleton, Tabs } from 'antd';
import { COI_DETAILS_SECTIONS, CoiDetailsSection } from 'shared/navigation/navRoutes';
import { CoiDetailsManage } from './CoiDetailsManage';
import { useNavigate } from '@/hooks/useNavigate';
import { trpc } from '@/trpc';
import { conflictOfInterestFormStore } from './store';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/compliance/coi/details/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Conflict of interest details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as CoiDetailsSection,
            };
        },
    },
    component: CoiDetails,
});

function CoiDetails() {
    const { message } = App.useApp();
    const orgId = useOrgId();
    const { id, section } = Route.useParams();
    const trpcClient = useTrpcClient();
    const updateConflictOfInterest = trpc.conflictOfInterest.updateConflictOfInterest.useMutation();
    const navigate = useNavigate();

    const { formData: conflictOfInterest } = conflictOfInterestFormStore.useInit({
        entity: 'conflict of interest',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                await updateConflictOfInterest.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.conflictOfInterest.getConflictOfInterest.query({
                    id,
                });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(e) {
            errorMessage.show(e);
        },
    });

    return (
        <>
            {id === conflictOfInterest?.id ? (
                <Tabs
                    type="line"
                    activeKey={section}
                    onTabClick={(key) => {
                        navigate({
                            to: '/console/$orgId/compliance/coi/details/$id/$section',
                            params: {
                                orgId,
                                id,
                                section: key as CoiDetailsSection,
                            },
                        });
                    }}
                    items={[
                        {
                            key: COI_DETAILS_SECTIONS.MANAGE,
                            label: 'Manage',
                            children: <CoiDetailsManage />,
                        },
                        {
                            key: COI_DETAILS_SECTIONS.LIBRARY,
                            label: 'Library',
                            children: (
                                <DocumentLibrary
                                    entityId={Number(conflictOfInterest.id)}
                                    entityType="conflictOfInterest"
                                />
                            ),
                        },
                    ]}
                />
            ) : (
                <Skeleton active />
            )}
        </>
    );
}
