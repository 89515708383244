import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CardWrapper } from '@/components/Claim/Questionnaire/CardWrapper';
import { errorMessage } from '@/components/Common/errorMessage';
import { Card, Skeleton } from 'antd';
import { QuestionnaireContext } from '@/components/Claim/Questionnaire/QuestionnaireContext';
import { QuestionnaireService } from '@/services/QuestionnaireService';
import { ClaimFormState } from '@/hooks/Claim/useClaimFormState';
import { PublicClaimFormState } from '@/hooks/Claim/usePublicClaimFormState';

interface Props {
    goBack: () => any;
    goNext: () => any;
    onSaveDraftOpen?: () => void;
    service: QuestionnaireService;
    state: ClaimFormState | PublicClaimFormState;
    onSave: () => any;
    displaySaveButton?: boolean;
    isPublic?: boolean;
    isInitialized?: boolean;
}

export const Questionnaire = observer(
    ({
        goBack,
        goNext,
        onSaveDraftOpen,
        service,
        state,
        onSave,
        displaySaveButton = true,
        isPublic = false,
        isInitialized = true,
    }: Props) => {
        useEffect(() => {
            async function loadInitialData() {
                if (!isInitialized || service.root) return;

                try {
                    const loaded = await service.loadNext();
                    if (!loaded) {
                        errorMessage.show(`The root node can't be found.`);
                    }
                } catch (e) {
                    errorMessage.show(e);
                }
            }

            void loadInitialData();
        }, [isInitialized, service.root]);

        const proceedToNextCard = async () => {
            try {
                service.validateCurrentCard();
                if (service.isCurrentCardValid) {
                    const nextFound = await service.loadNext();
                    if (!nextFound) {
                        if (!service.root) {
                            errorMessage.show('No card was found.');
                        } else {
                            goNext();
                        }
                    } else {
                        if (service.currentPathSeqNo === 1) {
                            state.ui.activeStep++;
                        }
                    }
                }
            } catch (err) {
                errorMessage.show(err);
            }
        };

        const goToPrevCard = () => {
            if (service.currentCard.seqNo <= 0) {
                goBack();
            } else {
                if (service.currentPathSeqNo === 1) {
                    state.ui.activeStep--;
                }

                service.goToPreviousCard();
            }
        };

        return (
            <QuestionnaireContext.Provider value={{ isPublic }}>
                <>
                    {service.isLoading ? (
                        <Card>
                            <Skeleton active />
                        </Card>
                    ) : (
                        <>
                            {service.currentCard && (
                                <CardWrapper
                                    card={service.currentCard}
                                    proceedToNextCard={proceedToNextCard}
                                    goToPrevCard={goToPrevCard}
                                    onSave={onSave}
                                    readOnly={state.ui.readOnly}
                                    service={service}
                                    displaySaveButton={displaySaveButton}
                                    onSaveDraftOpen={onSaveDraftOpen}
                                />
                            )}
                        </>
                    )}
                </>
            </QuestionnaireContext.Provider>
        );
    },
);
