import { gql } from '@/__generated__';
import { useState } from 'react';
import { CommTemplateAdminDsQueryVariables, OrderBy } from '@/__generated__/graphql';
import { errorMessage } from '@/components/Common/errorMessage';
import { useQuery } from '@apollo/client';
import { useAntdTableOld } from '@/hooks/Table/useAntdTableOld';
import { Button, Card, Input, Space, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CommTemplateAction, CommTemplateEdit } from './CommTemplateEdit';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/comm-templates')({
    staticData: {
        ui: {
            title: 'Communication templates',
        },
    },
    component: CommTemplates,
});

function CommTemplates() {
    const [action, setAction] = useState<CommTemplateAction>(['none']);

    const [variables, setVariables] = useState<CommTemplateAdminDsQueryVariables>({
        where: { orgId: { _eq: -1 } },
        orderBy: [{ createdAt: OrderBy.desc }],
        limit: 10,
        offset: 0,
    });

    const { data, loading, refetch } = useQuery(CommTemplateAdminDs, {
        variables,
        onError: (error) => errorMessage.show(error),
    });

    const { tableProps } = useAntdTableOld({
        rowKey: 'id',
        paginationConfig: {
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showSizeChanger: true,
        },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                ...options,
            });
        },
        columns: [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
            },
            {
                title: 'Internal Name',
                dataIndex: 'internalName',
                key: 'internalName',
                sorter: true,
            },
            {
                title: 'Channel',
                dataIndex: 'channel',
                key: 'channel',
            },
            {
                title: 'Related To',
                dataIndex: 'relatedTo',
                key: 'relatedTo',
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                render: (value) => new Date(value).toLocaleString(),
            },
        ],
        data: {
            rows: data?.CommTemplate || [],
            total: data?.CommTemplateAggregate?.aggregate?.count || 0,
            loading,
        },
    });

    return (
        <>
            <Space className="mb-4 flex justify-between">
                <Input.Search
                    placeholder="Search templates"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={(value) => {
                        setVariables({
                            ...variables,
                            where: {
                                ...variables.where,
                                _or: [{ name: { _ilike: `%${value}%` } }, { internalName: { _ilike: `%${value}%` } }],
                            },
                        });
                    }}
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setAction(['add']);
                    }}
                >
                    New template
                </Button>
            </Space>

            <CommTemplateEdit
                action={action}
                onClose={() => {
                    setAction(['none']);
                    void refetch();
                }}
            />

            <Card>
                <Table
                    {...tableProps}
                    size="small"
                    rowClassName="cursor-pointer"
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                setAction(['edit', record.id]);
                            },
                        };
                    }}
                />
            </Card>
        </>
    );
}

const CommTemplateAdminDs = gql(/* GraphQL */ `
    query CommTemplateAdminDs(
        $where: CommTemplateBoolExp
        $orderBy: [CommTemplateOrderBy!]
        $limit: Int
        $offset: Int
    ) {
        CommTemplate(offset: $offset, limit: $limit, orderBy: $orderBy, where: $where) {
            id
            name
            orgId
            channel
            relatedTo
            subjectTemplate
            internalName
            createdAt
            template
        }
        CommTemplateAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`);
