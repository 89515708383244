import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useOrgId } from '@/hooks/Org/useOrgId';

export const Route = createFileRoute('/console/$orgId/configuration/')({
    component() {
        const orgId = useOrgId();

        return <Navigate to="/console/$orgId/configuration/system" params={{ orgId }} />;
    },
});
