import { isEmptyObject } from '@/utils/general';
import { ApolloError } from '@apollo/client';
import { TRPCClientError } from '@trpc/client';
import { HookAPI } from 'antd/es/modal/useModal';
import React from 'react';

function hasMessage(error: unknown): error is { message: string } {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as any).message === 'string'
    );
}

class ErrorMessage {
    modalApi?: HookAPI;

    init(modalApi: HookAPI) {
        this.modalApi = modalApi;
    }

    private renderErrorMessage(error: unknown): JSX.Element {
        if (!error) {
            return <div>Unknown error</div>;
        }

        if (error instanceof ApolloError) {
            return this.renderErrorMessage(extractApolloErrorMessages(error));
        }

        if (Array.isArray(error)) {
            if (error.length === 0) {
                return <div>Unknown error</div>;
            } else if (error.length === 1) {
                return this.renderErrorMessage(error[0]);
            } else {
                return (
                    <div>
                        {error.map((e, idx) => (
                            <div key={idx}>{this.renderErrorMessage(e)}</div>
                        ))}
                    </div>
                );
            }
        }

        if (React.isValidElement(error)) {
            return error;
        }
        if (error instanceof TRPCClientError) {
            if (error.data && 'zodError' in error.data && error.data.zodError) {
                return (
                    <div>
                        {Object.keys(error.data.zodError.fieldErrors).map((key) => (
                            <div key={key} className="pb-2">
                                <div className="pb-1 font-semibold">
                                    {key
                                        .replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, (str) => str.toUpperCase())}
                                </div>
                                {error.data.zodError.fieldErrors[key]?.map(
                                    (error: any, idx: number) => <div key={idx}>{error}</div>,
                                )}
                            </div>
                        ))}
                    </div>
                );
            }
        }

        if (hasMessage(error)) {
            if (error.message.toLowerCase() === 'failed to fetch') {
                return (
                    <div>Connection failed. Please check your network settings and try again.</div>
                );
            }
            return <div>{error.message}</div>;
        }

        if (error instanceof Error) {
            return <div>{error.message}</div>;
        }

        return <div>{String(error)}</div>;
    }

    show(error: unknown, title?: string) {
        if (!this.modalApi) {
            throw new Error('Error modal not initialized');
        }
        return this.modalApi.error({
            title: title || extractErrorTitle(error),
            content: this.renderErrorMessage(error),
            okText: 'Close',
            okType: 'primary',
        });
    }

    validation(error: unknown, title: string = 'Validation Error') {
        if (!this.modalApi) {
            throw new Error('Error modal not initialized');
        }
        return this.modalApi.error({
            title: title || extractErrorTitle(error),
            content: this.renderErrorMessage(error),
            okText: 'Got it',
            maskClosable: true,
            centered: true,
        });
    }
    showAsync(
        error: unknown,
        opts: { title?: string; okText?: string } = { okText: 'Ok' },
    ): Promise<'ok' | 'cancel'> {
        return new Promise((resolve) => {
            if (!this.modalApi) {
                throw new Error('Error modal not initialized');
            }
            this.modalApi.error({
                title: opts.title || extractErrorTitle(error),
                content: this.renderErrorMessage(error),
                okText: opts.okText || 'Ok',
                onCancel: () => {
                    resolve('cancel');
                },
                onOk: () => {
                    resolve('ok');
                },
            });
        });
    }
}

const errorMessage = new ErrorMessage();

export { errorMessage };

function extractApolloErrorMessages(error: ApolloError): string[] {
    const errorMessages: string[] = [];
    if (
        (error.networkError && !isEmptyObject(error.networkError)) ||
        error.message === 'Failed to fetch' ||
        error.message === 'Socket closed'
    ) {
        errorMessages.push('Connection failed. Please check your network settings and try again.');
    } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach((e) => {
            const code = e.extensions?.code;
            if (code === 'constraint-violation') {
                errorMessages.push(
                    'You are attempting to delete a record that is referenced by another table.',
                );
            } else if (code === 'permission-denied') {
                errorMessages.push('You do not have permission to perform this action.');
            } else if (code === 'foreign-key-violation') {
                errorMessages.push(
                    'Something went wrong while processing your request. It looks like the information you provided doesn’t meet the required criteria. Please double-check your inputs and try again.',
                );
            } else if (code === 'invalid-jwt') {
                errorMessages.push('Your session has expired. Please log in again.');
            } else if (code === 'unique-violation') {
                errorMessages.push('This record already exists in the database.');
            } else if (code === 'check-violation') {
                errorMessages.push('Something went wrong while processing your request.');
            } else if (code === 'not-found') {
                errorMessages.push('The requested record was not found.');
            } else if (code === 'internal-server-error') {
                errorMessages.push('Something went wrong while processing your request.');
            } else if (code === 'not-null-violation') {
                errorMessages.push(
                    'You are trying to insert a row without providing a value for a required field.',
                );
            } else if (code === 'invalid-input') {
                errorMessages.push('Invalid input');
            } else {
                const message = e.message.replace('Error: ', '');
                errorMessages.push(message);
            }
        });
    } else {
        errorMessages.push(error.message);
    }
    return errorMessages;
}

function extractErrorTitle(error: unknown): string {
    if (error instanceof ApolloError) {
        if (
            (error.networkError && !isEmptyObject(error.networkError)) ||
            error.message === 'Failed to fetch' ||
            error.message === 'Socket closed'
        ) {
            return 'Network Error';
        }
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            if (error.graphQLErrors[0].extensions?.code === 'unexpected') {
                return 'Error';
            } else {
                return 'Database Error';
            }
        }
        if (error.message) {
            return 'Error';
        }
    }
    return 'Error';
}
