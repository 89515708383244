import { Form, Input, InputRef, RefSelectProps, Select } from 'antd';
import { ReactNode, useEffect, useId, useMemo, useRef, useState } from 'react';
import {
    InputWrapper,
    InputWrapperRef,
    VInputProps,
} from '@/components/Common/ViewForm/InputWrapper';
import { RequireExactlyOne } from 'type-fest';
import { errorMessage } from '@/components/Common/errorMessage';
import { DefaultFormData } from '@/stores/FormStore/types';

type Props<Value = any, TFormData extends DefaultFormData = DefaultFormData> = Omit<
    VInputProps<TFormData>,
    'hideSaveButton'
> &
    RequireExactlyOne<
        {
            /**
             * Function that loads dropdown options asynchronously.
             *
             * @returns {Promise<Array<{ label?: string; value: Value }>>} A Promise that resolves to an array of objects.
             */
            loaderFn: () => Promise<{ label?: ReactNode; value: Value }[]>;
            /**
             * Dropdown options.
             */
            options: { label?: ReactNode; value: Value }[];
        },
        'loaderFn' | 'options'
    > & {
        allowClear?: boolean;
        multiple?: boolean;
        maxCount?: number;
        showSearch?: boolean;
        enableOtherOption?: boolean;
        otherLabel?: string;
    };

export const VDropdown = <Value, TFormData extends DefaultFormData = DefaultFormData>({
    field,
    loaderFn,
    options,
    allowClear,
    multiple,
    buttonsPosition = 'right',
    maxCount,
    showSearch,
    enableOtherOption,
    otherLabel = 'Other - please specify',
    formStore,
    ...props
}: Props<Value, TFormData>) => {
    const id = useId();
    const OTHER_VALUE = 'OTHER';
    const getFieldValue = formStore.use.getFieldValue();
    const inputRef = useRef<RefSelectProps>(null);
    const otherInputRef = useRef<InputRef>(null);
    const wrapperRef = useRef<InputWrapperRef | null>(null);

    const [localOptions, setLocalOptions] = useState<NonNullable<Props['options']>>(options || []);
    const [loading, setLoading] = useState(false);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherValue, setOtherValue] = useState('');

    const [form] = Form.useForm();

    useEffect(() => {
        if (!loaderFn) {
            return;
        }

        const asyncWrapper = async () => {
            setLoading(true);

            try {
                const data = await loaderFn();
                setLocalOptions(data);
            } catch (e) {
                errorMessage.show(e);
            } finally {
                setLoading(false);
            }
        };

        void asyncWrapper();
    }, []);

    useEffect(() => {
        if (isOtherSelected) {
            otherInputRef.current?.focus();
        }
    }, [isOtherSelected]);

    useEffect(() => {
        if (!enableOtherOption) {
            return;
        }

        const value = getFieldValue(field);

        if (value && localOptions.length && !localOptions.find((item) => item.value === value)) {
            setIsOtherSelected(true);
        }
    }, [enableOtherOption, localOptions]);

    const formattedOptions = useMemo(
        () => [
            ...localOptions
                .filter(({ value }) => value !== null)
                .map(({ label, value }) => ({
                    label: label || value,
                    value,
                })),
            ...(enableOtherOption ? [{ label: otherLabel, value: OTHER_VALUE }] : []),
        ],
        [localOptions, otherLabel, enableOtherOption],
    );

    const clearState = () => {
        setIsOtherSelected(false);
        setOtherValue('');
        form.setFieldValue(field, null);
    };

    return (
        <InputWrapper
            field={field}
            formStore={formStore}
            innerRef={(ref) => {
                wrapperRef.current = ref;
            }}
            focusFn={() => {
                if (isOtherSelected) {
                    otherInputRef.current?.focus();
                    return;
                }

                inputRef.current?.focus();
            }}
            formOverride={form}
            hideSaveButton={!multiple && !enableOtherOption}
            containerId={id}
            buttonsPosition={buttonsPosition}
            beforeCancelEditing={() => {
                if (!form.getFieldValue(field)) {
                    clearState();
                }
            }}
            onSave={async (value, { save }) => {
                if (!value) {
                    clearState();
                }

                void save({ [field]: value } as Partial<TFormData>);
            }}
            {...props}
        >
            {isOtherSelected ? (
                <Input
                    ref={otherInputRef}
                    value={otherValue}
                    placeholder={otherLabel}
                    onChange={(e) => {
                        setOtherValue(e.target.value);
                        form.setFieldValue(field, e.target.value);
                    }}
                    allowClear
                    onClear={() => {
                        setIsOtherSelected(false);
                        setOtherValue('');
                    }}
                />
            ) : (
                <Select
                    ref={inputRef}
                    mode={multiple ? 'multiple' : undefined}
                    loading={loading}
                    options={formattedOptions}
                    defaultOpen
                    showSearch={showSearch}
                    getPopupContainer={() => document.getElementById(id)!}
                    onChange={(value) => {
                        if (value === OTHER_VALUE) {
                            setIsOtherSelected(true);
                            setOtherValue('');
                            form.setFieldValue(field, '');
                            return;
                        }

                        setIsOtherSelected(false);
                        setOtherValue('');
                        form.setFieldValue(field, value);

                        if (!multiple) {
                            void wrapperRef.current?.save();
                        }
                    }}
                    allowClear={allowClear || multiple}
                    onClear={() => {
                        clearState();
                        void wrapperRef.current?.save();
                    }}
                    maxCount={maxCount}
                    popupClassName="min-w-max"
                />
            )}
        </InputWrapper>
    );
};
