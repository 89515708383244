import { ShareableRouteContext } from '@/types/router';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/configuration/product-types/$productTypeId')({
    staticData: {
        ui: {
            title: 'PDS configuration',
        },
    },
    params: {
        parse({ productTypeId }) {
            return {
                productTypeId: BigInt(productTypeId),
            };
        },
    },
    beforeLoad({ context: { trpcUtils }, params }): ShareableRouteContext {
        trpcUtils.productType.getProductType.ensureData({
            id: params.productTypeId,
            orgId: params.orgId,
        });

        return {
            breadcrumb: {
                async title() {
                    const productType = await trpcUtils.productType.getProductType.ensureData({
                        id: params.productTypeId,
                        orgId: params.orgId,
                    });

                    return productType.name;
                },
            },
        };
    },
    component: Outlet,
});
