import { ComplaintForm } from '@/components/Complaint/ComplaintForm';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useNavigate } from '@/hooks/useNavigate';
import { Card } from 'antd';

type Props = {
    action: ['add'] | ['edit', { complaintId: string }];
    emailClassificationId?: bigint;
};

export function ComplaintFormWrapper({ action, emailClassificationId }: Props) {
    const orgId = useOrgId();
    const navigate = useNavigate();

    const complaintId = action[1]?.complaintId;
    const actionType = action[0];

    function onCancel() {
        if (actionType === 'add') {
            navigate({ moveInHistory: 'back' });
        }

        navigate({
            to: '/console/$orgId/compliance/complaints/details/$id/manage',
            params: {
                orgId,
                id: BigInt(complaintId!),
            },
        });
    }

    function onAfterSaveCancel(complaintId: string) {
        navigate({
            to: '/console/$orgId/compliance/complaints/details/$id/manage',
            params: {
                orgId,
                id: BigInt(complaintId!),
            },
        });
    }

    return (
        <Card>
            <ComplaintForm
                emailClassificationId={emailClassificationId}
                onCancel={onCancel}
                onAfterSaveCancel={onAfterSaveCancel}
            />
        </Card>
    );
}
