import { Tabs } from 'antd';
import { RiskAppetiteStatementManageDetails } from './RiskAppetiteStatementManageDetails';
import { History } from '@/components/Common/History';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/risk/ras/details/$id')({
    staticData: {
        breadcrumb: {
            title: 'Risk appetite statement details',
        },
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: RiskAppetiteStatementDetails,
});

function RiskAppetiteStatementDetails() {
    const { id } = Route.useParams();

    return (
        <>
            <Tabs
                type="line"
                items={[
                    {
                        key: 'risk_appetite_statement_details',
                        label: 'Risk Appetite Statement Details',
                        destroyInactiveTabPane: true,
                        children: <RiskAppetiteStatementManageDetails />,
                    },
                    {
                        key: 'risk_appetite_statement_history',
                        label: 'History',
                        destroyInactiveTabPane: true,
                        children: <History idValue={Number(id)} idPropertyName="riskAppetiteStatementId" />,
                    },
                ]}
            />
        </>
    );
}
