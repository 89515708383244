import { OBLIGATION_LIBRARY_SECTIONS, ObligationLibrarySection } from 'shared/navigation/navRoutes';
import { App, Skeleton, Tabs } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { ObligationLibraryByPk } from '@/graphql/queries/obligation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { obligationsLibraryFormStore } from './store';
import { ObligationsLibraryItemEdit } from './ObligationsLibraryItemEdit';
import { History } from '@/components/Common/History';
import { gql } from '@/__generated__';
import { useNavigate } from '@/hooks/useNavigate';
import { RecordNotFoundError } from '@/utils/error';
import { ObligationLibraryItem } from './types';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/obligations/library/$id/$section')({
    staticData: {
        breadcrumb: {
            title: 'Obligation details',
        },
    },
    params: {
        parse({ id, section }) {
            return {
                id: BigInt(id),
                section: section as ObligationLibrarySection,
            };
        },
    },
    component: ObligationLibraryItemDetails,
});

function ObligationLibraryItemDetails() {
    const { id } = Route.useParams();
    const { message } = App.useApp();
    const [getObligationByPk] = useLazyQuery(ObligationLibraryByPk);
    const [updateObligationByPk] = useMutation(UpdateObligationLibraryByPk);
    const navigate = useNavigate();

    const { formData: obligation } = obligationsLibraryFormStore.useInit({
        entity: 'obligation',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { Controls, ...newData } = newValue;
                const customLabelUpdates: { [key: string]: string | string[] } = {};
                const regularUpdates: Partial<ObligationLibraryItem> = {};

                Object.keys(newData).forEach((key) => {
                    if (key.startsWith('customLabels.')) {
                        const labelId = key.split('.')[1];
                        customLabelUpdates[labelId] = newData[key as keyof typeof newData];
                    } else {
                        regularUpdates[key as keyof typeof newData] = newData[key as keyof typeof newData];
                    }
                });

                if (Object.keys(regularUpdates).length || Object.keys(customLabelUpdates).length) {
                    await updateObligationByPk({
                        variables: {
                            id: currentValue.id!,
                            input: {
                                ...regularUpdates,
                                customLabels: {
                                    ...currentValue.customLabels,
                                    ...customLabelUpdates,
                                },
                            },
                        },
                    });
                }

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const { data } = await getObligationByPk({
                    variables: {
                        id,
                    },
                    fetchPolicy: 'network-only',
                });

                if (!data?.ObligationLibraryByPk?.id) {
                    throw new RecordNotFoundError();
                }

                return {
                    success: true,
                    data: {
                        ...data?.ObligationLibraryByPk,
                        controls: [],
                    },
                };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync("The obligation you are trying to access either doesn't exist.");
                navigate({ to: '/admin-console/obligations/library' });
                return;
            }

            errorMessage.show(error);
        },
    });

    return obligation && BigInt(id) === BigInt(obligation.id) ? (
        <Tabs
            type="line"
            onTabClick={(key) =>
                navigate({
                    to: '/admin-console/obligations/library/$id/$section',
                    params: {
                        id,
                        section: key as ObligationLibrarySection,
                    },
                })
            }
            items={[
                {
                    key: OBLIGATION_LIBRARY_SECTIONS.DETAILS,
                    label: 'Manage obligation',
                    children: <ObligationsLibraryItemEdit />,
                },
                {
                    key: OBLIGATION_LIBRARY_SECTIONS.HISTORY,
                    label: 'History',
                    children: <History idValue={Number(id)} idPropertyName="obligationLibraryId" />,
                },
            ]}
        />
    ) : (
        <Skeleton active />
    );
}

const UpdateObligationLibraryByPk = gql(/* GraphQL */ `
    mutation UpdateObligationLibraryByPk(
        $id: bigint!
        $input: ObligationLibrarySetInput = {}
        $controls: [ObligationLibrary_ControlLibraryInsertInput!] = []
    ) {
        updateObligationLibraryByPk(pkColumns: { id: $id }, _set: $input) {
            id
        }
        deleteObligationLibrary_ControlLibrary(where: { obligationLibraryItemId: { _eq: $id } }) {
            affectedRows
        }
        insertObligationLibrary_ControlLibrary(objects: $controls) {
            affectedRows
        }
    }
`);
