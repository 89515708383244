import { useAntdTable } from '@/hooks/Table/useAntdTable';
import { RouterInputs, RouterOutputs, trpc } from '@/trpc';
import { Card, Input, Select, Space, Switch, Table, Tag, Tooltip, Button, Drawer, Descriptions } from 'antd';
import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Duration } from 'luxon';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-console/advanced/jobs/recurring')({
    staticData: {
        breadcrumb: {
            title: 'Recurring jobs',
        },
        ui: {
            title: 'Recurring jobs',
            subtitle: 'List of recurring background jobs.',
        },
    },
    component: RecurringJobs,
});

type Job = RouterOutputs['job']['listRecurringJobs'][number];
type QueryVariables = RouterInputs['job']['listRecurringJobs'];

const LIMIT_OPTIONS = [
    { value: 100, label: 'Last 100 jobs' },
    { value: 200, label: 'Last 200 jobs' },
    { value: 400, label: 'Last 500 jobs' },
    { value: 1000, label: 'Last 1000 jobs' },
    { value: 2000, label: 'Last 2000 jobs' },
    { value: 5000, label: 'Last 5000 jobs' },
];

function RecurringJobs() {
    const [searchText, setSearchText] = useState('');
    const [showPendingOnly, setShowPendingOnly] = useState(false);
    const [showFailedOnly, setShowFailedOnly] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [queryVariables, setQueryVariables] = useState<QueryVariables>({
        limit: 200,
    });
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);

    const { data: jobs, isLoading, refetch } = trpc.job.listRecurringJobs.useQuery(queryVariables);
    const intervalRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (autoRefresh) {
            intervalRef.current = setInterval(() => {
                refetch();
            }, 10000);
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [autoRefresh, refetch]);

    const getFilteredJobs = () => {
        if (!jobs) return jobs;

        let filtered = jobs;

        if (showPendingOnly) {
            filtered = filtered.filter(
                (job) => !job.finishedOn && !job.processedOn && job.attemptsMade < (job.opts?.attempts || 0),
            );
        }

        if (showFailedOnly) {
            filtered = filtered.filter((job) => job.failedReason || job.stacktrace?.length > 0);
        }

        if (!searchText) return filtered;

        const searchLower = searchText.toLowerCase();
        return filtered.filter((job) => {
            const name = job.name?.toLowerCase() || '';
            const status = job.finishedOn
                ? 'completed'
                : job.processedOn
                  ? 'processing'
                  : job.attemptsMade >= (job.opts?.attempts || 0)
                    ? 'failed'
                    : 'pending';
            const message = !job.returnvalue
                ? ''
                : typeof job.returnvalue === 'object' && job.returnvalue !== null
                  ? ((job.returnvalue as any).message || '').toLowerCase()
                  : String(job.returnvalue).toLowerCase();
            const failedReason = job.failedReason?.toLowerCase() || '';

            return (
                name.includes(searchLower) ||
                status.includes(searchLower) ||
                message.includes(searchLower) ||
                failedReason.includes(searchLower)
            );
        });
    };

    const getJobStatus = (job: Job) => {
        if (job.failedReason || job.stacktrace?.length) return 'failed';
        if (job.finishedOn) return 'completed';
        if (job.processedOn) return 'processing';
        return 'pending';
    };

    const { tableProps } = useAntdTable<Job>({
        rowKey: 'id',
        data: {
            rows: getFilteredJobs() || [],
            total: getFilteredJobs()?.length || 0,
            loading: isLoading,
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => {
                    const aId = a.id?.toString() || '';
                    const bId = b.id?.toString() || '';
                    return aId.localeCompare(bId);
                },
                className: 'whitespace-nowrap',
                render: (value) => value?.toString(),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => {
                    const aName = a.name || '';
                    const bName = b.name || '';
                    return aName.localeCompare(bName);
                },
                className: 'whitespace-nowrap',
            },
            {
                title: 'Status',
                className: 'whitespace-nowrap',
                filters: [
                    { text: 'Completed', value: 'completed' },
                    { text: 'Processing', value: 'processing' },
                    { text: 'Failed', value: 'failed' },
                    { text: 'Pending', value: 'pending' },
                ],
                onFilter: (value, record) => getJobStatus(record) === value,
                sorter: (a, b) => {
                    const getStatusPriority = (status: string) => {
                        switch (status) {
                            case 'completed':
                                return 4;
                            case 'processing':
                                return 3;
                            case 'failed':
                                return 2;
                            default:
                                return 1; // pending
                        }
                    };
                    return getStatusPriority(getJobStatus(a)) - getStatusPriority(getJobStatus(b));
                },
                render: (_, record) => {
                    const status = getJobStatus(record);
                    const color =
                        status === 'completed'
                            ? 'success'
                            : status === 'processing'
                              ? 'processing'
                              : status === 'failed'
                                ? 'error'
                                : 'default';

                    return <Tag color={color}>{status}</Tag>;
                },
            },
            {
                title: 'Attempts',
                className: 'whitespace-nowrap',
                render: (_, record) => (
                    <Tooltip title={`${record.attemptsMade} of ${record.opts?.attempts || 1}`}>
                        {record.attemptsMade}/{record.opts?.attempts || 1}
                    </Tooltip>
                ),
            },
            {
                title: 'Created',
                className: 'whitespace-nowrap',
                sorter: (a, b) => {
                    if (!a.timestamp) return -1;
                    if (!b.timestamp) return 1;
                    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
                },
                render: (_, record) =>
                    record.timestamp ? format(new Date(record.timestamp), 'yyyy-MM-dd HH:mm:ss') : '-',
            },
            {
                title: 'Completed',
                className: 'whitespace-nowrap',
                sorter: (a, b) => {
                    if (!a.finishedOn) return -1;
                    if (!b.finishedOn) return 1;
                    return new Date(a.finishedOn).getTime() - new Date(b.finishedOn).getTime();
                },
                render: (_, record) =>
                    record.finishedOn ? format(new Date(record.finishedOn), 'yyyy-MM-dd HH:mm:ss') : '-',
            },
            {
                title: 'Execution Time (ms)',
                className: 'whitespace-nowrap',
                dataIndex: 'executionTimeInMs',
                sorter: (a, b) => (a.executionTimeInMs || 0) - (b.executionTimeInMs || 0),
                render: (value) => (value ? formatDuration(value) : '-'),
            },
            {
                title: 'Return Value',
                className: 'whitespace-nowrap',
                render: (_, record) => {
                    if (!record.returnvalue && !record.failedReason) return '-';

                    const message =
                        record.failedReason ||
                        (typeof record.returnvalue === 'object' && record.returnvalue !== null
                            ? (record.returnvalue as any).message
                            : String(record.returnvalue));

                    if (!message) return '-';

                    return <Tooltip title={message}>{message}</Tooltip>;
                },
            },
            {
                title: 'Failed Reason',
                className: 'whitespace-nowrap',
                render: (_, record) => {
                    if (!record.failedReason) return '-';
                    return <Tooltip title={record.failedReason}>{record.failedReason}</Tooltip>;
                },
            },
            {
                title: 'Stack Trace',
                className: 'whitespace-nowrap',
                render: (_, record) => {
                    if (!record.stacktrace?.length) return '-';

                    const stackTrace = record.stacktrace[0];
                    return (
                        <Tooltip
                            title={<pre style={{ whiteSpace: 'pre-wrap', maxWidth: '800px' }}>{stackTrace}</pre>}
                            placement="left"
                        >
                            <Button type="link" size="small">
                                View Stack Trace
                            </Button>
                        </Tooltip>
                    );
                },
            },
        ],
        paginationConfig: {
            defaultPageSize: 20,
            showSizeChanger: true,
            total: jobs?.length || 0,
        },
    });

    const formatDate = (date: number | string | null | undefined) => {
        if (!date) return '-';
        return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    return (
        <>
            <Space className="mb-4 flex justify-between">
                <Space>
                    <Input.Search
                        placeholder="Search jobs"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={(value) => {
                            setSearchText(value);
                        }}
                    />
                    <Select
                        size="large"
                        value={queryVariables.limit}
                        onChange={(value) => setQueryVariables((prev) => ({ ...prev, limit: value }))}
                        options={LIMIT_OPTIONS}
                        style={{ minWidth: 160 }}
                    />
                    <Space align="center">
                        <span>Pending only</span>
                        <Switch checked={showPendingOnly} onChange={setShowPendingOnly} />
                    </Space>
                    <Space align="center">
                        <span>Failed jobs</span>
                        <Switch checked={showFailedOnly} onChange={setShowFailedOnly} />
                    </Space>
                </Space>
                <Space>
                    <Space align="center">
                        <span>Auto-refresh</span>
                        <Switch checked={autoRefresh} onChange={setAutoRefresh} />
                    </Space>
                    <Button type="primary" icon={<ReloadOutlined />} onClick={() => refetch()} loading={isLoading} />
                </Space>
            </Space>

            <Card>
                <Table
                    {...tableProps}
                    size="small"
                    scroll={{ x: true }}
                    rowClassName={(record: Job) => {
                        if (record.failedReason || record.stacktrace?.length) {
                            return 'bg-red-50 cursor-pointer';
                        }
                        return 'cursor-pointer';
                    }}
                    onRow={(record) => ({
                        onClick: () => setSelectedJob(record),
                    })}
                />
            </Card>

            <Drawer
                title={`Job Details - ${selectedJob?.name || ''}`}
                placement="right"
                width={1000}
                open={!!selectedJob}
                onClose={() => setSelectedJob(null)}
                footer={
                    <div className="flex justify-end">
                        <Button onClick={() => setSelectedJob(null)}>Close</Button>
                    </div>
                }
            >
                {selectedJob && (
                    <div className="space-y-8">
                        <Descriptions title="Basic Information" column={2} bordered>
                            <Descriptions.Item label="ID" span={2}>
                                {selectedJob.id}
                            </Descriptions.Item>
                            <Descriptions.Item label="Name" span={2}>
                                {selectedJob.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Status" span={2}>
                                <Tag
                                    color={
                                        getJobStatus(selectedJob) === 'completed'
                                            ? 'success'
                                            : getJobStatus(selectedJob) === 'processing'
                                              ? 'processing'
                                              : getJobStatus(selectedJob) === 'failed'
                                                ? 'error'
                                                : 'default'
                                    }
                                >
                                    {getJobStatus(selectedJob)}
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Created At" span={2}>
                                {formatDate(selectedJob.timestamp)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Started At" span={2}>
                                {formatDate(selectedJob.processedOn)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Completed At" span={2}>
                                {formatDate(selectedJob.finishedOn)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Execution Time" span={2}>
                                {selectedJob.executionTimeInMs ? formatDuration(selectedJob.executionTimeInMs) : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Attempts" span={2}>
                                {selectedJob.attemptsMade}/{selectedJob.opts?.attempts || 1}
                            </Descriptions.Item>
                        </Descriptions>

                        {selectedJob.data && (
                            <Descriptions title="Job Data" column={1} bordered>
                                <Descriptions.Item label="Data">
                                    <pre className="whitespace-pre-wrap font-mono text-sm">
                                        {JSON.stringify(selectedJob.data, null, 2)}
                                    </pre>
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        {selectedJob.returnvalue && (
                            <Descriptions title="Return Value" column={1} bordered>
                                <Descriptions.Item label="Result">
                                    <pre className="whitespace-pre-wrap font-mono text-sm">
                                        {typeof selectedJob.returnvalue === 'object'
                                            ? JSON.stringify(selectedJob.returnvalue, null, 2)
                                            : selectedJob.returnvalue}
                                    </pre>
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        {selectedJob.failedReason && (
                            <Descriptions title="Error Information" column={1} bordered>
                                <Descriptions.Item label="Failed Reason">{selectedJob.failedReason}</Descriptions.Item>
                                {selectedJob.stacktrace?.[0] && (
                                    <Descriptions.Item label="Stack Trace">
                                        <pre className="whitespace-pre-wrap rounded bg-gray-50 p-4 font-mono text-sm">
                                            {selectedJob.stacktrace[0]}
                                        </pre>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        )}
                    </div>
                )}
            </Drawer>
        </>
    );
}
function formatDuration(ms: number): string {
    const duration = Duration.fromMillis(ms);

    if (ms < 1000) {
        return `${ms.toFixed(2)} ms`;
    }

    const parts: string[] = [];
    const minutes = Math.floor(duration.as('minutes'));
    const seconds = Math.floor(duration.as('seconds') % 60);
    const milliseconds = Math.round(duration.as('milliseconds') % 1000);

    if (minutes > 0) {
        parts.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
    }
    if (seconds > 0) {
        parts.push(`${seconds} s`);
    }
    if (milliseconds > 0) {
        parts.push(`${milliseconds} ms`);
    }

    return parts.join(' ');
}
