import { Card, Col, message, Row, Skeleton, Typography } from 'antd';
import { errorMessage } from '@/components/Common/errorMessage';
import { useOrgId } from '@/hooks/Org/useOrgId';
import {
    CONTROL_FREQUENCY,
    CONTROL_STATUSES,
    CONTROL_TYPES,
    ControlFrequency,
    ControlStatus,
    ControlType,
} from 'shared/constants/control';
import { ScheduledControlTests } from '@/components/Control/ScheduledControlTests';
import { ControlTestHistory } from '@/components/Control/ControlTestHistory';
import { useCreateSignalStore } from '@/context/signal';
import { ControlContext, ControlSignals } from '../../ControlContext';
import { TaskList } from '@/components/Task/TaskList';
import { Risks } from './Risks';
import { Obligations } from './Obligations';
import { controlFormStore } from './store';
import { trpc } from '@/trpc';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useNavigate } from '@/hooks/useNavigate';
import { VDropdown } from '@/components/Common/ViewForm/VDropdown';
import { VUserSelector } from '@/components/Common/ViewForm/VUserSelector';
import { requiredFieldRule } from '@/utils/form';
import { VTextInput } from '@/components/Common/ViewForm/VTextInput';
import { RecordNotFoundError } from '@/utils/error';
import { VRichTextInput } from '@/components/Common/ViewForm/VRichTextInput';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/console/$orgId/controls/register/details/$id')({
    staticData: {
        breadcrumb: {
            title: 'Control details',
        },
    },
    params: {
        parse({ id }) {
            return {
                id: BigInt(id),
            };
        },
    },
    component: ControlDetails,
});

function ControlDetails() {
    const orgId = useOrgId();
    const signalStore = useCreateSignalStore<ControlSignals>();
    const { id } = Route.useParams();
    const updateControlMutation = trpc.control.updateControl.useMutation();
    const trpcClient = useTrpcClient();
    const navigate = useNavigate();

    const { formData: control } = controlFormStore.useInit({
        entity: 'control',
        loadDependencies: [id],

        async onSave(currentValue, newValue) {
            try {
                message.info('Saving...');

                await updateControlMutation.mutateAsync({
                    id: currentValue.id,
                    set: newValue,
                });

                message.destroy();
                message.success('Saved.');

                return { success: true };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onLoad() {
            try {
                const data = await trpcClient.control.getControl.query({ id });

                return { success: true, data };
            } catch (e) {
                return { success: false, error: e };
            }
        },

        async onError(error) {
            message.destroy();

            if (error instanceof RecordNotFoundError) {
                await errorMessage.showAsync(
                    "The control you are trying to access either doesn't exist or you don't have permissions to access it.",
                );

                navigate({
                    to: '/console/$orgId/controls/register',
                    params: { orgId },
                });
                return;
            }

            errorMessage.show(error);
        },
    });

    return (
        <ControlContext.Provider value={signalStore}>
            {id === control?.id ? (
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={16}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card>
                                    <Typography.Title level={4} className="mb-4 mt-0">
                                        {`Control: ${control.id}`}
                                    </Typography.Title>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={6}>
                                            <VDropdown
                                                label="Status"
                                                field="status"
                                                rules={requiredFieldRule}
                                                formatValueFn={(value) => CONTROL_STATUSES[value as ControlStatus]}
                                                options={Object.keys(CONTROL_STATUSES).map((key) => ({
                                                    label: CONTROL_STATUSES[key as ControlStatus],
                                                    value: key,
                                                }))}
                                                formStore={controlFormStore}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <VUserSelector
                                                label="Control Owner"
                                                field="ownerId"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                                removeAnonymousAccount
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <VDropdown
                                                label="Frequency"
                                                field="frequency"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                                formatValueFn={(value) => CONTROL_FREQUENCY[value as ControlFrequency]}
                                                options={Object.keys(CONTROL_FREQUENCY).map((key) => ({
                                                    label: CONTROL_FREQUENCY[key as ControlFrequency],
                                                    value: key,
                                                }))}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <VDropdown
                                                label="Type"
                                                field="type"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                                formatValueFn={(value) => CONTROL_TYPES[value as ControlType]}
                                                options={Object.keys(CONTROL_TYPES).map((key) => ({
                                                    label: CONTROL_TYPES[key as ControlType],
                                                    value: key,
                                                }))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <VTextInput
                                                label="Control name"
                                                field="name"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <VTextInput
                                                label="Control objective"
                                                field="objective"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <VRichTextInput
                                                field="description"
                                                label="Control Description"
                                                rules={requiredFieldRule}
                                                formStore={controlFormStore}
                                                maxRowsToDisplay={12}
                                                setHtml
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24}>
                                <ScheduledControlTests controlId={id} />
                            </Col>

                            <Col span={24}>
                                <ControlTestHistory controlId={id} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Obligations controlId={control.id} />
                            </Col>
                            <Col xs={24}>
                                <Risks controlId={control.id} />
                            </Col>
                            <Col xs={24}>
                                <TaskList relatedEntity="control" relatedEntityId={control.id} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Skeleton active />
            )}
        </ControlContext.Provider>
    );
}
